import { ReactNode } from "react";


interface ModalPromptProps{
  setShowModal: (show: boolean) => void,
  title: string,
  content: ReactNode,
  closeFunction: (parmas: any) => any,
  acceptFunction: (params: any) => any,
  formName: string
}

export default function ModalPrompt({
  setShowModal,
  title,
  content,
  closeFunction,
  acceptFunction,
  formName
}:ModalPromptProps){


  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative my-6 mx-auto w-1/3 xl:max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-fondo outline-none focus:outline-none font-firasans">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-placeholder rounded-t">
              <h3 className="text-3xl font-semibold text-principal-1">
                  {title}
              </h3>
              <button
                  className="p-1 ml-auto bg-transparent border-0 text-principal-2 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
              >
                  <span className="bg-transparent text-principal-2 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                  </span>
              </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto overflow-y-auto font-roboto text-placeholder">
                  {
                    content
                  }
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-3 border-t border-solid border-slate-200 rounded-b">
              <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hover:text-principal-3"
                  type="button"
                  onClick={() => setShowModal(false)}
              >Cancelar</button>
              <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 hover:text-principal-3"
                  type="submit"
                  form={formName}
              >Guardar</button>
              </div>
          </div>
          </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}