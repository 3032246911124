import ONGP1 from "../../interfaces/p6m";
import { useContext } from "react"
import { AuthContext } from "../../context/auth";
import { useNavigate } from 'react-router-dom';

interface UserRowProps{
    ongP1: ONGP1
}

export default function UserAdmin({
    ongP1
}:UserRowProps) 
{

    const navigate = useNavigate();

    function next()
    {
        navigate('/dashboard/previewp7c')
    }

    function back()
    {
        navigate('/dashboard/previewp5c')
    }

    const userContext = useContext(AuthContext);
    function sendOne()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        fetch(`${userContext?.api_url}/company/listSixthComp`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: localStorage.getItem('id')
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    console.log(resp);
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

return(
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            INGRESOS CORRIENTES
        </h1>
        <form onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. ¿Existen políticas de los ingresos y se encuentran documentadas y autorizadas por autoridades gerenciales?</span><br/>
                    <select disabled name="p1">
                        <option value="No">{ongP1.p1}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. ¿De acuerdo a la estructura funcional de la empresa, existe una adecuada segregación de funciones y son independientes los empleados que manejan los inventarios?</span><br/>
                    <select disabled name="p2">
                        <option value="No">{ongP1.p2}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. ¿De acuerdo a la estructura funcional de la empresa, existe una adecuada segregación de funciones y son independientes los directivos que manejan la Gerencia?</span><br/>
                    <select disabled name="p3">
                        <option value="No">{ongP1.p3}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. ¿De acuerdo a la estructura funcional de la empresa, existe una adecuada segregación de funciones y son independientes los empleados que manejan los fondos de caja?</span><br/>
                    <textarea disabled maxLength={300} value={ongP1.p4} name="p4" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #4..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. ¿De acuerdo a la estructura funcional de la empresa, existe una adecuada segregación de funciones y son independientes los empleados que manejan los registros contables?</span><br/>
                    <select disabled name="p5">
                        <option value="No">{ongP1.p5}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. ¿Se concilian mensualmente los auxiliares de cuentas por cobrar con los registros contables?</span><br/>
                    <select disabled name="p6">
                        <option value="No">{ongP1.p6}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. ¿Se expiden recibos o comprobantes por ingresos, que reúnan los requisitos fiscales?</span><br/>
                    <select disabled name="p7">
                        <option value="No">{ongP1.p7}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. ¿Tratándose de ingresos por ventas u otros servicios, se expiden recibos por ingresos con el nuevo regimen de facturación?</span><br/>
                    <select disabled name="p8">
                        <option value="No">{ongP1.p8}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. ¿Se adjuntan comprobantes o registros de los clientes en las transferencias recibidas?</span><br/>
                    <select disabled name="p9">
                        <option value="No">{ongP1.p9}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. ¿Existen ingresos no identificados en las transferencias bancarias o depositos realizados?</span><br/>
                    <select disabled name="p10">
                        <option value="No">{ongP1.p10}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. ¿Mantienen una información expedita o base de datos de los clientes sobre la recepcion de facturas ycobros efectuados?</span><br/>
                    <select disabled name="p11">
                        <option value="No">{ongP1.p11}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">En el caso de haber obtenido ingresos por enajenación de terrenos, construcciones, partes sociales, acciones nominativas colocadas entre público inversionistas se considero la opción de ajustar el monto original de la inversión en esos bienes para determinar:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. ¿Si se percibieron ingresos en especie, se consideró su valor de mercado o en su defecto el de avalúo?</span><br/>
                    <select disabled name="p12">
                        <option value="No">{ongP1.p12}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. ¿Se realizo la documentacion legal y pertinente a la adquisición de bienes tangibles o intangibles?</span><br/>
                    <select disabled name="p13">
                        <option value="No">{ongP1.p13}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. ¿Existe comprobantes de entrega y recepción de los ingresos por enajenacion o donación en especie?</span><br/>
                    <select disabled name="p14">
                        <option value="No">{ongP1.p14}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">15. ¿Se registra como ingresos las adquisiciones de bienes tangibles o intangibles?</span><br/>
                    <select disabled name="p15">
                        <option value="No">{ongP1.p15}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">INGRESOS POR INTERESES:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">16. ¿Se registran mensualmente los ingresos correspondientes a intereses por cuentas bancarias?</span><br/>
                    <select disabled name="p16">
                        <option value="No">{ongP1.p16}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">17. ¿Se registran o se lleva un control de los ingresos por depósitos a plazos fijos?</span><br/>
                    <select disabled name="p17">
                        <option value="No">{ongP1.p17}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">18. ¿Se documenta a traves de documentos bancarios o notas de crédito los intereses ganados?</span><br/>
                    <select disabled name="p18">
                        <option value="No">{ongP1.p18}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">19. ¿Se reporta en los auxiliares bancarios los interese generados en el mes?</span><br/>
                    <select disabled name="p19">
                        <option value="No">{ongP1.p19}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">OTROS INGRESOS:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">20. ¿Que documento se emite al obtener ingresos derivados de prestación de servicios, tales como: Alquiler, Servicios Audiovisuales, etc?</span><br/>
                    <select disabled name="p20">
                        <option value="No">{ongP1.p20}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">21. ¿Existen politicas y reglas sobre la recepcion de ingresos no corrientes?</span><br/>
                    <select disabled name="p21">
                        <option value="No">{ongP1.p21}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">22. ¿Los ingresos derivados por servicios prestados son realizados por transferencias bancarias o cheques emitidos a favor de la empresa?</span><br/>
                    <select disabled name="p22">
                        <option value="No">{ongP1.p22}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">23.¿Esta prohibido recibir efectivo para la prestación de servicios o existe personal autorizado para la recepcion del mismo?</span><br/>
                    <select disabled name="p23">
                        <option value="No">{ongP1.p23}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={next}
              >Siguiente</button>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={back}
              >Atras</button>
        </form>
      </div>
    </div>
)}