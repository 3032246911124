import ONGP1 from "../../interfaces/p14";
import { useContext } from "react"
import { AuthContext } from "../../context/auth";
import { useNavigate } from 'react-router-dom';

interface UserRowProps{
    ongP1: ONGP1
}

export default function UserAdmin({
    ongP1
}:UserRowProps) 
{

    const navigate = useNavigate();

    function back()
    {
        navigate('/dashboard/previewp13')
    }

    const userContext = useContext(AuthContext);
    function sendOne()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        fetch(`${userContext?.api_url}/ong/listFourteenONG`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: localStorage.getItem('id')
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    console.log(resp);
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

return(
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            RECURSOS HUMANOS
        </h1>
        <form onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. ¿Existe un Manual de Personal y de Funciones?</span><br/>
                    <select disabled name="p1">
                        <option value="No">{ongP1.p1}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. ¿Posee la Organización Manuales inductivos de entrenamiento y capacitación?</span><br/>
                    <select disabled name="p2">
                        <option value="No">{ongP1.p2}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. ¿Posee la organización adecuadamente establecido las políticas y obligaciones del personal de cada área de trabajo?</span><br/>
                    <select disabled name="p3">
                        <option value="No">{ongP1.p3}</option>
                  </select>
                  <br/>
                  <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. ¿Existen Manual de Reclutamiento, Selección y Contratación de Personal?</span><br/>
                    <select disabled name="p4">
                        <option value="No">{ongP1.p4}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. ¿Existen Clasificación de Puestos y Rangos Salariales?</span><br/>
                    <select disabled name="p5">
                        <option value="No">{ongP1.p5}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. ¿Existe un Manual de Evaluación de Puestos y Funciones?</span><br/>
                    <select disabled name="p6">
                        <option value="No">{ongP1.p6}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. ¿Existe Manual de Seguridad e Higiene del Personal?</span><br/>
                    <select disabled name="p7">
                        <option value="No">{ongP1.p7}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. ¿Contiene la Organización Expedientes actualizados de Trabajo del personal?</span><br/>
                    <textarea disabled value={ongP1.p8} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. Mencione los documentos que actualmente existen en los expedientes de trabajo.</span><br/>
                    <textarea disabled name="p9" value={ongP1.p9} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. ¿Posee la Organización detallado los puestos de trabajo existentes?</span><br/>
                    <select disabled name="p10">
                        <option value="No">{ongP1.p10}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. ¿Están correctamente definidos las funciones de cada puesto de trabajo?</span><br/>
                    <select disabled name="p11">
                        <option value="No">{ongP1.p11}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. ¿Los puestos de Trabajo están de acuerdo al Organigrama actual?</span><br/>
                    <select disabled name="p12">
                        <option value="No">{ongP1.p12}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. ¿Que instrumento se utiliza para la contratación del Personal?</span><br/>
                    <textarea disabled name="p13" value={ongP1.p13} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. ¿Están debidamente certificados por el Ministerio de Trabajo los contratos?</span><br/>
                    <select disabled name="p14">
                        <option value="No">{ongP1.p14}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">15. ¿Existe un Código de Ética, Conducta y Valores del personal?</span><br/>
                    <select disabled name="p15">
                        <option value="No">{ongP1.p15}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">16. ¿Posee la Organización procesos de selección adecuados? ¿Que tipo de procesos efectúan actualmente?</span><br/>
                    <textarea disabled value={ongP1.p16} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">17. ¿Posee la Organización documento de Conflicto de Intereses?</span><br/>
                    <select disabled name="p17">
                        <option value="No">{ongP1.p17}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">18. ¿Posee la Organización documento de Política de Protección a los niños(as)?</span><br/>
                    <select disabled name="p18">
                        <option value="No">{ongP1.p18}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">19. ¿Están correctamente definidas las horas de trabajo de cada puesto?</span><br/>
                    <select disabled name="p19">
                        <option value="No">{ongP1.p19}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">20. ¿Están correctamente definidas los beneficios, vacaciones y licencias al personal?</span><br/>
                    <select disabled name="p20">
                        <option value="No">{ongP1.p20}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">21. ¿Existen Controles de evaluación del personal de las áreas de trabajo?¿ Existen Controles de evaluación del personal de las áreas de trabajo?</span><br/>
                    <select disabled name="p21">
                        <option value="No">{ongP1.p21}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">22. ¿Contiene informes definitivos de las evaluaciones efectuadas al personal?</span><br/>
                    <select disabled name="p22">
                        <option value="No">{ongP1.p22}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">23. ¿Se planifica y ejecuta la formación y capacitación del personal nuevo?</span><br/>
                    <select disabled name="p23">
                        <option value="No">{ongP1.p23}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">24. ¿Cada cuanto se efectúan capacitaciones y entrenamiento del personal permanente?</span><br/>
                    <select disabled name="p24">
                        <option value="No">{ongP1.p24}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">25. ¿Posee la Organización presupuesto para capacitar al personal con Seminarios, Conferencias, Entrenamientos y otros en el año?</span><br/>
                    <select disabled name="p25">
                        <option value="No">{ongP1.p25}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">26. ¿Existe un inductivo y manual de procedimientos para la contratación de personal?</span><br/>
                    <select disabled name="p26">
                        <option value="No">{ongP1.p26}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">27. Describa claramente el proceso de selección y elección de personal nuevo.</span><br/>
                    <textarea disabled name="p27" value={ongP1.p27} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">28. ¿Que procedimientos realiza y ejecuta al contratar al personal elegido?</span><br/>
                    <textarea disabled name="p28" value={ongP1.p28} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">29. ¿Existe un departamento o sección para desarrollar el talento y la capacidad de cada empleado permanente de la organización?</span><br/>
                    <select disabled name="p29">
                        <option value="No">{ongP1.p29}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">30. ¿Existen políticas y planes de incentivos y compensaciones al personal?</span><br/>
                    <select disabled name="p30">
                        <option value="No">{ongP1.p30}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">31. ¿Que tipo de incentivos y compensaciones contiene el personal?</span><br/>
                    <textarea disabled name="p31" value={ongP1.p31} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">32. ¿Existe un plan o políticas de rotación y movimiento de personal en áreas especializadas o técnicas?</span><br/>
                    <select disabled name="p32">
                        <option value="No">{ongP1.p32}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">33. ¿Existe un reglamento de medidas disciplinarias del personal?</span><br/>
                    <select disabled name="p33">
                        <option value="No">{ongP1.p33}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">34. ¿Existe un plan de seguros contra accidentes o sucesos del personal en actividades de riesgos?</span><br/>
                    <select disabled name="p34">
                        <option value="No">{ongP1.p34}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">35. Describa los medios de comunicación que se utiliza para los empleados y personal de la Organización</span><br/>
                    <textarea disabled name="p35" value={ongP1.p35} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #35..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">36. ¿Se preparan y utilizan los planes de acción para comunicar las necesidades de la dirección?</span><br/>
                    <select disabled name="p36">
                        <option value="No">{ongP1.p36}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">37. ¿Existe una correcta aplicación de los roles de mando y autoridad?</span><br/>
                    <select disabled name="p37">
                        <option value="No">{ongP1.p37}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">38. ¿Que consideraciones incluiría usted en los roles de mando y autoridad?</span><br/>
                    <textarea disabled name="p38" value={ongP1.p38} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #38..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">39. ¿Se encuentra la estructura de personal de acuerdo al Código de Trabajo y sus reglamentos?</span><br/>
                    <select disabled name="p39">
                        <option value="No">{ongP1.p39}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">40. ¿Existe un correcto sistema de salarios e incentivos del personal en general de la Organización?</span><br/>
                    <select disabled name="p40">
                        <option value="No">{ongP1.p40}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">41. ¿ Contiene la Organización Carnet o Medios de identificación personal de cada empleado permanente?</span><br/>
                    <select disabled name="p41">
                        <option value="No">{ongP1.p41}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

        <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={back}
              >Atras</button>
        </form>
      </div>
    </div>
)}