import ONGP1 from "../../interfaces/p0";
import { SetStateAction, useContext, useEffect, useState } from "react"
import ModalPrompt from "../ModalFiles";
import { AuthContext } from "../../context/auth";
import { useNavigate } from 'react-router-dom';

interface UserRowProps{
    ongP1: ONGP1
}

export default function UserAdmin({
    ongP1
}:UserRowProps) 
{

    const userContext = useContext(AuthContext);
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModal2, setShowModal2] = useState<boolean>(false);
    const [showModal3, setShowModal3] = useState<boolean>(false);
    const [showModal4, setShowModal4] = useState<boolean>(false);
    const [showModal5, setShowModal5] = useState<boolean>(false);
    const [showModal6, setShowModal6] = useState<boolean>(false);
    const [showModal7, setShowModal7] = useState<boolean>(false);
    const [showModal8, setShowModal8] = useState<boolean>(false);
    const [showModal9, setShowModal9] = useState<boolean>(false);
    const [showModal10, setShowModal10] = useState<boolean>(false);
    const [showModal11, setShowModal11] = useState<boolean>(false);
    const [showModal12, setShowModal12] = useState<boolean>(false);
    const [showModal13, setShowModal13] = useState<boolean>(false);
    const [showModal14, setShowModal14] = useState<boolean>(false);

    function next()
    {
        navigate('/dashboard/previewp1c')
    }

    function modalOne()
    {
        setShowModal(true)
    }

    function modalTwo()
    {
        setShowModal2(true)
    }

    function modalThree()
    {
        setShowModal3(true)
    }

    function modalFour()
    {
        setShowModal4(true)
    }

    function modalFive()
    {
        setShowModal5(true)
    }

    function modalSix()
    {
        setShowModal6(true)
    }

    function modalSeven()
    {
        setShowModal7(true)
    }

    function modalEight()
    {
        setShowModal8(true)
    }

    function modalNine()
    {
        setShowModal9(true)
    }

    function modalTen()
    {
        setShowModal10(true)
    }

    function modalEleven()
    {
        setShowModal11(true)
    }

    function modalTwelve()
    {
        setShowModal12(true)
    }

    function modalThirteen()
    {
        setShowModal13(true)
    }

    function modalFourteen()
    {
        setShowModal14(true)
    }

    function sendOne()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        fetch(`${userContext?.api_url}/company/listZeroComp`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: localStorage.getItem('id')
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    console.log(resp);
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

return(
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            CONOCIMIENTO DEL CLIENTE
        </h1>
        <div onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. Nombre de la empresa.</span>
                    <textarea disabled maxLength={300} value={ongP1.p1} name="p1" id='text-area1' className="w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring"  placeholder="Pregunta #1..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. Domicilio y teléfonos, planta y oficinas.</span>
                    <textarea disabled maxLength={300} value={ongP1.p2} name="p2" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. Teléfono</span>
                    <textarea disabled maxLength={300} value={ongP1.p3} name="p3" className="pattern w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Número Teléfonico sin guiones." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. Dirección</span>
                    <textarea disabled maxLength={300} value={ongP1.p4} name="p4" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #4..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. Representante Legal</span>
                    <textarea disabled maxLength={300} value={ongP1.p5} name="p5" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #5..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. Identidad del Representante Legal</span><br></br>
                    <select disabled name="p15">
                        <option value="No">{ongP1.p6}</option>
                    </select>
                </label>
                <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalOne}>Consultar</button>

                {showModal ? (
                    <ModalPrompt
                        setShowModal={setShowModal}
                        title={ongP1.p6}
                        content={
                            
                            <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p6} height="100%" width="100%"></iframe>

                        }
                        />
                        ) : null}
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. RTN del Representante Legal</span><br/>
                    <select disabled name="p15">
                        <option value="No">{ongP1.p7}</option>
                    </select>
                </label>

                <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalTwo}>Consultar</button>

                {showModal2 ? (
                    <ModalPrompt
                    setShowModal={setShowModal2}
                    title={ongP1.p7}
                    content={
                            
                        <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p7} height="100%" width="100%"></iframe>
                        
                    }
                    />
                    ) : null}
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. Estados Financieros Firmados y Sellados</span><br/>
                    <select disabled name="p15">
                        <option value="No">{ongP1.p8}</option>
                    </select>
                </label>

                <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalThree}>Consultar</button>

                {showModal3 ? (
                    <ModalPrompt
                        setShowModal={setShowModal3}
                        title={ongP1.p8}
                        content={
                            
                                <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p8} height="100%" width="100%"></iframe>

                        }
                    />
                    ) : null}
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. Logo de Empresa</span><br/>
                    <select disabled name="p15">
                        <option value="No">{ongP1.p9}</option>
                    </select>
                </label>
                <br/>
                <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalFour}>Consultar</button>

                {showModal4 ? (
                    <ModalPrompt
                        setShowModal={setShowModal4}
                        title={ongP1.p9}
                        content={
                            
                                <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p9} height="100%" width="100%"></iframe>

                        }
                    />
                    ) : null}
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. Nombre de los principales funcionarios y el nombre específico a quien debamos dirigir nuestra propuesta.</span>
                    <textarea disabled maxLength={300} value={ongP1.p10} name="p10" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #10..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. Objeto o Giro de la compañía y/o Organización y/o institución.</span>
                    <textarea disabled maxLength={300} value={ongP1.p11} name="p11" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #11..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. Capital social (Patrimonio Institucional), número de acciones, valor nominal, clase de las mismas.</span>
                    <textarea disabled maxLength={300} value={ongP1.p12} name="p12" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #12..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. Fecha de terminación de su ejercicio fiscal.</span><br/>
                    <input type="date" value={ongP1.p13} name ='p13' disabled/>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. ¿Su empresa tiene Registro Tributario Nacional? - RTN</span><br/>
                    <select disabled name="p14">
                        <option value="No">{ongP1.p14}</option>
                    </select>
                </label>
                {ongP1.p14 === 'No' ? (
                    <span className="text-principal-2">No se subio ningún archivo</span>
                    ) : 
                    <div>
                        <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalFive}>Consultar</button>

                        {showModal5 ? (
                            <ModalPrompt
                            setShowModal={setShowModal5}
                            title={ongP1.p14}
                            content={
                                
                                <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p14} height="100%" width="100%"></iframe>
                                
                            }
                            />
                            ) : null}

                    </div>               
                }
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">15. Número Patronal del IHSS.</span><br/>
                    <select disabled name="p15">
                        <option value="No">{ongP1.p15}</option>
                    </select>
                </label>
                {ongP1.p15 === 'No' ? (
                    <span className="text-principal-2">No se subio ningún archivo</span>
                    ) : 
                    <div>
                        <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalSix}>Consultar</button>

                        {showModal6 ? (
                            <ModalPrompt
                            setShowModal={setShowModal6}
                            title={ongP1.p15}
                            content={
                                
                                <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p15} height="100%" width="100%"></iframe>
                                
                            }
                            />
                            ) : null}
                    </div>               
                }
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">16. Cámara y/o Institucion regulada a la que pertenece:</span>
                    <textarea disabled maxLength={300} value={ongP1.p16} name="p16" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #16..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">17. Número aproximado de empleados y obreros.</span><br/>
                    <select disabled name="p17">
                        <option value="1-10">{ongP1.p17}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">18. Total de activo, pasivo y capital contable, ingresos anuales, gastos y utilidades.</span>
                    <textarea disabled maxLength={300} value={ongP1.p18} name="p18" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #18..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">19. Nombres de los principales accionistas y/o Junta Directiva.</span>
                    <textarea disabled maxLength={300} value={ongP1.p19} name="p19" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #19..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">20. ¿Qué relaciones ha tenido la compañía y/o Organización con otros despachos de contadores públicos? Indicar que clase de servicio ha recibido y de que despacho o contador público.</span>
                    <textarea disabled maxLength={300} value={ongP1.p20} name="p20" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #20..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">21. Revisar dictámenes, informes y'/'o cartas de sugerencias preparadas por otros contadores públicos con motivo de sus intervenciones.</span>
                    <textarea disabled maxLength={300} value={ongP1.p21} name="p21" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #21..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">22. ¿Son aparentemente adecuados los métodos del registro, ya sean electrónicos, mecánicos o manuales?</span><br/>
                    <select disabled name="p22">
                        <option value="No">{ongP1.p22}</option>
                  </select>
                  <br/>
                  <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">23. ¿Contiene la organización Personería Jurídica?</span><br/>
                    <select disabled name="p23">
                        <option value="No">{ongP1.p23}</option>
                    </select>
                </label>
                {ongP1.p23 === 'No' ? (
                    <span className="text-principal-2">No se subio ningún archivo</span>
                    ) : 
                    <div>
                        <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalEight}>Consultar</button>

                        {showModal8 ? (
                            <ModalPrompt
                            setShowModal={setShowModal8}
                            title={ongP1.p23}
                            content={
                                
                                    <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p23} height="100%" width="100%"></iframe>
                                    
                                }
                                />
                                ) : null}
                    </div>               
                }
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">24. ¿Ha tenido modificaciones la Personería Jurídica?</span><br/>
                    <select disabled name="p24">
                        <option value="No">{ongP1.p24}</option>
                    </select>
                </label>
                {ongP1.p24 === 'No' ? (
                    <span className="text-principal-2">No se subio ningún archivo</span>
                    ) : 
                    <div>
                        <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalSeven}>Consultar</button>

                        {showModal7 ? (
                            <ModalPrompt
                            setShowModal={setShowModal7}
                            title={ongP1.p24}
                            content={
                                
                                <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p24} height="100%" width="100%"></iframe>
                                
                            }
                            />
                            ) : null}
                    </div>               
                }
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">25. ¿De cuantos miembros cuenta la Junta Directiva de la Organización?</span>
                    <textarea disabled maxLength={300} value={ongP1.p25} name="p25" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #25..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">26. ¿Existe en el libro de actas todos los nombramientos desde su fundación?</span><br/>
                    <select disabled name="p26">
                        <option value="No">{ongP1.p26}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">27. ¿Cada cuanto se reúne la Junta Directiva de la Organización?</span>
                    <textarea disabled maxLength={300} value={ongP1.p27} name="p27" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #27..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">28. ¿De que procedimientos y reglamentos se rige la Junta Directiva?</span>
                    <textarea disabled maxLength={300} value={ongP1.p28} name="p28" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #28..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">29. ¿Solicita la Junta Directiva Informes de Gestión a la Dirección Ejecutiva?</span>
                    <textarea disabled maxLength={300} value={ongP1.p29} name="p29" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #29..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">30. ¿Contiene Reglamentos Internos de Trabajo?</span><br/>
                    <select disabled name="p30">
                        <option value="No">{ongP1.p30}</option>
                    </select>
                </label>
                {ongP1.p30 === 'No' ? (
                    <span className="text-principal-2">No se subio ningún archivo</span>
                    ) : 
                    <div>
                    <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalNine}>Consultar</button>

                    {showModal9 ? (
                        <ModalPrompt
                        setShowModal={setShowModal9}
                        title={ongP1.p30}
                        content={
                            
                            <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p30} height="100%" width="100%"></iframe>
                            
                        }
                        />
                        ) : null}
                </div>               
            }
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">31. ¿Contiene un Organigrama?</span><br/>
                    <select disabled name="p31">
                        <option value="No">{ongP1.p31}</option>
                    </select>
                </label>

                {ongP1.p31 === 'No' ? (
                    <span className="text-principal-2">No se subio ningún archivo</span>
                    ) : 
                    <div>
                        <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalTen}>Consultar</button>

                        {showModal10 ? (
                            <ModalPrompt
                            setShowModal={setShowModal10}
                            title={ongP1.p31}
                            content={
                                
                                <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p31} height="100%" width="100%"></iframe>
                                
                            }
                            />
                            ) : null}
                    </div>               
                }
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">32. Describa las Credenciales que como Organización y/o compañía pertenece.</span>
                    <textarea disabled maxLength={300} value={ongP1.p32} name="p32" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #32..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">33. ¿Contiene la organización Partes Relacionadas de acuerdos con Organismos Internacionales?</span><br/>
                    <select disabled name="p33">
                        <option value="No">{ongP1.p33}</option>
                    </select>
                </label>

                {ongP1.p33 === 'No' ? (
                    <span className="text-principal-2">No se subio ningún archivo</span>
                    ) : 
                    <div>
                        <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalEleven}>Consultar</button>

                        {showModal11 ? (
                            <ModalPrompt
                            setShowModal={setShowModal11}
                            title={ongP1.p33}
                            content={
                                
                                <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p33} height="100%" width="100%"></iframe>
                                
                            }
                            />
                            ) : null}
                    </div>               
                }
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">34. ¿Cuales son las compañías de partes relacionadas? Mencione el País.</span>
                    <textarea disabled maxLength={300} value={ongP1.p34} name="p34" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #34..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">35. ¿Hay juicios pendientes de tipo laboral, mercantil o de litigios de la organización?</span><br/>
                    <select disabled name="p35">
                        <option value="No">{ongP1.p35}</option>
                    </select>
                </label>

                {ongP1.p35 === 'No' ? (
                    <span className="text-principal-2">No se subio ningún archivo</span>
                    ) : 
                    <div>
                        <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalTwelve}>Consultar</button>

                        {showModal12 ? (
                            <ModalPrompt
                            setShowModal={setShowModal12}
                            title={ongP1.p35}
                            content={
                                
                                <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p35} height="100%" width="100%"></iframe>
                                
                            }
                            />
                            ) : null}
                    </div>               
                }
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">36. ¿Cual es el tipo de proceso de selección de la Junta Directiva?</span>
                    <textarea disabled maxLength={300} value={ongP1.p36} name="p36" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #36..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">37. ¿Mencione las características principales del Presidente'(a)' de la Junta Directiva?</span>
                    <textarea disabled maxLength={300} value={ongP1.p37} name="p37" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #37..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">38. ¿Mencione las características principales del Tesorero(a) de la Junta Directiva?</span>
                    <textarea disabled maxLength={300} value={ongP1.p38} name="p38" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #38..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">39. ¿Mencione las características principales del Secretario (a) de la Junta Directiva?</span>
                    <textarea disabled maxLength={300} value={ongP1.p39} name="p39" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #39..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">40. ¿Mencione las características principales del Fiscal de la Junta Directiva?</span>
                    <textarea disabled maxLength={300} value={ongP1.p40} name="p40" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #40..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">41. ¿Cuantos años lleva la Junta Directiva anual? ¿y para cuando finaliza su periodo?</span>
                    <textarea disabled maxLength={300} value={ongP1.p41} name="p41" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #41..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">42. ¿Tiene Estudios de Precio de Transferencia actualizados?</span><br/>
                    <select disabled name="p42">
                        <option value="No">{ongP1.p42}</option>
                    </select>
                </label>

                {ongP1.p42 === 'No' ? (
                    <span className="text-principal-2">No se subio ningún archivo</span>
                    ) : 
                    <div>
                        <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalThirteen}>Consultar</button>

                        {showModal13 ? (
                            <ModalPrompt
                            setShowModal={setShowModal13}
                            title={ongP1.p42}
                            content={
                                
                                <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p42} height="100%" width="100%"></iframe>
                                
                            }
                            />
                            ) : null}
                    </div>               
                }
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">43. ¿Efectuó la declaración de Precios de Transferencia?</span><br/>
                    <select disabled name="p43">
                        <option value="No">{ongP1.p43}</option>
                    </select>
                </label>

                {ongP1.p43 === 'No' ? (
                    <span className="text-principal-2">No se subio ningún archivo</span>
                    ) : 
                    <div>
                        <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalFourteen}>Consultar</button>

                        {showModal14 ? (
                        <ModalPrompt
                            setShowModal={setShowModal14}
                            title={ongP1.p43}
                            content={
                                
                                <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p43} height="100%" width="100%"></iframe>
                                
                            }
                            />
                            ) : null}
                    </div>               
                }
            </div>
            <br/>

          <button
                className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="submit"
                form="newOne"
                onClick={next}
            >Siguiente</button>
      </div>
    </div>
</div>
)}