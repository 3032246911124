import ONGP1 from "../../interfaces/finishedProgress";
import { useContext } from "react"
import { AuthContext } from "../../context/auth";
import { useNavigate } from 'react-router-dom';

interface UserRowProps{
    ongP1: ONGP1
}

export default function UserAdmin({
    ongP1
}:UserRowProps) 
{

    const navigate = useNavigate();

    function back()
    {
        navigate('/dashboard/previewp8c')
    }

    const userContext = useContext(AuthContext);
    function sendOne()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        fetch(`${userContext?.api_url}/user/getFinishedONGAll`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            }
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    console.log(resp);
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

return(
    <div className="justify-center ml-10 p-6 items-center overflow-x-hidden bg-principal-3">
        <div className="overflow-x-auto">
            <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase mb-5">
                PROGRESO - ONG
            </h1>
            <table className="table-auto w-full font-roboto text-principal-3">
                <thead className="text-xs font-semibold uppercase text-principal-2 bg-principal-1">
                    <tr>
                        <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-center">ID</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-center">Nombre</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-center">Cuestionario 1</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-center">Cuestionario 2</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-center">Cuestionario 3</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-center">Cuestionario 4</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-center">Cuestionario 5</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-center">Cuestionario 6</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-center">Cuestionario 7</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-center">Cuestionario 8</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-center">Cuestionario 9</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-center">Cuestionario 10</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-center">Cuestionario 11</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-center">Cuestionario 12</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-center">Cuestionario 13</div>
                        </th>
                        <th className="p-2 whitespace-nowrap">
                            <div className="font-semibold text-center">Cuestionario 14</div>
                        </th>
                    </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100 text-principal-2">
                <tr>
                    <td className="p-2 whitespace-nowrap">
                        <div className="font-medium text-center">
                            {String(ongP1.id)}
                        </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                        <div className="font-medium text-center">
                            {String(ongP1.nombre)}
                        </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                        <div className="font-medium text-center">
                            {String(ongP1.finishedone == true ? ('Terminado') : ('No Terminado'))}
                        </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                        <div className="font-medium text-center">
                            {String(ongP1.finishedtwo == true ? ('Terminado') : ('No Terminado'))}
                        </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                        <div className="font-medium text-center">
                            {String(ongP1.finishedthree == true ? ('Terminado') : ('No Terminado'))}
                        </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                        <div className="font-medium text-center">
                            {String(ongP1.finishedfour == true ? ('Terminado') : ('No Terminado'))}
                        </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                        <div className="font-medium text-center">
                            {String(ongP1.finishedfive == true ? ('Terminado') : ('No Terminado'))}
                        </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                        <div className="font-medium text-center">
                            {String(ongP1.finishedsix == true ? ('Terminado') : ('No Terminado'))}
                        </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                        <div className="font-medium text-center">
                            {String(ongP1.finishedseven == true ? ('Terminado') : ('No Terminado'))}
                        </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                        <div className="font-medium text-center">
                            {String(ongP1.finishedeight == true ? ('Terminado') : ('No Terminado'))}
                        </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                        <div className="font-medium text-center">
                            {String(ongP1.finishednine == true ? ('Terminado') : ('No Terminado'))}
                        </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                        <div className="font-medium text-center">
                            {String(ongP1.finishedten == true ? ('Terminado') : ('No Terminado'))}
                        </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                        <div className="font-medium text-center">
                            {String(ongP1.finishedeleven == true ? ('Terminado') : ('No Terminado'))}
                        </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                        <div className="font-medium text-center">
                            {String(ongP1.finishedtwelve == true ? ('Terminado') : ('No Terminado'))}
                        </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                        <div className="font-medium text-center">
                            {String(ongP1.finishedthirteen == true ? ('Terminado') : ('No Terminado'))}
                        </div>
                    </td>
                    <td className="p-2 whitespace-nowrap">
                        <div className="font-medium text-center">
                            {String(ongP1.finishedfourteen == true ? ('Terminado') : ('No Terminado'))}
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
      </div>
)}