import p5 from '../../interfaces/p5';
import React, { useContext, useEffect, useState } from 'react';
import ModalPrompt from "../ModalUpload";
import { AuthContext } from '../../context/auth';
import { useNavigate } from 'react-router-dom';

const FormExample6 = () => 
{

    const userContext = useContext(AuthContext);
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [file1, setFile1] = useState<any>();
    const [file7, setFile7] = useState<any>();
    const [file11, setFile11] = useState<any>();
    const [file12, setFile12] = useState<any>();
    const [file13, setFile13] = useState<any>();
    const [selected1, setSelected1] = useState();
    const [selected5, setSelected5] = useState();
    const [selected6, setSelected6] = useState();
    const [selected7, setSelected7] = useState();
    const [selected9, setSelected9] = useState();
    const [selected11, setSelected11] = useState();
    const [selected12, setSelected12] = useState();
    const [selected13, setSelected13] = useState();

    const handleFile1 = (e: any) => 
    {
        setFile1(e.target?.files[0]);
    }

    const handleFile7 = (e: any) => 
    {
        setFile7(e.target?.files[0]);
    }

    const handleFile11 = (e: any) => 
    {
        setFile11(e.target?.files[0]);
    }

    const handleFile12 = (e: any) => 
    {
        setFile12(e.target?.files[0]);
    }

    const handleFile13 = (e: any) => 
    {
        setFile13(e.target?.files[0]);
    }

    const handleChange1 = (event:any) => 
    {
        setSelected1(event.target.value)
        
    }

    const handleChange5 = (event:any) => 
    {
        setSelected5(event.target.value)
        
    }
    
    const handleChange6 = (event:any) => 
    {
        setSelected6(event.target.value)
        
    }

    const handleChange7 = (event:any) => 
    {
        setSelected7(event.target.value)
        
    }

    const handleChange9 = (event:any) => 
    {
        setSelected9(event.target.value)
        
    }

    const handleChange11 = (event:any) => 
    {
        setSelected11(event.target.value)
        
    }
    
    const handleChange12 = (event:any) => 
    {
        setSelected12(event.target.value)
        
    }

    const handleChange13 = (event:any) => 
    {
        setSelected13(event.target.value)
        
    }

    const handleUpload = () => 
    {
        const formData = new FormData();
        formData.append("file", file1);
        formData.append("file", file7);
        formData.append("file", file11);
        formData.append("file", file12);
        formData.append("file", file13);
        fetch(`${userContext?.api_url}/file/upload`, 
        {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.log(error));
    }

    async function sendOne(event: React.FormEvent<HTMLFormElement>)
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        event.preventDefault();

        let getP1: p5 = {
            fk_id_usuario: userContext?.currentUser?.id,
            p1: event.currentTarget.p1.value == 'No' ? (event.currentTarget.p1.value) : (file1.name),
            p2: event.currentTarget.p2.value,
            p3: event.currentTarget.p3.value,
            p4: event.currentTarget.p4.value,
            p5: event.currentTarget.p5.value == 'No' ? (event.currentTarget.p5.value) : (event.currentTarget.pp5.value),
            p6: event.currentTarget.p6.value == 'No' ? (event.currentTarget.p6.value) : (event.currentTarget.pp6.value),
            p7: event.currentTarget.p7.value == 'No' ? (event.currentTarget.p7.value) : (file7.name),
            p8: event.currentTarget.p8.value,
            p9: event.currentTarget.p9.value == 'No' ? (event.currentTarget.p9.value) : (event.currentTarget.pp9.value),
            p10: event.currentTarget.p10.value,
            p11: event.currentTarget.p11.value == 'No' ? (event.currentTarget.p11.value) : (file11.name),
            p12: event.currentTarget.p12.value == 'No' ? (event.currentTarget.p12.value) : (file12.name),
            p13: event.currentTarget.p13.value == 'No' ? (event.currentTarget.p13.value) : (file13.name),
            p14: event.currentTarget.p14.value,
            p15: event.currentTarget.p15.value,
            p16: event.currentTarget.p16.value,
            p17: event.currentTarget.p17.value,
            p18: event.currentTarget.p18.value,
            p19: event.currentTarget.p19.value,
            p20: event.currentTarget.p20.value,
            p21: event.currentTarget.p21.value,
            p22: event.currentTarget.p22.value,
            p23: event.currentTarget.p23.value
		}

        setShowModal(true);
        await fetch(`${userContext?.api_url}/user/upload`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                user_id: userContext?.currentUser?.id
            })
        }).then(resp => 
        {
            fetch(`${userContext?.api_url}/ong/fifthQuestionary`, 
            {
                method: 'POST',
                headers: 
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    fk_id_usuario: getP1.fk_id_usuario,
                    p1: getP1.p1,
                    p2: getP1.p2,
                    p3: getP1.p3,
                    p4: getP1.p4,
                    p5: getP1.p5,
                    p6: getP1.p6,
                    p7: getP1.p7,
                    p8: getP1.p8,
                    p9: getP1.p9,
                    p10: getP1.p10,
                    p11: getP1.p11,
                    p12: getP1.p12,
                    p13: getP1.p13,
                    p14: getP1.p14,
                    p15: getP1.p15,
                    p16: getP1.p16,
                    p17: getP1.p17,
                    p18: getP1.p18,
                    p19: getP1.p19,
                    p20: getP1.p20,
                    p21: getP1.p21,
                    p22: getP1.p22,
                    p23: getP1.p23
                })
        }).then(resp => 
            {   
                if (resp.status >= 400) 
                {
                    console.log(resp);
                }
                else
                {
                    setShowModal(false);
                    navigate('/ong/p6')
                }
            })
        }).catch((err) => 
        {
            console.log(err);
        })
    }

    function verifyStatus()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

            fetch(`${userContext?.api_url}/user/getFinished`, 
            {
                method: 'POST',
                headers: 
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    id: userContext?.currentUser?.id
                })
            }).then(resp => 
            {
                if (resp.status >= 400) 
                {
                    console.log(resp);
                } 
                else 
                {
                    resp.json().then(jsonResponse =>
                    {
                        let finished = jsonResponse; 
                        finished.map(()=>
                        {
                            if(finished[0].finishedfourteen === true)
                            {
                                navigate('/')
                            }
                            else if(finished[0].finishedfive === true)
                            {
                                navigate('/ong/p6')
                            }
                        })
                    })
                }
            }).catch((err) => 
            {
                console.log(err);
            })        
    }

    useEffect(() => {
        verifyStatus();
      });
    
  
  return (
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            CUMPLIMIENTO DE CONVENIO
        </h1>
        <form onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. ¿Existe un documento de acuerdo entre la organización y la Agencia de Cooperacion?</span><br/>
                    <select required name="p1" value={selected1} onChange={handleChange1}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected1 === 'Si' && 
                        <div>
                                <span className="text-principal-1">Subir en formato .pdf</span><br/>
                                <input onChange={handleFile1} className="text-principal-2" type="file" name ='p1' accept="application/pdf" required/>
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. ¿Se encontraba debidamente firmado el convenio de cooperacion por ambas partes?</span><br/>
                    <select required name="p2">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. ¿Se encontraba debidamente especificado en mombre contraactual del proyecto en mencion?</span><br/>
                    <select required name="p3">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                  <br/>
                  <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. ¿Existen todas las clausulas generales relevantes del proyecto, ejemplo; Fecha de inicio y duracion? ¿Nombre completo del proyecto?</span><br/>
                    <select required name="p4">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. ¿Esta implementado el presente convenio en las fechas establecidas en la carta de acuerdo del proyecto?</span><br/>
                    <select required name="p5" value={selected5} onChange={handleChange5}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected5 === 'Si' && 
                        <div>
                            <textarea required name="pp5" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Explique..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. ¿La organización contiene una cuenta bancaria separada independiente para el uso de los fondos del proyecto?</span><br/>
                    <select required name="p6" value={selected6} onChange={handleChange6}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected6 === 'Si' && 
                        <div>
                            <textarea required name="pp6" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. ¿Existe un presupuesto establecido en el convenio de cooperacion entre la organización y la Agencia Donante?</span><br/>
                    <select required name="p7" value={selected7} onChange={handleChange7}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected7 === 'Si' && 
                        <div>
                                <span className="text-principal-1">Subir en formato .pdf</span><br/>
                                <input onChange={handleFile7} className="text-principal-2" type="file" name ='p7' accept="application/pdf" required/>
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. ¿La organización cumplio las actividades del proyecto en el presupuesto?</span><br/>
                    <textarea required name="p8" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #8..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. Situación financiera y principales fuentes de financiamiento.</span><br/>
                    <select required name="p9" value={selected9} onChange={handleChange9}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected9 === 'Si' && 
                        <div>
                            <textarea required name="pp9" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. ¿Existe alguna solicitud a la Agencia Donantes sobre el sobregiro de la linea presupuestaria?</span><br/>
                    <select required name="p10">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. ¿Se encuentra esa solicitud aprobada por la Agencia Donante, o existe algun correo electronico afirmando la autorizacion?</span><br/>
                    <select required name="p11" value={selected11} onChange={handleChange11}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected11 === 'Si' && 
                        <div>
                                <span className="text-principal-1">Subir en formato .pdf</span><br/>
                                <input onChange={handleFile11} className="text-principal-2" type="file" name ='p11' accept="application/pdf" required/>
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. ¿Existe algun Adendum o ampliacion al proyecto? Si es No, pasar a la pregunta #15</span><br/>
                    <select required name="p12" value={selected12} onChange={handleChange12}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected12 === 'Si' && 
                        <div>
                                <span className="text-principal-1">Subir en formato .pdf</span><br/>
                                <input onChange={handleFile12} className="text-principal-2" type="file" name ='p12' accept="application/pdf" required/>
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-1">Si su respuesta fue 'No' en la pregunta 12, poner 'No' como respuesta de esta pregunta.</span><br/>
                    <span className="text-principal-2">13. Peculiaridades y políticas del sistema de contabilidad.</span><br/>
                    <select required name="p13" value={selected13} onChange={handleChange13}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected13 === 'Si' && 
                        <div>
                                <span className="text-principal-1">Subir en formato .pdf</span><br/>
                                <input onChange={handleFile13} className="text-principal-2" type="file" name ='p13' accept="application/pdf" required/>
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-1">Si su respuesta fue 'No' en la pregunta 12, poner 'No' como respuesta de esta pregunta.</span><br/>
                    <span className="text-principal-2">14. Opinión sobre el prestigio e imagen de la organización ante él publico.</span><br/>
                    <select required name="p14">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">15. ¿La Organización Ejecutadora Participa en abuso de fondos y/o acciones fraudulentas?</span><br/>
                    <select required name="p15">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">16. ¿La Organizción Ejecutora participa en practicas corruptas?</span><br/>
                    <select required name="p16">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">17. ¿La Organización Ejecutora participa en trabajo infantil conforme a las definiciones de la organización internacional de trabajo?</span><br/>
                    <select required name="p17">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">18. ¿La Organización Ejecutora viola las leyes laborables aplicables?</span><br/>
                    <select required name="p18">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">19. ¿La Organización Ejecutora viola las leyes medioambientables aplicables?</span><br/>
                    <select required name="p19">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">20. ¿La Organización Ejecutora tolera la discriminación o el acoso sexual e su organización?</span><br/>
                    <select required name="p20">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">21. ¿La Organización Ejecutora ya no se encuentra dispuesta o capacitada para llevar a cabo la ejecucion del contrato conforme a la solicitud?</span><br/>
                    <select required name="p21">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">22. ¿La Organización Ejecutora no ha presentado los informes mandatarios en un periodo de 8 meses?</span><br/>
                    <select required name="p22">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">23. ¿La Organización Ejecutora trasfiere la la ejecucion de actividades a otra agencia sin el previo consentimiento de la Agencia de cooperación?</span><br/>
                    <select required name="p23">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={handleUpload}
              >Guardar y Avanzar</button>

            {showModal ? (
                <ModalPrompt
                    setShowModal={setShowModal}
                    title={'Subiendo archivos'}
                    content={
                        <span className="text-principal-2">La subida de archivos dependerá de la velocidad de su internet, por favor espere.</span>
                    }
                />
                ) : null}
        </form>
      </div>
    </div>
  );
};

export default FormExample6;