import p6 from '../../interfaces/p6';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/auth';
import { useNavigate } from 'react-router-dom';

const FormExample6 = () => 
{

    const userContext = useContext(AuthContext);
    const navigate = useNavigate();

    const [selected1, setSelected1] = useState();
    const [selected5, setSelected5] = useState();
    const [selected6, setSelected6] = useState();
    const [selected7, setSelected7] = useState();
    const [selected31, setSelected31] = useState();

    const handleChange1 = (event:any) => 
    {
        setSelected1(event.target.value)
        
    }

    const handleChange5 = (event:any) => 
    {
        setSelected5(event.target.value)
        
    }
    
    const handleChange6 = (event:any) => 
    {
        setSelected6(event.target.value)
        
    }

    const handleChange7 = (event:any) => 
    {
        setSelected7(event.target.value)
        
    }

    const handleChange31 = (event:any) => 
    {
        setSelected31(event.target.value)
        
    }

    function sendOne(event: React.FormEvent<HTMLFormElement>)
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        event.preventDefault();

        let getP1: p6 = {
            fk_id_usuario: userContext?.currentUser?.id,
            p1: event.currentTarget.p1.value,
            p2: event.currentTarget.p2.value,
            p3: event.currentTarget.p3.value,
            p4: event.currentTarget.p4.value == 'No' ? (event.currentTarget.p4.value) : (event.currentTarget.pp4.value),
            p5: event.currentTarget.p5.value,
            p6: event.currentTarget.p6.value,
            p7: event.currentTarget.p7.value,
            p8: event.currentTarget.p8.value,
            p9: event.currentTarget.p9.value,
            p10: event.currentTarget.p10.value,
            p11: event.currentTarget.p11.value,
            p12: event.currentTarget.p12.value,
            p13: event.currentTarget.p13.value,
            p14: event.currentTarget.p14.value,
            p15: event.currentTarget.p15.value,
            p16: event.currentTarget.p16.value,
            p17: event.currentTarget.p17.value,
            p18: event.currentTarget.p18.value,
            p19: event.currentTarget.p19.value,
            p20: event.currentTarget.p20.value,
            p21: event.currentTarget.p21.value,
            p22: event.currentTarget.p22.value,
            p23: event.currentTarget.p23.value,
            p24: event.currentTarget.p24.value,
            p25: event.currentTarget.p25.value,
            p26: event.currentTarget.p26.value,
            p27: event.currentTarget.p27.value,
            p28: event.currentTarget.p28.value,
            p29: event.currentTarget.p29.value == 'No' ? (event.currentTarget.p29.value) : (event.currentTarget.pp29.value),
            p30: event.currentTarget.p30.value == 'No' ? (event.currentTarget.p30.value) : (event.currentTarget.pp30.value),
            p31: event.currentTarget.p31.value == 'No' ? (event.currentTarget.p31.value) : (event.currentTarget.pp31.value),
            p32: event.currentTarget.p32.value,
            p33: event.currentTarget.p33.value,
            p34: event.currentTarget.p34.value,
            p35: event.currentTarget.p35.value,
            p36: event.currentTarget.p36.value,
            p37: event.currentTarget.p37.value,
            p38: event.currentTarget.p38.value,
            p39: event.currentTarget.p39.value,
            p40: event.currentTarget.p40.value,
            p41: event.currentTarget.p41.value == 'No' ? (event.currentTarget.p41.value) : (event.currentTarget.pp41.value),
            p42: event.currentTarget.p42.value,
            p43: event.currentTarget.p43.value,
            p44: event.currentTarget.p44.value,
            p45: event.currentTarget.p45.value,
            p46: event.currentTarget.p46.value,
            p47: event.currentTarget.p47.value,
            p48: event.currentTarget.p48.value,
            p49: event.currentTarget.p49.value,
            p50: event.currentTarget.p50.value,
            p51: event.currentTarget.p51.value
		}

        fetch(`${userContext?.api_url}/ong/sixthQuestionary`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: getP1.fk_id_usuario,
                p1: getP1.p1,
                p2: getP1.p2,
                p3: getP1.p3,
                p4: getP1.p4,
                p5: getP1.p5,
                p6: getP1.p6,
                p7: getP1.p7,
                p8: getP1.p8,
                p9: getP1.p9,
                p10: getP1.p10,
                p11: getP1.p11,
                p12: getP1.p12,
                p13: getP1.p13,
                p14: getP1.p14,
                p15: getP1.p15,
                p16: getP1.p16,
                p17: getP1.p17,
                p18: getP1.p18,
                p19: getP1.p19,
                p20: getP1.p20,
                p21: getP1.p21,
                p22: getP1.p22,
                p23: getP1.p23,
                p24: getP1.p24,
                p25: getP1.p25,
                p26: getP1.p26,
                p27: getP1.p27,
                p28: getP1.p28,
                p29: getP1.p29,
                p30: getP1.p30,
                p31: getP1.p31,
                p32: getP1.p32,
                p33: getP1.p33,
                p34: getP1.p34,
                p35: getP1.p35,
                p36: getP1.p36,
                p37: getP1.p37,
                p38: getP1.p38,
                p39: getP1.p39,
                p40: getP1.p40,
                p41: getP1.p41,
                p42: getP1.p42,
                p43: getP1.p43,
                p44: getP1.p44,
                p45: getP1.p45,
                p46: getP1.p46,
                p47: getP1.p47,
                p48: getP1.p48,
                p49: getP1.p49,
                p50: getP1.p50,
                p51: getP1.p51
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    navigate('/ong/p7')
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

    function verifyStatus()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

            fetch(`${userContext?.api_url}/user/getFinished`, 
            {
                method: 'POST',
                headers: 
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    id: userContext?.currentUser?.id
                })
            }).then(resp => 
            {
                if (resp.status >= 400) 
                {
                    console.log(resp);
                } 
                else 
                {
                    resp.json().then(jsonResponse =>
                    {
                        let finished = jsonResponse; 
                        finished.map(()=>
                        {
                            if(finished[0].finishedfourteen === true)
                            {
                                navigate('/')
                            }
                            else if(finished[0].finishedsix === true)
                            {
                                navigate('/ong/p7')
                            }
                        })
                    })
                }
            }).catch((err) => 
            {
                console.log(err);
            })        
    }

    useEffect(() => {
        verifyStatus();
        window.scrollTo(0, 0);
      });
    
  
  return (
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            EFECTIVO Y EQUIVALENTES
        </h1>
        <form onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. ¿Cuantas cuentas bancarias existen abiertas?</span><br/>
                    <textarea required name="p1" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #1..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. ¿Cuál es el objeto de cada una de ellas?</span><br/>
                    <textarea required name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. ¿Todas las cuentas bancarias son utilizadas por la Organización?</span><br/>
                    <select required name="p3">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                  <br/>
                  <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. ¿Está centralizada la responsabilidad por las entradas y los depósitos de efectivo en el menor número posible de personas?</span><br/>
                    <select required name="p4" value={selected1} onChange={handleChange1}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected1 === 'Si' && 
                        <div>
                            <textarea required name="pp4" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Están todos los empleados que participan en las entradas, los pagos y que manejan efectivo y valores:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. ¿Adecuadamente afianzados?</span><br/>
                    <select required name="p5">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. ¿Obligados a tomar vacaciones anuales?</span><br/>
                    <select required name="p6">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Los empleados que manejan efectivo están desligados de algunos de los deberes siguientes:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. ¿Preparan recibos de ingresos o llevan registros de ingresos?</span><br/>
                    <select required name="p7">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. ¿Manejan o tiene acceso a los libros auxiliares?</span><br/>
                    <select required name="p8">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. ¿Manejan el libro mayor o el registro de la póliza?</span><br/>
                    <select required name="p9">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. ¿Preparan o aprueban los comprobantes de pagos?</span><br/>
                    <select required name="p10">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. ¿Preparan, firman o envían por correo los cheques de pagos?</span><br/>
                    <select required name="p11">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Ingreso de Caja:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. ¿Cuales son las principales fuentes de ingresos?</span><br/>
                    <textarea required name="p12" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #12..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. ¿Dónde y como se reciben los ingresos?</span><br/>
                    <textarea required name="p13" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #13..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Las entradas de dinero a caja por cantidades relativamente grandes, ¿son en cheques o transferencias y no en efectivo?</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. ¿Que clase de registro se lleva para las entradas de caja y quien lo lleva?</span><br/>
                    <textarea required name="p14" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #14..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">15. ¿Se depositan correctamentos los ingresos que corresponden a reintegros o devoluciones intactos y sin ningún retraso?</span><br/>
                    <textarea required name="p15" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #15..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">16. ¿Mantienen actualizado el control de los ingresos de caja hasta que se depositan en los bancos?</span><br/>
                    <select required name="p16">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">17. ¿Alguna persona independiente, efectúa periódicamente un examen por sorpresa de caja chica?</span><br/>
                    <select required name="p17">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">18. ¿Se entregan directamente a un empleado independiente del cajero las partidas de depósito o cobro devueltas por el banco? (debido a la insuficiencia de fondos, etc.)</span><br/>
                    <select required name="p18">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">19. ¿Se encuentra prohibido cambiar cheques por ingresos recibidos en efectivo y se respeta esa prohibición?</span><br/>
                    <select required name="p19">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">20. ¿Se han dado instrucciones escritas a los bancos para que no hagan efectivo ningún cheque a nombre de la Organización?</span><br/>
                    <select required name="p20">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Pagos:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">21.¿Que clase de registro de pagos se lleva y quien lo lleva?</span><br/>
                    <textarea required name="p21" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #21..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">22. ¿Se hacen por medio de cheques y/o transferencias todos los pagos, salvo los de caja chica?</span><br/>
                    <select required name="p22">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">23. ¿Se emplea una maquina protectora de cheques?</span><br/>
                    <select required name="p23">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">24. ¿Se mutilan o cancelan los cheques anulados para impedir que vuelvan a usarse y se adhieren a la póliza con el sello de cancelado?</span><br/>
                    <select required name="p24">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">En lo que respecta a los talonarios de cheques en blanco:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">25. ¿Se tienen en un lugar seguro?</span><br/>
                    <select required name="p25">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">26. ¿Son accesibles solamente a las personas autorizadas para preparar cheques y giros?</span><br/>
                    <select required name="p26">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">27. ¿Se prepara un Voucher de cheque o se hace un registro del cheque simultáneamente a su preparación?</span><br/>
                    <select required name="p27">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">28. ¿Se hacen los pagos solamente basándose en comprobantes aprobados con la documentación original de soporte adjunta?</span><br/>
                    <select required name="p28">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">29. ¿Se cancela con el sello de pagado los documentos correspondientes para impedir el pago duplicado?  En caso afirmativo Como, cuando y quién lo sella?</span><br/>
                    <select required name="p29" value={selected5} onChange={handleChange5}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected5 === 'Si' && 
                        <div>
                            <textarea required name="pp29" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">30. ¿Quiénes son las personas autorizadas para firmar los cheques? (Hágase una lista de los nombres y de los puestos que ocupan).</span><br/>
                    <select required name="p30" value={selected6} onChange={handleChange6}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected6 === 'Si' && 
                        <div>
                            <textarea required name="pp30" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">31. ¿Necesitan dos firmas todos los cheques que representen desembolsos de fondos en general? En caso negativo, ¿por qué se considera esto innecesario?</span><br/>
                    <select required name="p31" value={selected31} onChange={handleChange31}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected31 === 'Si' && 
                        <div>
                            <textarea required name="pp31" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">32. ¿Existen limitaciones a los importes de los cheques firmados por una sola persona?</span><br/>
                    <select required name="p32">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">33. ¿Las personas autorizadas tienen prohibido firmar cheques en blanco y respetan esa prohibición?</span><br/>
                    <select required name="p33">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Las personas autorizadas para firmar cheques están desligadas de los deberes siguientes:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">34. ¿Abre la correspondencia o hace una  lista de los ingresos de caja recibidos por correo?</span><br/>
                    <select required name="p34">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">35. ¿Actúa como cajero o tiene acceso a los ingresos de caja?</span><br/>
                    <select required name="p35">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">36. ¿Prepara la conciliación de las cuentas de los bancos?</span><br/>
                    <select required name="p36">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">37. ¿Prepara o revisa los comprobantes?</span><br/>
                    <select required name="p37">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">38. ¿Tiene bajo su custodia la caja chica u otros fondos especiales?</span><br/>
                    <select required name="p38">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Caja Chica:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">39. ¿Se hacen los pagos menores por medio de fondo fijo reembolsable contra entrega de comprobantes?</span><br/>
                    <select required name="p39">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">40. ¿Esta prohibido mezclar los ingresos de caja con el fondo de caja chica?</span><br/>
                    <select required name="p40">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">41. ¿Recae en una sola persona la responsabilidad por cada fondo?</span><br/>
                    <select required name="p41" value={selected7} onChange={handleChange7}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected7 === 'Si' && 
                        <div>
                            <textarea required name="pp41" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">42. ¿Están los pagos de caja chica soportados por documentos debidamente aprobados?</span><br/>
                    <select required name="p42">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">43. ¿Se comprueban en el momento del reembolso los documentos que sirven de apoyo a los pagos de la caja chica?</span><br/>
                    <select required name="p43">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">44. ¿Cuándo se reponen los fondos de la caja chica, (se cancelan con el sello de "PAGADO" y la fecha) los comprobantes para evitar el pago duplicado?</span><br/>
                    <select required name="p44">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">45. ¿No se expiden los cheques para reponer el fondo de caja chica a la orden de la persona que tiene a su cargo la custodia de la misma?</span><br/>
                    <select required name="p45">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Si el fondo de caja chica esta representado en parte por una cuenta bancaria:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">46. ¿Se ha notificado al banco que no debe aceptar para su depósito en la cuenta ningún cheque pagadero a la Organización?</span><br/>
                    <select required name="p46">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">47. ¿Se depositan alguna vez en esa cuenta  ingresos por cobros?</span><br/>
                    <select required name="p47">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>
            
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">48. ¿Se concilia la cuenta bancaria de la caja chica por un empleado independiente del encargado de su custodia?</span><br/>
                    <select required name="p48">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">49. ¿Está prohibido cambiar cheques de particulares con dinero del fondo?  En caso afirmativo, ¿se necesita autorización?</span><br/>
                    <select required name="p49">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">50. ¿Está prohibido hacer anticipos  de salarios y sueldos con dinero del fondo? En caso afirmativo, (se exigen que los anticipos se hiciera por medio de un cheque?</span><br/>
                    <select required name="p50">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">51. ¿Se revisan los fondos de caja chica  mediante  recuentos frecuentes y por sorpresa  por algún auditor interno u otra persona independiente?</span><br/>
                    <select required name="p51">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
              >Guardar y Avanzar</button>
        </form>
      </div>
    </div>
  );
};

export default FormExample6;