export default function WelcomePage(){

    /*function changeAuth(){
      logout();
    }*/
  
    return(
    <div className="flex w-full text-6xl font-semibold text-principal-2 items-center justify-center h-screen">
        <h1>¡Bienvenid@!</h1>
    </div>
    );
  }