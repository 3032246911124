import p4 from '../../interfaces/p4';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/auth';
import { useNavigate } from 'react-router-dom';

const FormExample6 = () => 
{

    const userContext = useContext(AuthContext);
    const navigate = useNavigate();

    const [selected1, setSelected1] = useState();
    const [selected3, setSelected3] = useState();
    const [selected6, setSelected6] = useState();
    const [selected9, setSelected9] = useState();
    const [selected11, setSelected11] = useState();
    const [selected12, setSelected12] = useState();
    const [selected13, setSelected13] = useState();

    const handleChange1 = (event:any) => 
    {
        setSelected1(event.target.value)
        
    }

    const handleChange3 = (event:any) => 
    {
        setSelected3(event.target.value)
        
    }
    
    const handleChange6 = (event:any) => 
    {
        setSelected6(event.target.value)
        
    }

    const handleChange9 = (event:any) => 
    {
        setSelected9(event.target.value)
        
    }

    const handleChange11 = (event:any) => 
    {
        setSelected11(event.target.value)
        
    }
    
    const handleChange12 = (event:any) => 
    {
        setSelected12(event.target.value)
        
    }

    const handleChange13 = (event:any) => 
    {
        setSelected13(event.target.value)
        
    }

    function sendOne(event: React.FormEvent<HTMLFormElement>)
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        event.preventDefault();

        let getP1: p4 = {
            fk_id_usuario: userContext?.currentUser?.id,
            p1: event.currentTarget.p1.value,
            p2: event.currentTarget.p2.value,
            p3: event.currentTarget.p3.value == 'No' ? (event.currentTarget.p3.value) : (event.currentTarget.pp3.value),
            p4: event.currentTarget.p4.value,
            p5: event.currentTarget.p5.value,
            p6: event.currentTarget.p6.value == 'No' ? (event.currentTarget.p6.value) : (event.currentTarget.pp6.value),
            p7: event.currentTarget.p7.value,
            p8: event.currentTarget.p8.value,
            p9: event.currentTarget.p9.value == 'No' ? (event.currentTarget.p9.value) : (event.currentTarget.pp9.value),
            p10: event.currentTarget.p10.value,
            p11: event.currentTarget.p11.value == 'No' ? (event.currentTarget.p11.value) : (event.currentTarget.pp11.value),
            p12: event.currentTarget.p12.value == 'No' ? (event.currentTarget.p12.value) : (event.currentTarget.pp12.value),
            p13: event.currentTarget.p13.value == 'No' ? (event.currentTarget.p13.value) : (event.currentTarget.pp13.value),
            p14: event.currentTarget.p14.value == 'No' ? (event.currentTarget.p14.value) : (event.currentTarget.pp14.value),
		}

        fetch(`${userContext?.api_url}/ong/fourthQuestionary`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: getP1.fk_id_usuario,
                p1: getP1.p1,
                p2: getP1.p2,
                p3: getP1.p3,
                p4: getP1.p4,
                p5: getP1.p5,
                p6: getP1.p6,
                p7: getP1.p7,
                p8: getP1.p8,
                p9: getP1.p9,
                p10: getP1.p10,
                p11: getP1.p11,
                p12: getP1.p12,
                p13: getP1.p13,
                p14: getP1.p14
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    navigate('/ong/p5')
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

    function verifyStatus()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

            fetch(`${userContext?.api_url}/user/getFinished`, 
            {
                method: 'POST',
                headers: 
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    id: userContext?.currentUser?.id
                })
            }).then(resp => 
            {
                if (resp.status >= 400) 
                {
                    console.log(resp);
                } 
                else 
                {
                    resp.json().then(jsonResponse =>
                    {
                        let finished = jsonResponse; 
                        finished.map(()=>
                        {
                            if(finished[0].finishedfourteen === true)
                            {
                                navigate('/')
                            }
                            else if(finished[0].finishedfour === true)
                            {
                                navigate('/ong/p5')
                            }
                        })
                    })
                }
            }).catch((err) => 
            {
                console.log(err);
            })        
    }

    useEffect(() => {
        verifyStatus();
        window.scrollTo(0, 0);
      });
    
  
  return (
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
      <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            PROCESOS INTERNOS
        </h1>
        <p className="font-semibold text-center text-principal-2 uppercase">Cuentas por cobrar</p>
        <form onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. ¿Existe una separación definida en las labores de los empleados que manejan las cuentas por cobrar, los fondos de caja, la correspondencia y los registros contables?</span><br/>
                    <textarea required name="p1" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #1..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. ¿Se envían confirmaciones de saldo por sorpresa periódicamente por los auditores internos o por algún funcionario responsable?</span><br/>
                    <select required name="p2">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                        <option value="No Aplica">No Aplica</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. ¿Se somete a la aprobación de un funcionario responsable la aceptación de los documentos y garantías colaterales?</span><br/>
                    <select required name="p3" value={selected3} onChange={handleChange3}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected3 === 'Si' && 
                        <div>
                            <textarea required name="pp3" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Nombre de Representante..." />
                        </div>}
                  <br/>
                  <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. ¿Se llevan registros detallados de los documentos por cobrar y de las garantías colaterales?</span><br/>
                    <select required name="p4">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. ¿Los auditores internos u otros funcionarios independientes practican periódicamente una inspección de los documentos?</span><br/>
                    <select required name="p5">
                        <option value="No">No</option>
                        <option value="Si -> Semanal">Si -{'>'} Semanal</option>
                        <option value="Si -> Quincenal">Si -{'>'} Quincenal</option>
                        <option value="Si -> Mensual">Si -{'>'} Mensual</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. ¿Se notifica puntualmente a algún funcionario responsable el vencimiento de los documentos?</span><br/>
                    <select required name="p6" value={selected6} onChange={handleChange6}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected6 === 'Si' && 
                        <div>
                            <textarea required name="pp6" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Nombre de Representante..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. ¿Que documento se utiliza para establecer las cuentas por cobrar derivadas de el giro de la organización?</span><br/>
                    <textarea required name="p7" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #7..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. ¿Se vigila que esta cuenta sea utilizada para albergar cuentas por cobrar no derivadas del giro u objeto de la entidad?</span><br/>
                    <textarea required name="p8" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #8..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. ¿Son autorizados los anticipos a empleados por un funcionario apropiado?</span><br/>
                    <select required name="p9" value={selected9} onChange={handleChange9}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                        <option value="No Aplica">No Aplica</option>
                    </select>
                        {selected9 === 'Si' && 
                        <div>
                            <textarea required name="pp9" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Nombre de Representante..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. ¿Se tiene establecido un monto máximo para prestamos a empleados?</span><br/>
                    <textarea required name="p10" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #10..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. ¿Son revisados los saldos periódicamente por un funcionario responsable?</span><br/>
                    <select required name="p11" value={selected11} onChange={handleChange11}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                        <option value="No Aplica">No Aplica</option>
                    </select>
                        {selected11 === 'Si' && 
                        <div>
                            <textarea required name="pp11" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Nombre de Representante..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Están los ajustes o cancelación de cuentas  por cobrar aprobadas por un funcionario que no sea responsable de:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. ¿Su autorización?</span><br/>
                    <select required name="p12" value={selected12} onChange={handleChange12}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                        <option value="No Aplica">No Aplica</option>
                    </select>
                        {selected12 === 'Si' && 
                        <div>
                            <textarea required name="pp12" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Nombre de Representante..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. ¿Su cobro?</span><br/>
                    <select required name="p13" value={selected13} onChange={handleChange13}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected13 === 'Si' && 
                        <div>
                            <textarea required name="pp13" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Nombre de Representante..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. ¿Prevé el sistema el registro adecuado y oportuno de ingresos provenientes de rentas, regalías, intereses?</span><br/>
                    <select required name="p14" value={selected1} onChange={handleChange1}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                        <option value="No Aplica">No Aplica</option>
                    </select>
                        {selected1 === 'Si' && 
                        <div>
                            <textarea required name="pp14" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Nombre de Representante..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
              >Guardar y Avanzar</button>
        </form>
      </div>
    </div>
  );
};

export default FormExample6;