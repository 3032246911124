import ONGP1 from "../../interfaces/p8";
import { useContext, useState } from "react"
import { AuthContext } from "../../context/auth";
import { useNavigate } from 'react-router-dom';
import ModalPrompt from "../ModalFiles";

interface UserRowProps{
    ongP1: ONGP1
}

export default function UserAdmin({
    ongP1
}:UserRowProps) 
{

    const navigate = useNavigate();
    const userContext = useContext(AuthContext);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModal2, setShowModal2] = useState<boolean>(false);
    const [showModal3, setShowModal3] = useState<boolean>(false);

    function next()
    {
        navigate('/dashboard/previewp9')
    }

    function back()
    {
        navigate('/dashboard/previewp7')
    }

    function modalOne()
    {
        setShowModal(true)
    }

    function modalTwo()
    {
        setShowModal2(true)
    }

    function modalThree()
    {
        setShowModal3(true)
    }

    function sendOne()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        fetch(`${userContext?.api_url}/ong/listEightONG`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: localStorage.getItem('id')
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    console.log(resp);
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

return(
    <div className="justify-center overflow-hidden">
    <div className="w-full p-6 m-auto lg:max-w-xl">
      <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
          IMPUESTOS POR PAGAR
      </h1>
      <form onSubmit={sendOne} id="newOne" className="mt-6">
          <div className="mb-2">
              <label>
                  <span className="text-principal-2">1. ¿Se vigila el cumplimiento oportuno de las obligaciones fiscales a cargo de la entidad a efecto de evitar contingencias que puedan alterar su estabilidad?</span><br/>
                  <select disabled name="p1">
                      <option value="No">{ongP1.p1}</option>
                  </select>
                  <br/>
                  <br/>
              </label>
          </div>

          <div className="mb-2">
              <label>
                  <span className="text-principal-2">2. ¿Se archivan de manera adecuada todas las declaraciones fiscales presentadas?</span><br/>
                  <select disabled name="p2">
                      <option value="No">{ongP1.p2}</option>
                  </select>
                  <br/>
                  <br/>
              </label>
          </div>

          <div className="mb-2">
              <label>
                  <span className="text-principal-2">3. ¿Cuenta la entidad con un calendario de obligaciones fiscales?</span><br/>
                  <select disabled name="p3">
                      <option value="No">{ongP1.p3}</option>
                  </select>
                <br/>
                <br/>
              </label>
          </div>

          <span className="text-principal-2">Se revisa la documentación y comprobantes en cuanto a:</span><br/>
          <div className="mb-2">
              <label>
                  <span className="text-principal-2">4. ¿Cuales son las obligaciones fiscales en materia de ISR, Impuesto al Activo e IVA que tiene registrada la organización?</span><br/>
                  <textarea disabled name="p4" value={ongP1.p4} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #4..." />
                  <br/>
                  <br/>
              </label>
          </div>

          <div className="mb-2">
              <label>
                  <span className="text-principal-2">5. En caso de haber pagado contribuciones mediante compensación, ¿se verificó que estén presentados los avisos correspondientes?</span><br/>
                  <textarea disabled name="p5" value={ongP1.p5} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #5..." />
                  <br/>
                  <br/>
              </label>
          </div>

          <div className="mb-2">
              <label>
                  <span className="text-principal-2">6. ¿Se han presentado declaraciones del Impuesto Sobre La Renta del los últimos 3 años anteriores?</span><br/>
                  <select disabled name="p6">
                      <option value="No">{ongP1.p6}</option>
                  </select>
                  </label>
                {ongP1.p6 === 'No' ? (
                    <span className="text-principal-2">No se subio ningún archivo</span>
                    ) : 
                    <div>
                        <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalOne}>Consultar</button>

                        {showModal ? (
                            <ModalPrompt
                            setShowModal={setShowModal}
                            title={ongP1.p6}
                            content={
                                
                                <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p6} height="100%" width="100%"></iframe>
                                
                            }
                            />
                            ) : null}
                    </div>               
                }
            </div>
            <br/>

          <div className="mb-2">
              <label>
                  <span className="text-principal-2">7. ¿Preparan recibos de ingresos o llevan registros de ingresos?</span><br/>
                  <select disabled name="p7">
                      <option value="No">{ongP1.p7}</option>
                  </select>
                  </label>
                {ongP1.p7 === 'No' ? (
                    <span className="text-principal-2">No se subio ningún archivo</span>
                    ) : 
                    <div>
                        <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalTwo}>Consultar</button>

                        {showModal2 ? (
                            <ModalPrompt
                            setShowModal={setShowModal2}
                            title={ongP1.p7}
                            content={
                                
                                <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p7} height="100%" width="100%"></iframe>
                                
                            }
                            />
                            ) : null}
                    </div>               
                }
            </div>
            <br/>

          <div className="mb-2">
              <label>
                  <span className="text-principal-2">8. ¿Mantiene en resguardo toda la documentación concernientes a las obligaciones fiscales de la organización?</span><br/>
                  <select disabled name="p8">
                      <option value="No">{ongP1.p8}</option>
                  </select>
                  <br/>
                  <br/>
              </label>
          </div>

          <div className="mb-2">
              <label>
                  <span className="text-principal-2">9. ¿Se realizan mensualmente las declaraciones concernientes al ISR de la Fuente de la Organización?</span><br/>
                  <select disabled name="p9">
                      <option value="No">{ongP1.p9}</option>
                  </select>
                  <br/>
                  <br/>
              </label>
          </div>

          <div className="mb-2">
              <label>
                  <span className="text-principal-2">10. ¿Contiene un detalle especifico por mes de las personas que se les realiza la deduccion correspondiente al ISR de la Fuente?</span><br/>
                  <select disabled name="p10">
                      <option value="No">{ongP1.p10}</option>
                  </select>
                  </label>
                {ongP1.p10 === 'No' ? (
                    <span className="text-principal-2">No se subio ningún archivo</span>
                    ) : 
                    <div>
                        <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalThree}>Consultar</button>

                        {showModal3 ? (
                            <ModalPrompt
                            setShowModal={setShowModal3}
                            title={ongP1.p10}
                            content={
                                
                                <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p10} height="100%" width="100%"></iframe>
                                
                            }
                            />
                            ) : null}
                    </div>               
                }
            </div>
            <br/>

          <div className="mb-2">
              <label>
                  <span className="text-principal-2">11. ¿Mantiene actualizado el sistema de DET LIVE de la plataforma virtual de la Servicios de Administración de Renta (SAR)?</span><br/>
                  <select disabled name="p11">
                      <option value="No">{ongP1.p11}</option>
                  </select>
                  <br/>
                  <br/>
              </label>
          </div>

          <div className="mb-2">
              <label>
                  <span className="text-principal-2">12. ¿Registra mensualmente las declaraciones mensuales de retencion (DMR) al sistema DET 5.5.?</span><br/>
                  <select disabled name="p12">
                      <option value="No">{ongP1.p12}</option>
                  </select>
                  <br/>
                  <br/>
              </label>
          </div>

          <div className="mb-2">
              <label>
                  <span className="text-principal-2">13. ¿Se encuentran subidas en la plataforma virtual de la Servicios de Administración de Renta (SAR)?</span><br/>
                  <select disabled name="p13">
                      <option value="No">{ongP1.p13}</option>
                  </select>
                  <br/>
                  <br/>
              </label>
          </div>

          <div className="mb-2">
              <label>
                  <span className="text-principal-2">14. ¿Se realizan las retenciones del 12.5% de servicios profesionales mensualmente?</span><br/>
                  <select disabled name="p14">
                      <option value="No">{ongP1.p14}</option>
                  </select>
                  <br/>
                  <br/>
              </label>
          </div>

          <div className="mb-2">
              <label>
                  <span className="text-principal-2">15. ¿Se encuentran las declaraciones del 12.5% de servicios profesionales detalladas por cada persona retenida?</span><br/>
                  <select disabled name="p15">
                      <option value="No">{ongP1.p15}</option>
                  </select> 
                  <br/>
                  <br/>
              </label>
          </div>

          <div className="mb-2">
              <label>
                  <span className="text-principal-2">16. ¿Existe un expediente relacionado con los servicios profesionales que estan sujetos a la retencion del 12.5%?</span><br/>
                  <select disabled name="p16">
                      <option value="No">{ongP1.p16}</option>
                  </select>
                  <br/>
                  <br/>
              </label>
          </div>

          <div className="mb-2">
              <label>
                  <span className="text-principal-2">17. ¿Emiten la organización la constancia de retención a cada servicio profesional, de acuerdo al nuevo regimen de facturación de la SAR?</span><br/>
                  <select disabled name="p17">
                      <option value="No">{ongP1.p17}</option>
                  </select>
                  <br/>
                  <br/>
              </label>
          </div>

          <div className="mb-2">
              <label>
                  <span className="text-principal-2">18. ¿Esta actualizado el libro de retenciones autorizado por la Alcaldia Municipal y la Servicios de Administración de Renta (SAR)?</span><br/>
                  <select disabled name="p18">
                      <option value="No">{ongP1.p18}</option>
                  </select>
                  <br/>
                  <br/>
              </label>
          </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={next}
              >Siguiente</button>
        <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={back}
              >Atras</button>
        </form>
      </div>
    </div>
)}