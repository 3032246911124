import ONGP1 from "../../interfaces/p13";
import { useContext } from "react"
import { AuthContext } from "../../context/auth";
import { useNavigate } from 'react-router-dom';

interface UserRowProps{
    ongP1: ONGP1
}

export default function UserAdmin({
    ongP1
}:UserRowProps) 
{

    const navigate = useNavigate();

    function next()
    {
        navigate('/dashboard/previewp14')
    }

    function back()
    {
        navigate('/dashboard/previewp12')
    }

    const userContext = useContext(AuthContext);
    function sendOne()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        fetch(`${userContext?.api_url}/ong/listThirteenONG`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: localStorage.getItem('id')
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    console.log(resp);
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

return(
    <div className="w-full p-6 m-auto lg:max-w-xl">
    <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
        GESTIÓN ADMINISTRATIVA Y CONTABLE
    </h1>
    <form onSubmit={sendOne} id="newOne" className="mt-6">
        <div className="mb-2">
            <label>
                <span className="text-principal-2">1. ¿Poseen Manuales y Procedimientos Administrativos Contables?</span><br/>
                <select disabled name="p1">
                    <option value="No">{ongP1.p1}</option>
              </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">2. ¿Poseen Procesos de control interno y/o Tipos de control interno?</span><br/>
                <select disabled name="p2">
                    <option value="No">{ongP1.p2}</option>
              </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">3. ¿Poseen Manual de Planificación y preparación de Presupuestos?</span><br/>
                <select disabled name="p3">
                    <option value="No">{ongP1.p3}</option>
              </select>
              <br/>
              <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">4. ¿Contienen procedimientos de Gestión de Fondos y Acuerdos?</span><br/>
                <select disabled name="p4">
                    <option value="No">{ongP1.p4}</option>
              </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">5. ¿Se encuentran bien definidas las políticas, reglas y procedimientos de la organización?</span><br/>
                <select disabled name="p5">
                    <option value="No">{ongP1.p5}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">6. ¿Poseen Reglamentos Internos de Trabajo e Higiene?</span><br/>
                <select disabled name="p6">
                    <option value="No">{ongP1.p6}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">7. ¿Poseen un Manual Contable con el Plan de Cuentas General?</span><br/>
                <select disabled name="p7">
                    <option value="No">{ongP1.p7}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">8. ¿La organización tiene el Cumplimiento de las Normas de Contabilidad Generalmente Aceptadas (PCGA's)?</span><br/>
                <select disabled name="p8">
                    <option value="No">{ongP1.p8}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">9. ¿La organización contiene un eficiente control de efectivo y equivalentes?</span><br/>
                <select disabled name="p9">
                    <option value="No">{ongP1.p9}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">10. ¿Manejan control de Activos Fijos? (Propiedad, Planta y Equipo)</span><br/>
                <select disabled name="p10">
                    <option value="No">{ongP1.p10}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">11. ¿Manejan Control de Ingresos?</span><br/>
                <select disabled name="p11">
                    <option value="No">{ongP1.p11}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">12. ¿Manejan Control de Compras y Adquisiciones?</span><br/>
                <select disabled name="p12">
                    <option value="No">{ongP1.p12}</option>
              </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">13. ¿Manejan Control de Cuentas por Cobrar y Adelantos a empleados?</span><br/>
                <select disabled name="p13">
                    <option value="No">{ongP1.p13}</option>
              </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">14. ¿Manejan Control de Liquidaciones por viáticos y gastos reembolsables?</span><br/>
                <textarea disabled value={ongP1.p14} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">15. ¿Contienen Manual de Liquidaciones?</span><br/>
                <select disabled name="p15">
                    <option value="No">{ongP1.p15}</option>
              </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">16. ¿Contienen Control de Viáticos y Gastos de Viaje Nacional y Extranjero?</span><br/>
                <select disabled name="p16">
                    <option value="No">{ongP1.p16}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">17. ¿Existen Manuales de Viáticos y Gastos de Viaje?</span><br/>
                <select disabled name="p17">
                    <option value="No">{ongP1.p17}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">18. ¿Efectúan Estados Financieros como ser:(BG, ER, FC, EJEC. P. CP.)</span><br/>
                <select disabled name="p18">
                    <option value="No">{ongP1.p18}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">19. ¿Contienen Control de Gastos y Egresos?</span><br/>
                <select disabled name="p19">
                    <option value="No">{ongP1.p19}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">20. ¿Contienen Control de Compras de Alimentos y de cocina?</span><br/>
                <select disabled name="p20">
                    <option value="No">{ongP1.p20}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">21. ¿Contiene un control adecuado por la compra del material didáctico y de oficina?</span><br/>
                <select disabled name="p21">
                    <option value="No">{ongP1.p21}</option>
              </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">22. ¿Contienen uso de Fondo de Caja Chica?</span><br/>
                <textarea disabled value={ongP1.p22} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">23. ¿Utilizan Auxiliar o control de Bancos en Moneda Nacional y Extranjera?</span><br/>
                <select disabled name="p23">
                    <option value="No">{ongP1.p23}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">24. ¿Contienen Libros Legales? (Compras, Ingresos, Diario, Balanza, Mayor)</span><br/>
                <select disabled name="p24">
                    <option value="No">{ongP1.p24}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">25. ¿Utilizan Ordenes de Compra?</span><br/>
                <select disabled name="p25">
                    <option value="No">{ongP1.p25}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">26. ¿Contienen Ordenes de Requisiciones externas e internas?</span><br/>
                <select disabled name="p26">
                    <option value="No">{ongP1.p6}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">27. ¿Contienen recibos o comprobantes de gastos enumerados?</span><br/>
                <select disabled name="p27">
                    <option value="No">{ongP1.p27}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">28. ¿ Contienen recibos o comprobantes de ingresos enumerados?</span><br/>
                <select disabled name="p28">
                    <option value="No">{ongP1.p28}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">29. ¿Contienen formato de Solicitud de Gastos y Egresos?</span><br/>
                <select disabled name="p29">
                    <option value="No">{ongP1.p29}</option>
              </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">30. ¿Contienen formato de Liquidación de Gastos?</span><br/>
                <select disabled name="p30">
                    <option value="No">{ongP1.p30}</option>
              </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">31. ¿Contienen formato de Solicitud de Viáticos y gastos de viaje?</span><br/>
                <select disabled name="p31">
                    <option value="No">{ongP1.p31}</option>
              </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">32. ¿Contienen formato de Liquidación de Viáticos y gastos de viaje?</span><br/>
                <select disabled name="p32">
                    <option value="No">{ongP1.p32}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">33. ¿Contienen Manual de políticas de desembolsos y compras al por mayor?</span><br/>
                <select disabled name="p33">
                    <option value="No">{ongP1.p33}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">34. ¿Contienen detalles y registros de los activos fijos en uso y existencia?</span><br/>
                <select disabled name="p34">
                    <option value="No">{ongP1.p34}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">35. Mencione cual es el procedimiento que utiliza para controlar los activos fijos.</span><br/>
                <textarea disabled value={ongP1.p35} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">36. ¿Contiene registros de cada activo en uso en expedientes separados?</span><br/>
                <select disabled name="p36">
                    <option value="No">{ongP1.p36}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">37. ¿Esta seccionado por Responsable y cuenta contable los activos fijos?</span><br/>
                <select disabled name="p37">
                    <option value="No">{ongP1.p37}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">38. ¿Posee comprobante de uso por responsable de los activos fijos de la organización?</span><br/>
                <select disabled name="p38">
                    <option value="No">{ongP1.p38}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">39. ¿Están debidamente etiquetados los activos fijos de la organización?</span><br/>
                <select disabled name="p39">
                    <option value="No">{ongP1.p39}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">40. ¿Se llevan registros de las bajas y altas de los inventarios del activo fijo?</span><br/>
                <select disabled name="p40">
                    <option value="No">{ongP1.p40}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">41. ¿ Se encuentra los activos fijos de la organización asegurados bajo una póliza?</span><br/>
                <select disabled name="p41">
                    <option value="No">{ongP1.p41}</option>
              </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">42. ¿Están los pagos de caja chica soportados por documentos debidamente aprobados?</span><br/>
                <textarea disabled value={ongP1.p42} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">43. ¿Contiene y cumplen políticas y normas de los activos fijos?</span><br/>
                <select disabled name="p43">
                    <option value="No">{ongP1.p43}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">44. ¿Existe control de inventarios de salidas y entradas de materiales y suministros?</span><br/>
                <select disabled name="p44">
                    <option value="No">{ongP1.p44}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">45. ¿Contiene un formato de salidas y entradas por departamentos de los inventarios de materiales y suministros?</span><br/>
                <select disabled name="p45">
                    <option value="No">{ongP1.p45}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">46. ¿Están reflejados en los Estados Financieros de la organización el costo razonable de los inventarios de materiales y suministros?</span><br/>
                <select disabled name="p46">
                    <option value="No">{ongP1.p46}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">47. ¿Existe un expediente de cada empleado de la organización?</span><br/>
                <select disabled name="p47">
                    <option value="No">{ongP1.p47}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>
        
        <div className="mb-2">
            <label>
                <span className="text-principal-2">48. ¿ Contiene un control de nóminas y pagos de salarios? ¿Que modalidad de pago?</span><br/>
                <textarea disabled value={ongP1.p48} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">49. ¿Contiene un formato de pago por cada empleado?</span><br/>
                <select disabled name="p49">
                    <option value="No">{ongP1.p49}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">50. ¿Cual es el tipo de modalidad de pago?</span><br/>
                <textarea disabled name="p50" value={ongP1.p50} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #50..." />
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">51. ¿Contiene un control de entrada y salidas de los empleados? ¿Cual?</span><br/>
                <textarea disabled value={ongP1.p51} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">52. ¿Contiene control inmediato de las entradas y salidas de empleados en los centros y programas que están fuera de las oficinas principales?</span><br/>
                <select disabled name="p52">
                    <option value="No">{ongP1.p52}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">53. ¿Existe algún mecanismo de reclamo o modificaciones en los salarios?</span><br/>
                <select disabled name="p53">
                    <option value="No">{ongP1.p53}</option>
              </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">54. ¿De que manera se le informa al empleado(a) la recepción de su salario?</span><br/>
                <textarea disabled name="p54" value={ongP1.p54} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="¿Como?..." />
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">55. ¿Contienen adelantos o anticipos de salarios?</span><br/>
                <select disabled name="p55">
                    <option value="No">{ongP1.p55}</option>
              </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">56. ¿Está la organización inscrita a Cooperativas de Ahorros y Créditos?</span><br/>
                <select disabled name="p56">
                    <option value="No">{ongP1.p56}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">57. ¿Contiene un registro detallado de las deducciones por cada empleado(a)?</span><br/>
                <select disabled name="p57">
                    <option value="No">{ongP1.p57}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">58. ¿Existen bonificaciones por resultados o gratificaciones a los empleados(as)?</span><br/>
                <select disabled name="p58">
                    <option value="No">{ongP1.p58}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">59. ¿Las prestaciones sociales se pagan al momento del retiro del empleado(a)?</span><br/>
                <select disabled name="p59">
                    <option value="No">{ongP1.p59}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">60. ¿Se pagan y calculan horas extras?</span><br/>
                <select disabled name="p60">
                    <option value="No">{ongP1.p60}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">61. ¿ Se pagan y calculan depreciación por uso de automóvil?</span><br/>
                <select disabled name="p61">
                    <option value="No">{ongP1.p61}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">62. ¿Contienen un Manual de Créditos y cuentas por cobrar?</span><br/>
                <select disabled name="p62">
                    <option value="No">{ongP1.p62}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">63. ¿Existe un auxiliar de los Microcréditos y cuentas por cobrar?</span><br/>
                <select disabled name="p63">
                    <option value="No">{ongP1.p63}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">64. ¿Contiene un formato de pago a Microcréditos o cuentas por cobrar?</span><br/>
                <select disabled name="p64">
                    <option value="No">{ongP1.p64}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">65. ¿Existe un departamento de caja que recepcione los pagos de Microcréditos o las cuentas por cobrar?</span><br/>
                <select disabled name="p65">
                    <option value="No">{ongP1.p65}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">66. ¿Que formato o control utiliza para la recuperación de créditos vencidos?</span><br/>
                <textarea disabled name="p66" value={ongP1.p66} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="¿Formato?..." />
                <br/>
                <br/>
            </label>
        </div>

        <div className="mb-2">
            <label>
                <span className="text-principal-2">67. ¿Se cuenta con informes mensuales de la cartera crediticia?</span><br/>
                <select disabled name="p67">
                    <option value="No">{ongP1.p67}</option>
                </select>
                <br/>
                <br/>
            </label>
        </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={next}
              >Siguiente</button>
        <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={back}
              >Atras</button>
        </form>
      </div>
)}