import { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../context/auth";
import { APIGET } from "../../utils/api";
import ONGDash from "../../interfaces/dash";
import ONGDashCard from "../mkt/mktDashCard";

export default function UserAdmin() 
{

    localStorage.removeItem('id');

	const userContext = useContext(AuthContext);
    const [dashONGList, setdashONGList] = useState<ONGDash[]>([]);
    const [showResult, setResult] = useState<boolean>(false);

    function renderONGDashboard() {
        return dashONGList.map((currentDash: ONGDash) => <ONGDashCard ongDash={currentDash}/>);
    }

    function getQuestionariesONG() 
    {
        APIGET('POST', `${userContext?.api_url}/company/listDashboardComp`).then((resp) => 
        {
            if (resp.status === "OK") 
            {
                if(resp.info.length === 0)
                {
                    setResult(false);
                }
                else
                {
                    setdashONGList(resp.info);
                    setResult(true);
                }
            }
        })
	}

    useEffect(() => {
		getQuestionariesONG();
	}, [])

    return(
        <div className="justify-center items-center content-center flex overflow-x-hidden overflow-y-hidden inset-0 z-50 outline-none focus:outline-none bg-principal-3">
        {showResult ? (
                renderONGDashboard()
			    ) :     <div className="flex w-full text-3xl font-semibold text-principal-2 items-center justify-center h-screen">
                <h1>Aún no se ha llenado ningún cuestionario.</h1>
            </div>}
	</div>
    );
}