import ONGP1 from "../../interfaces/p5";
import { useContext, useState } from "react"
import { AuthContext } from "../../context/auth";
import { useNavigate } from 'react-router-dom';
import ModalPrompt from "../ModalFiles";

interface UserRowProps{
    ongP1: ONGP1
}

export default function UserAdmin({
    ongP1
}:UserRowProps) 
{

    const navigate = useNavigate();
    const userContext = useContext(AuthContext);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModal2, setShowModal2] = useState<boolean>(false);
    const [showModal3, setShowModal3] = useState<boolean>(false);
    const [showModal4, setShowModal4] = useState<boolean>(false);
    const [showModal5, setShowModal5] = useState<boolean>(false);

    function next()
    {
        navigate('/dashboard/previewp6')
    }

    function back()
    {
        navigate('/dashboard/previewp4')
    }

    function modalOne()
    {
        setShowModal(true)
    }

    function modalTwo()
    {
        setShowModal2(true)
    }

    function modalThree()
    {
        setShowModal3(true)
    }

    function modalFour()
    {
        setShowModal4(true)
    }

    function modalFive()
    {
        setShowModal5(true)
    }

    function sendOne()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        fetch(`${userContext?.api_url}/ong/listFifthONG`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: localStorage.getItem('id')
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    console.log(resp);
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

return(
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            CUMPLIMIENTO DE CONVENIO
        </h1>
        <div onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. ¿Existe un documento de acuerdo entre la organización y la Agencia de Cooperacion?</span><br/>
                    <select disabled name="p1">
                        <option value="No">{ongP1.p1}</option>
                    </select>
                    </label>
                {ongP1.p1 === 'No' ? (
                    <span className="text-principal-2">No se subio ningún archivo</span>
                    ) : 
                    <div>
                        <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalOne}>Consultar</button>

                        {showModal ? (
                            <ModalPrompt
                            setShowModal={setShowModal}
                            title={ongP1.p1}
                            content={
                                
                                <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p1} height="100%" width="100%"></iframe>
                                
                            }
                            />
                            ) : null}
                    </div>               
                }
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. ¿Se encontraba debidamente firmado el convenio de cooperacion por ambas partes?</span><br/>
                    <select disabled name="p2">
                        <option value="No">{ongP1.p2}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. ¿Se encontraba debidamente especificado en mombre contraactual del proyecto en mencion?</span><br/>
                    <select disabled name="p3">
                        <option value="No">{ongP1.p3}</option>
                    </select>
                  <br/>
                  <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. ¿Existen todas las clausulas generales relevantes del proyecto, ejemplo; Fecha de inicio y duracion? ¿Nombre completo del proyecto?</span><br/>
                    <select disabled name="p4">
                        <option value="No">{ongP1.p4}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. ¿Esta implementado el presente convenio en las fechas establecidas en la carta de acuerdo del proyecto?</span><br/>
                    <textarea disabled value={ongP1.p5} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. ¿La organización contiene una cuenta bancaria separada independiente para el uso de los fondos del proyecto?</span><br/>
                    <select disabled name="p6">
                        <option value="No">{ongP1.p6}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. ¿Existe un presupuesto establecido en el convenio de cooperacion entre la organización y la Agencia Donante?</span><br/>
                    <select disabled name="p7">
                        <option value="No">{ongP1.p7}</option>
                    </select>
                    </label>
                {ongP1.p7 === 'No' ? (
                    <span className="text-principal-2">No se subio ningún archivo</span>
                    ) : 
                    <div>
                        <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalTwo}>Consultar</button>

                        {showModal2 ? (
                            <ModalPrompt
                            setShowModal={setShowModal2}
                            title={ongP1.p7}
                            content={
                                
                                <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p7} height="100%" width="100%"></iframe>
                                
                            }
                            />
                            ) : null}
                    </div>               
                }
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. ¿La organización cumplio las actividades del proyecto en el presupuesto?</span><br/>
                    <textarea disabled name="p8" value={ongP1.p8} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #8..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. Situación financiera y principales fuentes de financiamiento.</span><br/>
                    <textarea disabled value={ongP1.p9} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. ¿Existe alguna solicitud a la Agencia Donantes sobre el sobregiro de la linea presupuestaria?</span><br/>
                    <select disabled name="p10">
                        <option value="No">{ongP1.p10}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. ¿Se encuentra esa solicitud aprobada por la Agencia Donante, o existe algun correo electronico afirmando la autorizacion?</span><br/>
                    <select disabled name="p11">
                        <option value="No">{ongP1.p11}</option>
                    </select>
                    </label>
                {ongP1.p11 === 'No' ? (
                    <span className="text-principal-2">No se subio ningún archivo</span>
                    ) : 
                    <div>
                        <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalThree}>Consultar</button>

                        {showModal3 ? (
                            <ModalPrompt
                            setShowModal={setShowModal3}
                            title={ongP1.p11}
                            content={
                                
                                <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p11} height="100%" width="100%"></iframe>
                                
                            }
                            />
                            ) : null}
                    </div>               
                }
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. ¿Existe algun Adendum o ampliacion al proyecto? Si es No, pasar a la pregunta #15</span><br/>
                    <select disabled name="p12">
                        <option value="No">{ongP1.p12}</option>
                    </select>
                    </label>
                {ongP1.p12 === 'No' ? (
                    <span className="text-principal-2">No se subio ningún archivo</span>
                    ) : 
                    <div>
                        <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalFour}>Consultar</button>

                        {showModal4 ? (
                            <ModalPrompt
                            setShowModal={setShowModal4}
                            title={ongP1.p12}
                            content={
                                
                                <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p12} height="100%" width="100%"></iframe>
                                
                            }
                            />
                            ) : null}
                    </div>               
                }
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-1">Si su respuesta fue 'No' en la pregunta 12, poner 'No' como respuesta de esta pregunta.</span><br/>
                    <span className="text-principal-2">13. Peculiaridades y políticas del sistema de contabilidad.</span><br/>
                    <select disabled name="p13">
                        <option value="No">{ongP1.p13}</option>
                    </select>
                    </label>
                {ongP1.p13 === 'No' ? (
                    <span className="text-principal-2">No se subio ningún archivo</span>
                    ) : 
                    <div>
                        <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalFive}>Consultar</button>

                        {showModal5 ? (
                            <ModalPrompt
                            setShowModal={setShowModal5}
                            title={ongP1.p13}
                            content={
                                
                                <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p13} height="100%" width="100%"></iframe>
                                
                            }
                            />
                            ) : null}
                    </div>               
                }
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-1">Si su respuesta fue 'No' en la pregunta 12, poner 'No' como respuesta de esta pregunta.</span><br/>
                    <span className="text-principal-2">14. Opinión sobre el prestigio e imagen de la organización ante él publico.</span><br/>
                    <select disabled name="p14">
                        <option value="No">{ongP1.p14}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">15. ¿La Organización Ejecutadora Participa en abuso de fondos y/o acciones fraudulentas?</span><br/>
                    <select disabled name="p15">
                        <option value="No">{ongP1.p15}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">16. ¿La Organizción Ejecutora participa en practicas corruptas?</span><br/>
                    <select disabled name="p16">
                        <option value="No">{ongP1.p16}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">17. ¿La Organización Ejecutora participa en trabajo infantil conforme a las definiciones de la organización internacional de trabajo?</span><br/>
                    <select disabled name="p17">
                        <option value="No">{ongP1.p17}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">18. ¿La Organización Ejecutora viola las leyes laborables aplicables?</span><br/>
                    <select disabled name="p18">
                        <option value="No">{ongP1.p18}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">19. ¿La Organización Ejecutora viola las leyes medioambientables aplicables?</span><br/>
                    <select disabled name="p19">
                        <option value="No">{ongP1.p19}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">20. ¿La Organización Ejecutora tolera la discriminación o el acoso sexual e su organización?</span><br/>
                    <select disabled name="p20">
                        <option value="No">{ongP1.p20}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">21. ¿La Organización Ejecutora ya no se encuentra dispuesta o capacitada para llevar a cabo la ejecucion del contrato conforme a la solicitud?</span><br/>
                    <select disabled name="p21">
                        <option value="No">{ongP1.p21}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">22. ¿La Organización Ejecutora no ha presentado los informes mandatarios en un periodo de 8 meses?</span><br/>
                    <select disabled name="p22">
                        <option value="No">{ongP1.p22}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">23. ¿La Organización Ejecutora trasfiere la la ejecucion de actividades a otra agencia sin el previo consentimiento de la Agencia de cooperación?</span><br/>
                    <select disabled name="p23">
                        <option value="No">{ongP1.p23}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={next}
              >Siguiente</button>
        <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={back}
              >Atras</button>
        </div>
      </div>
    </div>
)}