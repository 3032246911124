import ONGP1 from "../../interfaces/p10";
import { useContext } from "react"
import { AuthContext } from "../../context/auth";
import { useNavigate } from 'react-router-dom';

interface UserRowProps{
    ongP1: ONGP1
}

export default function UserAdmin({
    ongP1
}:UserRowProps) 
{

    const navigate = useNavigate();

    function next()
    {
        navigate('/dashboard/previewp11')
    }

    function back()
    {
        navigate('/dashboard/previewp9')
    }

    const userContext = useContext(AuthContext);
    function sendOne()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        fetch(`${userContext?.api_url}/ong/listTenthONG`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: localStorage.getItem('id')
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    console.log(resp);
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

return(
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            LIQUIDACIONES POR COBRAR
        </h1>
        <form onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. ¿Los pagos por estos conceptos se encuentran autorizados por funcionario responsable y en casos importantes por el consejo de administración?</span><br/>
                    <textarea disabled value={ongP1.p1} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. ¿Se lleva una adecuada custodia física de los bienes cargados a pagos anticipados?</span><br/>
                    <select disabled name="p2">
                        <option value="No">{ongP1.p2}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. ¿Existen políticas definidas de limite y conceptos que deban ser tratados como pagos anticipados?</span><br/>
                    <select disabled name="p3">
                        <option value="No">{ongP1.p3}</option>
                    </select>
                  <br/>
                  <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. ¿Existe información adecuada en registros que permita identificar el origen de los pagos y las bases para su aplicación a resultados?</span><br/>
                    <select disabled name="p4">
                        <option value="No">{ongP1.p4}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. ¿Se llevan a cabo revisiones periódicas de la vigencia de los servicios por recibir para efecto de su aplicación a ejercicios futuros y para su control?</span><br/>
                    <select disabled name="p5">
                        <option value="No">{ongP1.p5}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. ¿Contiene una solicitud de Fondos para cada cheque efectuado por este concepto?</span><br/>
                    <select disabled name="p6">
                        <option value="No">{ongP1.p6}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. ¿Estan las pagos anticipados liquidados en el tiempo estipulado de acuerdo al manual de procedimientos contables?</span><br/>
                    <select disabled name="p7">
                        <option value="No">{ongP1.p7}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. ¿Existen congruencias por la actividad desarrollada en cada liquidacion del gasto?</span><br/>
                    <select disabled name="p8">
                        <option value="No">{ongP1.p8}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={next}
              >Siguiente</button>
        <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={back}
              >Atras</button>
        </form>
      </div>
    </div>
)}