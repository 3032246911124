import p2 from '../../interfaces/p2';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/auth';
import { useNavigate } from 'react-router-dom';

const FormExample6 = () => 
{

    const userContext = useContext(AuthContext);
    const navigate = useNavigate();

    const [selected5, setSelected5] = useState()

    const handleChange5 = (event:any) => 
    {
        setSelected5(event.target.value)
        
    }

    function sendOne(event: React.FormEvent<HTMLFormElement>)
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        event.preventDefault();

        let getP1: p2 = {
            fk_id_usuario: userContext?.currentUser?.id,
            p1: event.currentTarget.p1.value,
            p2: event.currentTarget.p2.value,
            p3: event.currentTarget.p3.value,
            p4: event.currentTarget.p4.value,
            p5: event.currentTarget.p5.value == 'No' ? (event.currentTarget.p5.value) : (event.currentTarget.pp5.value),
            p6: event.currentTarget.p6.value,
            p7: event.currentTarget.p7.value,
            p8: event.currentTarget.p8.value,
            p9: event.currentTarget.p9.value,
            p10: event.currentTarget.p10.value,
            p11: event.currentTarget.p11.value,
            p12: event.currentTarget.p12.value,
            p13: event.currentTarget.p13.value,
            p14: event.currentTarget.p14.value,
            p15: event.currentTarget.p15.value,
            p16: event.currentTarget.p16.value
		}

        fetch(`${userContext?.api_url}/ong/secondQuestionary`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: getP1.fk_id_usuario,
                p1: getP1.p1,
                p2: getP1.p2,
                p3: getP1.p3,
                p4: getP1.p4,
                p5: getP1.p5,
                p6: getP1.p6,
                p7: getP1.p7,
                p8: getP1.p8,
                p9: getP1.p9,
                p10: getP1.p10,
                p11: getP1.p11,
                p12: getP1.p12,
                p13: getP1.p13,
                p14: getP1.p14,
                p15: getP1.p15,
                p16: getP1.p16
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    navigate('/ong/p3')
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

    function verifyStatus()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

            fetch(`${userContext?.api_url}/user/getFinished`, 
            {
                method: 'POST',
                headers: 
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    id: userContext?.currentUser?.id
                })
            }).then(resp => 
            {
                if (resp.status >= 400) 
                {
                    console.log(resp);
                } 
                else 
                {
                    resp.json().then(jsonResponse =>
                    {
                        let finished = jsonResponse; 
                        finished.map(()=>
                        {
                            if(finished[0].finishedfourteen === true)
                            {
                                navigate('/')
                            }
                            else if(finished[0].finishedtwo === true)
                            {
                                navigate('/ong/p3')
                            }
                        })
                    })
                }
            }).catch((err) => 
            {
                console.log(err);
            })        
    }

    useEffect(() => {
        verifyStatus();
        window.scrollTo(0, 0);
      });
    
  
  return (
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            SISTEMA DE GOBIERNO
        </h1>
        <form onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. Desde que se inicio la personería jurídica, cuantas veces han hecho cambio de la Junta Directiva?</span>
                    <textarea required maxLength={300} name="p1" id='text-area1' className="w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring"  placeholder="Pregunta #1..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. Cada cuanto se reúne la Junta Directiva y normalmente como esta compuesta la agenda de reunión?</span>
                    <textarea required name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. Existe en la actual junta directiva alguna comisión de seguimiento y evaluación de las actividades en relación a los proyectos en ejecución?</span><br/>
                    <select required name="p3">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                  <br/>
                  <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. En que fecha se están realizando las Asambleas Generales?</span>
                    <input type="date" name ='p4' required/>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. Existe algún proceso de evaluación de cada miembro de la Junta Directiva?</span><br/>
                    <select required name="p5" value={selected5} onChange={handleChange5}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected5 === 'Si' && 
                        <div>
                            <textarea required name="pp5" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #5..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. Revisan en cada reunión de junta directiva los informes de actividades e informes financieros?</span><br></br>
                    <select required name="p6">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. Quien o quienes manejan las entregas de donaciones a los niños y niñas de las comunidades?</span><br/>
                    <textarea required name="p7" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #7..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. Existe un Plan Operativo Anual (POA) que es monitoreado por la Junta Directiva?</span><br/>
                    <select required name="p8">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. Existe algún proceso de conocimiento de las necesidades de cada comunidad?  ¿En caso de existir problemas entre los niños y niñas en las comunidades toda la Junta Directiva lleva y conoce el caso o solo el responsable de la comunidad?</span><br/>
                    <textarea required name="p9" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #9..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. Existe algún miembro de la Junta Directiva que recibe algún salario o remuneración?</span><br/>
                    <select required name="p10">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Preguntas al Secretario(a):</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. Lleva al día el libro de actas de la Junta Directiva?</span><br/>
                    <select required name="p11">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. Lleva un registro de cada reunión de Junta Directiva la asistencia y opiniones de relevancia para la organización?</span><br/>
                    <select required name="p12">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. Informa mensualmente a la Junta Directiva las decisiones de relevancia a cada miembro de la Junta?</span><br/>
                    <select required name="p13">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Preguntas al Fiscal:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. Tiene acceso a los cheques y documentos contables?</span><br/>
                    <select required name="p14">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">15. Cada cuanto revisa de forma muestral los pagos mas relevantes de la organización?</span>
                    <textarea required name="p15" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #15..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Preguntas a toda JD:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">16. Existe una comisión que supervise a todos los Comités de Apoyo?</span><br/>
                    <textarea required name="p16" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #16..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
              >Guardar y Avanzar</button>
        </form>
      </div>
    </div>
  );
};

export default FormExample6;