import ONGP1 from "../../interfaces/finishedProgress";
import { useEffect, useState, useContext } from "react"
import ONGAnsweredOne from "./ongProgress";
import { AuthContext } from "../../context/auth";

export default function UserRow(){
    
    const [ONGP1List, setONGP1List] = useState<ONGP1[]>([]);
    const userContext = useContext(AuthContext);
    const [showResult, setResult] = useState<boolean>(false);

    function renderONGP1() {
        return ONGP1List.map((currentDash: ONGP1) => <ONGAnsweredOne ongP1={currentDash}/>);
    }

    function sendOne()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        fetch(`${userContext?.api_url}/user/getFinishedONGAll`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            }
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    if(jsonResponse.info.length === 0)
                    {
                        setResult(false);
                    }
                    else
                    {
                        setONGP1List(jsonResponse.info)
                        setResult(true);
                    }
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

    useEffect(() => {
		sendOne();
	}, [])

    return(
    <div className="justify-center items-center content-center flex overflow-x-hidden overflow-y-hidden inset-0 z-50 outline-none focus:outline-none bg-principal-3">
        {showResult ? (
                renderONGP1()
			    ) :     <div className="flex w-full text-3xl font-semibold text-principal-2 items-center justify-center h-screen">
                <h1>Aún no se ha llenado ningún cuestionario.</h1>
            </div>}
    </div>
    )
}