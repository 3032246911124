import ONGP1 from "../../interfaces/p3m";
import { useContext } from "react"
import { AuthContext } from "../../context/auth";
import { useNavigate } from 'react-router-dom';

interface UserRowProps{
    ongP1: ONGP1
}

export default function UserAdmin({
    ongP1
}:UserRowProps) 
{

    const navigate = useNavigate();

    function next()
    {
        navigate('/dashboard/previewp4c')
    }

    function back()
    {
        navigate('/dashboard/previewp2c')
    }

    const userContext = useContext(AuthContext);
    function sendOne()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        fetch(`${userContext?.api_url}/company/listThreeComp`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: localStorage.getItem('id')
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    console.log(resp);
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

return(
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            EFECTIVO Y EQUIVALENTES
        </h1>
        <form onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. ¿Cuantas cuentas bancarias existen abiertas?</span><br/>
                    <textarea disabled maxLength={300} value={ongP1.p1} name="p1" id='text-area1' className="w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring"  placeholder="Pregunta #1..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. ¿Cuál es el objeto de cada una de ellas?</span><br/>
                    <textarea disabled maxLength={300} value={ongP1.p2} name="p2" id='text-area1' className="w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring"  placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. ¿Todas las cuentas bancarias son utilizadas por la empresa?</span><br/>
                    <select disabled name="p3">
                        <option value="No">{ongP1.p3}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. ¿Está centralizada la responsabilidad por las entradas y los depósitos de efectivo en el menor número posible de personas?</span><br/>
                    <select disabled name="p4">
                        <option value="No">{ongP1.p4}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Están todos los empleados que participan en las entradas, los pagos y que manejan efectivo y valores:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. ¿Adecuadamente afianzados?</span><br/>
                    <select disabled name="p5">
                        <option value="No">{ongP1.p5}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. ¿Obligados a tomar vacaciones anuales?</span><br/>
                    <select disabled name="p6">
                        <option value="No">{ongP1.p6}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Los empleados que manejan efectivo están desligados de algunos de los deberes siguientes:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. ¿Preparan recibos de ingresos o llevan registros de ingresos?</span><br/>
                    <select disabled name="p7">
                        <option value="No">{ongP1.p7}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. ¿Manejan o tiene acceso a los libros auxiliares?</span><br/>
                    <select disabled name="p8">
                        <option value="No">{ongP1.p8}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. ¿Manejan el libro mayor o el registro de la póliza?</span><br/>
                    <select disabled name="p9">
                        <option value="No">{ongP1.p9}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. ¿Preparan o aprueban los comprobantes de pagos?</span><br/>
                    <select disabled name="p10">
                        <option value="No">{ongP1.p10}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. ¿Preparan, firman o entregan los cheques de pagos?</span><br/>
                    <select disabled name="p11">
                        <option value="No">{ongP1.p11}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">INGRESOS DE CAJA:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. ¿Cuales son las principales fuentes de ingresos?</span><br/>
                    <textarea disabled maxLength={300} value={ongP1.p12} name="p12" id='text-area1' className="w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #12..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. ¿Dónde y como se reciben los ingresos?</span><br/>
                    <textarea disabled maxLength={300} value={ongP1.p13} name="p13" id='text-area1' className="w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring"  placeholder="Pregunta #13..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. Las entradas de dinero a caja por cantidades relativamente grandes, ¿son en cheques o giros y no en efectivo?</span><br/>
                    <textarea disabled maxLength={300} value={ongP1.p14} name="p14" id='text-area1' className="w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring"  placeholder="Pregunta #14..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">15. ¿Que clase de registro se lleva para las entradas de caja y quien lo lleva?</span><br/>
                    <textarea disabled maxLength={300} value={ongP1.p15} name="p15" id='text-area1' className="w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring"  placeholder="Pregunta #15..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">16. ¿Se depositan correctamentos los ingresos que corresponden a reintegros o devoluciones intactos y sin ningún retraso?</span><br/>
                    <select disabled name="p16">
                        <option value="No">{ongP1.p16}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">17. ¿Mantienen actualizado el control de los ingresos de caja hasta que se depositan en los bancos?</span><br/>
                    <select disabled name="p17">
                        <option value="No">{ongP1.p17}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">18. ¿Alguna persona independiente, efectúa periódicamente un examen por sorpresa de caja chica?</span><br/>
                    <select disabled name="p18">
                        <option value="No">{ongP1.p18}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">19. ¿Se entregan directamente a un empleado independiente del cajero las partidas de depósito o cobro devueltas por el banco? (debido a la insuficiencia de fondos, etc.)</span><br/>
                    <select disabled name="p19">
                        <option value="No">{ongP1.p19}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">20. ¿Se encuentra prohibido cambiar cheques por ingresos recibidos en efectivo y se respeta esa prohibición?</span><br/>
                    <select disabled name="p20">
                        <option value="No">{ongP1.p20}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">21. ¿Se han dado instrucciones escritas a los bancos para que no hagan efectivo ningún cheque a nombre de la empresa?</span><br/>
                    <select disabled name="p21">
                        <option value="No">{ongP1.p21}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">PAGOS:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">22. ¿Que clase de registro de pagos se lleva y quien lo lleva?</span><br/>
                    <textarea disabled name="p22" maxLength={300} value={ongP1.p22} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #22..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">23. ¿Se hacen por medio de cheques todos los pagos, salvo los de caja chica?</span><br/>
                    <select disabled name="p23">
                        <option value="No">{ongP1.p23}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">24. ¿Se emplea una maquina protectora de cheques?</span><br/>
                    <select disabled name="p24">
                        <option value="No">{ongP1.p24}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">25. ¿Se mutilan o cancelan los cheques anulados para impedir que vuelvan a usarse y se adhieren a la póliza con el sello de cancelado?</span><br/>
                    <select disabled name="p25">
                        <option value="No">{ongP1.p25}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">En lo que respecta a los talonarios de cheques en blanco:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">26. ¿Se tienen en un lugar seguro?</span><br/>
                    <select disabled name="p26">
                        <option value="No">{ongP1.p26}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">27. ¿Son accesibles solamente a las personas autorizadas para preparar cheques y giros?</span><br/>
                    <select disabled name="p27">
                        <option value="No">{ongP1.p27}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">28. ¿Se prepara un Voucher de cheque o se hace un registro del cheque simultáneamente a su preparación?</span><br/>
                    <select disabled name="p28">
                        <option value="No">{ongP1.p28}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">29. ¿Se hacen los pagos solamente basándose en comprobantes aprobados con la documentación original de soporte adjunta?</span><br/>
                    <select disabled name="p29">
                        <option value="No">{ongP1.p29}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">30. ¿Se cancela con el sello de pagado los documentos correspondientes para impedir el pago duplicado?  En caso afirmativo Como, cuando y quién lo sella?</span><br/>
                    <textarea disabled maxLength={300} value={ongP1.p30} name="p15" id='text-area1' className="w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring"  placeholder="Pregunta #15..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">31. ¿Quiénes son las personas autorizadas para firmar los cheques? (Hágase una lista de los nombres  y de los puestos que ocupan).</span><br/>
                    <textarea disabled name="p31" maxLength={300} value={ongP1.p31} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #31..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">32. ¿Necesitan dos firmas todos los cheques que representen desembolsos de fondos en general? En caso negativo, ¿por qué se considera esto innecesario?</span><br/>
                    <textarea disabled maxLength={300} value={ongP1.p32} name="p15" id='text-area1' className="w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring"  placeholder="Pregunta #15..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">33. ¿Existen limitaciones a los importes de los cheques firmados por una sola persona?</span><br/>
                    <select disabled name="p33">
                        <option value="No">{ongP1.p33}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">34. ¿Las personas autorizadas tienen prohibido firmar cheques en blanco y  respetan esa prohibición?</span><br/>
                    <select disabled name="p34">
                        <option value="No">{ongP1.p34}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Las personas autorizadas para firmar cheques están desligadas de los deberes siguientes:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">35. ¿Prepara la conciliación de las cuentas de los bancos?</span><br/>
                    <select disabled name="p35">
                        <option value="No">{ongP1.p35}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">36. ¿Prepara o revisa los comprobantes?</span><br/>
                    <select disabled name="p36">
                        <option value="No">{ongP1.p36}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">37. ¿Tiene bajo su custodia la caja chica u otros fondos especiales?</span><br/>
                    <select disabled name="p37">
                        <option value="No">{ongP1.p37}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>
            
            <span className="text-principal-2">CAJA CHICA:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">38. ¿Se hacen los pagos menores por medio de fondo fijo reembolsable contra entrega de comprobantes?</span><br/>
                    <select disabled name="p38">
                        <option value="No">{ongP1.p38}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">39. ¿Esta prohibido mezclar los ingresos de caja con el fondo de caja chica?</span><br/>
                    <select disabled name="p39">
                        <option value="No">{ongP1.p39}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">40. ¿Recae en una sola persona la responsabilidad por cada fondo?</span><br/>
                    <select disabled name="p40">
                        <option value="No">{ongP1.p40}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">41. ¿Están los pagos de caja chica soportados por documentos debidamente aprobados?</span><br/>
                    <select disabled name="p41">
                        <option value="No">{ongP1.p41}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">42. ¿Se comprueban en el momento del reembolso los documentos que sirven de apoyo a los pagos de la caja chica?</span><br/>
                    <select disabled name="p42">
                        <option value="No">{ongP1.p42}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">43. ¿Cuándo se reponen los fondos de la caja chica, (se cancelan con el sello de "PAGADO" y la fecha) los comprobantes para evitar el pago duplicado?</span><br/>
                    <select disabled name="p43">
                        <option value="No">{ongP1.p43}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">44. ¿No se expiden los cheques para reponer el fondo de caja chica a la orden de la persona que tiene a su cargo la custodia de la misma.</span><br/>
                    <select disabled name="p44">
                        <option value="No">{ongP1.p44}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={next}
              >Siguiente</button>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={back}
              >Atras</button>
        </form>
      </div>
    </div>
)}