import ONGP1 from "../../interfaces/p1m";
import { useContext } from "react"
import { AuthContext } from "../../context/auth";
import { useNavigate } from 'react-router-dom';

interface UserRowProps{
    ongP1: ONGP1
}

export default function UserAdmin({
    ongP1
}:UserRowProps) 
{

    const navigate = useNavigate();

    function next()
    {
        navigate('/dashboard/previewp2c')
    }

    function back()
    {
        navigate('/dashboard/previewp0c')
    }

    const userContext = useContext(AuthContext);
    function sendOne()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        fetch(`${userContext?.api_url}/company/listOneComp`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: localStorage.getItem('id')
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    console.log(resp);
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

return(
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            ACTIVO FIJO
        </h1>
        <form onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. ¿La dirección de la empresa autoriza el metodo y políticas de valuación, incluyendo las relativas a capitalización, actualización, depreciación?</span><br/>
                    <select disabled name="p1">
                        <option value="No">{ongP1.p1}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. ¿Son tomados periódicamente inventarios fisicos de los activos de la empresa?</span><br/>
                    <select disabled name="p2">
                        <option value="No">{ongP1.p2}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. ¿Existe una política que defina lo que debe considerarse como partidas capitalizadas y que como reparación y mantenimiento? (En caso afirmativo) ¿Cuál es?</span><br/>
                    <textarea disabled maxLength={300} value={ongP1.p3} name="pp3" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="¿Cúal Es?..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. ¿Existe una autorización formal para efectuar una inversión, venta o retiro, de activos fijos?</span><br/>
                    <select disabled name="p4">
                        <option value="No">{ongP1.p4}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. ¿Se tienen procedimientos claros para comprobar que se hayan recibio y registrado adecuadamente los bienes adquiridos?</span><br/>
                    <select disabled name="p5">
                        <option value="No">{ongP1.p5}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. ¿Se lleva un expediente que contenga la documentación soporte de la propiedad de los activos?</span><br/>
                    <select disabled name="p6">
                        <option value="No">{ongP1.p6}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. ¿Existe una segregación adecuada de las funciones de adquisición, custodia y registro de los activos fijos?</span><br/>
                    <select disabled name="p7">
                        <option value="No">{ongP1.p7}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. ¿Se revisa periodicamente las construcciones e instalaciones en proceso para registrar oportunamente aquellas que hayan sido terminadas?</span><br/>
                    <select disabled name="p8">
                        <option value="No">{ongP1.p8}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. ¿La empresa tiene establecido un registro adecuado para el control de anticipo a proveedores o constructores?</span><br/>
                    <select disabled name="p9">
                        <option value="No">{ongP1.p9}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. ¿Los activos fijos se registran en grupos homogéneos, en atención a su naturaleza y tasa de depreciación?</span><br/>
                    <select disabled name="p10">
                        <option value="No">{ongP1.p10}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. ¿Se valúan periódicamente para efecto de determinar la cobertura de seguros?</span><br/>
                    <select disabled name="p11">
                        <option value="No">{ongP1.p11}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. ¿Se mantiene un control de partidas chatarra?</span><br/>
                    <select disabled name="p12">
                        <option value="No">{ongP1.p12}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. ¿Son marcadas de alguna manera las piezas del equipo para efecto de identificación?</span><br/>
                    <select disabled name="p13">
                        <option value="No">{ongP1.p13}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. ¿Existen dentro del almacén de herramientas procedimientos adecuados para controlarlas físicamente?</span><br/>
                    <select disabled name="p14">
                        <option value="No">{ongP1.p14}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={next}
              >Siguiente</button>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={back}
              >Atras</button>
        </form>
      </div>
    </div>
)}