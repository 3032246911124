import p13 from '../../interfaces/p13';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/auth';
import { useNavigate } from 'react-router-dom';

const FormExample6 = () => 
{

    const userContext = useContext(AuthContext);
    const navigate = useNavigate();

    const [selected1, setSelected1] = useState();
    const [selected5, setSelected5] = useState();
    const [selected6, setSelected6] = useState();
    const [selected7, setSelected7] = useState();
    const [selected48, setSelected48] = useState();
    const [selected51, setSelected51] = useState();

    const handleChange1 = (event:any) => 
    {
        setSelected1(event.target.value)
        
    }

    const handleChange5 = (event:any) => 
    {
        setSelected5(event.target.value)
        
    }
    
    const handleChange6 = (event:any) => 
    {
        setSelected6(event.target.value)
        
    }

    const handleChange7 = (event:any) => 
    {
        setSelected7(event.target.value)
        
    }

    const handleChange48 = (event:any) => 
    {
        setSelected48(event.target.value)
        
    }

    const handleChange51 = (event:any) => 
    {
        setSelected51(event.target.value)
        
    }

    function sendOne(event: React.FormEvent<HTMLFormElement>)
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        event.preventDefault();

        let getP1: p13 = {
            fk_id_usuario: userContext?.currentUser?.id,
            p1: event.currentTarget.p1.value,
            p2: event.currentTarget.p2.value,
            p3: event.currentTarget.p3.value,
            p4: event.currentTarget.p4.value,
            p5: event.currentTarget.p5.value,
            p6: event.currentTarget.p6.value,
            p7: event.currentTarget.p7.value,
            p8: event.currentTarget.p8.value,
            p9: event.currentTarget.p9.value,
            p10: event.currentTarget.p10.value,
            p11: event.currentTarget.p11.value,
            p12: event.currentTarget.p12.value,
            p13: event.currentTarget.p13.value,
            p14: event.currentTarget.p14.value == 'No' ? (event.currentTarget.p14.value) : (event.currentTarget.pp14.value),
            p15: event.currentTarget.p15.value,
            p16: event.currentTarget.p16.value,
            p17: event.currentTarget.p17.value,
            p18: event.currentTarget.p18.value,
            p19: event.currentTarget.p19.value,
            p20: event.currentTarget.p20.value,
            p21: event.currentTarget.p21.value,
            p22: event.currentTarget.p22.value == 'No' ? (event.currentTarget.p22.value) : (event.currentTarget.pp22.value),
            p23: event.currentTarget.p23.value,
            p24: event.currentTarget.p24.value,
            p25: event.currentTarget.p25.value,
            p26: event.currentTarget.p26.value,
            p27: event.currentTarget.p27.value,
            p28: event.currentTarget.p28.value,
            p29: event.currentTarget.p29.value,
            p30: event.currentTarget.p30.value,
            p31: event.currentTarget.p31.value,
            p32: event.currentTarget.p32.value,
            p33: event.currentTarget.p33.value,
            p34: event.currentTarget.p34.value,
            p35: event.currentTarget.p35.value == 'No' ? (event.currentTarget.p35.value) : (event.currentTarget.pp35.value),
            p36: event.currentTarget.p36.value,
            p37: event.currentTarget.p37.value,
            p38: event.currentTarget.p38.value,
            p39: event.currentTarget.p39.value,
            p40: event.currentTarget.p40.value,
            p41: event.currentTarget.p41.value,
            p42: event.currentTarget.p42.value == 'No' ? (event.currentTarget.p42.value) : (event.currentTarget.pp42.value),
            p43: event.currentTarget.p43.value,
            p44: event.currentTarget.p44.value,
            p45: event.currentTarget.p45.value,
            p46: event.currentTarget.p46.value,
            p47: event.currentTarget.p47.value,
            p48: event.currentTarget.p48.value == 'No' ? (event.currentTarget.p48.value) : (event.currentTarget.pp48.value),
            p49: event.currentTarget.p49.value,
            p50: event.currentTarget.p50.value,
            p51: event.currentTarget.p51.value == 'No' ? (event.currentTarget.p51.value) : (event.currentTarget.pp51.value),
            p52: event.currentTarget.p52.value,
            p53: event.currentTarget.p53.value,
            p54: event.currentTarget.p54.value,
            p55: event.currentTarget.p55.value,
            p56: event.currentTarget.p56.value,
            p57: event.currentTarget.p57.value,
            p58: event.currentTarget.p58.value,
            p59: event.currentTarget.p59.value,
            p60: event.currentTarget.p60.value,
            p61: event.currentTarget.p61.value,
            p62: event.currentTarget.p62.value,
            p63: event.currentTarget.p63.value,
            p64: event.currentTarget.p64.value,
            p65: event.currentTarget.p65.value,
            p66: event.currentTarget.p66.value,
            p67: event.currentTarget.p67.value
		}

        fetch(`${userContext?.api_url}/ong/thirteenQuestionary`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: getP1.fk_id_usuario,
                p1: getP1.p1,
                p2: getP1.p2,
                p3: getP1.p3,
                p4: getP1.p4,
                p5: getP1.p5,
                p6: getP1.p6,
                p7: getP1.p7,
                p8: getP1.p8,
                p9: getP1.p9,
                p10: getP1.p10,
                p11: getP1.p11,
                p12: getP1.p12,
                p13: getP1.p13,
                p14: getP1.p14,
                p15: getP1.p15,
                p16: getP1.p16,
                p17: getP1.p17,
                p18: getP1.p18,
                p19: getP1.p19,
                p20: getP1.p20,
                p21: getP1.p21,
                p22: getP1.p22,
                p23: getP1.p23,
                p24: getP1.p24,
                p25: getP1.p25,
                p26: getP1.p26,
                p27: getP1.p27,
                p28: getP1.p28,
                p29: getP1.p29,
                p30: getP1.p30,
                p31: getP1.p31,
                p32: getP1.p32,
                p33: getP1.p33,
                p34: getP1.p34,
                p35: getP1.p35,
                p36: getP1.p36,
                p37: getP1.p37,
                p38: getP1.p38,
                p39: getP1.p39,
                p40: getP1.p40,
                p41: getP1.p41,
                p42: getP1.p42,
                p43: getP1.p43,
                p44: getP1.p44,
                p45: getP1.p45,
                p46: getP1.p46,
                p47: getP1.p47,
                p48: getP1.p48,
                p49: getP1.p49,
                p50: getP1.p50,
                p51: getP1.p51,
                p52: getP1.p52,
                p53: getP1.p53,
                p54: getP1.p54,
                p55: getP1.p55,
                p56: getP1.p56,
                p57: getP1.p57,
                p58: getP1.p58,
                p59: getP1.p59,
                p60: getP1.p60,
                p61: getP1.p61,
                p62: getP1.p62,
                p63: getP1.p63,
                p64: getP1.p64,
                p65: getP1.p65,
                p66: getP1.p66,
                p67: getP1.p67
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    navigate('/ong/p14')
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

    function verifyStatus()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

            fetch(`${userContext?.api_url}/user/getFinished`, 
            {
                method: 'POST',
                headers: 
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    id: userContext?.currentUser?.id
                })
            }).then(resp => 
            {
                if (resp.status >= 400) 
                {
                    console.log(resp);
                } 
                else 
                {
                    resp.json().then(jsonResponse =>
                    {
                        let finished = jsonResponse; 
                        finished.map(()=>
                        {
                            if(finished[0].finishedfourteen === true)
                            {
                                navigate('/')
                            }
                            else if(finished[0].finishedthirteen === true)
                            {
                                navigate('/ong/p14')
                            }
                        })
                    })
                }
            }).catch((err) => 
            {
                console.log(err);
            })        
    }

    useEffect(() => {
        verifyStatus();
        window.scrollTo(0, 0);
      });
    
  
  return (
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            GESTIÓN ADMINISTRATIVA Y CONTABLE
        </h1>
        <form onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. ¿Poseen Manuales y Procedimientos Administrativos Contables?</span><br/>
                    <select name="p1">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. ¿Poseen Procesos de control interno y/o Tipos de control interno?</span><br/>
                    <select name="p2">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. ¿Poseen Manual de Planificación y preparación de Presupuestos?</span><br/>
                    <select name="p3">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                  <br/>
                  <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. ¿Contienen procedimientos de Gestión de Fondos y Acuerdos?</span><br/>
                    <select name="p4">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. ¿Se encuentran bien definidas las políticas, reglas y procedimientos de la organización?</span><br/>
                    <select required name="p5">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. ¿Poseen Reglamentos Internos de Trabajo e Higiene?</span><br/>
                    <select required name="p6">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. ¿Poseen un Manual Contable con el Plan de Cuentas General?</span><br/>
                    <select required name="p7">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. ¿La organización tiene el Cumplimiento de las Normas de Contabilidad Generalmente Aceptadas (PCGA's)?</span><br/>
                    <select required name="p8">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. ¿La organización contiene un eficiente control de efectivo y equivalentes?</span><br/>
                    <select required name="p9">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. ¿Manejan control de Activos Fijos? (Propiedad, Planta y Equipo)</span><br/>
                    <select required name="p10">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. ¿Manejan Control de Ingresos?</span><br/>
                    <select required name="p11">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. ¿Manejan Control de Compras y Adquisiciones?</span><br/>
                    <select name="p12">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. ¿Manejan Control de Cuentas por Cobrar y Adelantos a empleados?</span><br/>
                    <select name="p13">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. ¿Manejan Control de Liquidaciones por viáticos y gastos reembolsables?</span><br/>
                    <select required name="p14" value={selected1} onChange={handleChange1}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected1 === 'Si' && 
                        <div>
                            <textarea required name="pp14" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="¿Donde?..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">15. ¿Contienen Manual de Liquidaciones?</span><br/>
                    <select name="p15">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">16. ¿Contienen Control de Viáticos y Gastos de Viaje Nacional y Extranjero?</span><br/>
                    <select required name="p16">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">17. ¿Existen Manuales de Viáticos y Gastos de Viaje?</span><br/>
                    <select required name="p17">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">18. ¿Efectúan Estados Financieros como ser:(BG, ER, FC, EJEC. P. CP.)</span><br/>
                    <select required name="p18">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">19. ¿Contienen Control de Gastos y Egresos?</span><br/>
                    <select required name="p19">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">20. ¿Contienen Control de Compras de Alimentos y de cocina?</span><br/>
                    <select required name="p20">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">21. ¿Contiene un control adecuado por la compra del material didáctico y de oficina?</span><br/>
                    <select name="p21">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">22. ¿Contienen uso de Fondo de Caja Chica?</span><br/>
                    <select required name="p22" value={selected5} onChange={handleChange5}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected5 === 'Si' && 
                        <div>
                            <textarea required name="pp22" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Cuantas?..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">23. ¿Utilizan Auxiliar o control de Bancos en Moneda Nacional y Extranjera?</span><br/>
                    <select required name="p23">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">24. ¿Contienen Libros Legales? (Compras, Ingresos, Diario, Balanza, Mayor)</span><br/>
                    <select required name="p24">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">25. ¿Utilizan Ordenes de Compra?</span><br/>
                    <select required name="p25">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">26. ¿Contienen Ordenes de Requisiciones externas e internas?</span><br/>
                    <select required name="p26">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">27. ¿Contienen recibos o comprobantes de gastos enumerados?</span><br/>
                    <select required name="p27">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">28. ¿ Contienen recibos o comprobantes de ingresos enumerados?</span><br/>
                    <select required name="p28">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">29. ¿Contienen formato de Solicitud de Gastos y Egresos?</span><br/>
                    <select name="p29">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">30. ¿Contienen formato de Liquidación de Gastos?</span><br/>
                    <select name="p30">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">31. ¿Contienen formato de Solicitud de Viáticos y gastos de viaje?</span><br/>
                    <select name="p31">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">32. ¿Contienen formato de Liquidación de Viáticos y gastos de viaje?</span><br/>
                    <select required name="p32">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">33. ¿Contienen Manual de políticas de desembolsos y compras al por mayor?</span><br/>
                    <select required name="p33">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">34. ¿Contienen detalles y registros de los activos fijos en uso y existencia?</span><br/>
                    <select required name="p34">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">35. Mencione cual es el procedimiento que utiliza para controlar los activos fijos.</span><br/>
                    <select required name="p35" value={selected6} onChange={handleChange6}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected6 === 'Si' && 
                        <div>
                            <textarea required name="pp35" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Procedimiento?..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">36. ¿Contiene registros de cada activo en uso en expedientes separados?</span><br/>
                    <select required name="p36">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">37. ¿Esta seccionado por Responsable y cuenta contable los activos fijos?</span><br/>
                    <select required name="p37">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">38. ¿Posee comprobante de uso por responsable de los activos fijos de la organización?</span><br/>
                    <select required name="p38">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">39. ¿Están debidamente etiquetados los activos fijos de la organización?</span><br/>
                    <select required name="p39">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">40. ¿Se llevan registros de las bajas y altas de los inventarios del activo fijo?</span><br/>
                    <select required name="p40">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">41. ¿ Se encuentra los activos fijos de la organización asegurados bajo una póliza?</span><br/>
                    <select name="p41">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">42. ¿Están los pagos de caja chica soportados por documentos debidamente aprobados?</span><br/>
                    <select required name="p42" value={selected7} onChange={handleChange7}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected7 === 'Si' && 
                        <div>
                            <textarea required name="pp42" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">43. ¿Contiene y cumplen políticas y normas de los activos fijos?</span><br/>
                    <select required name="p43">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">44. ¿Existe control de inventarios de salidas y entradas de materiales y suministros?</span><br/>
                    <select required name="p44">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">45. ¿Contiene un formato de salidas y entradas por departamentos de los inventarios de materiales y suministros?</span><br/>
                    <select required name="p45">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">46. ¿Están reflejados en los Estados Financieros de la organización el costo razonable de los inventarios de materiales y suministros?</span><br/>
                    <select required name="p46">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">47. ¿Existe un expediente de cada empleado de la organización?</span><br/>
                    <select required name="p47">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>
            
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">48. ¿ Contiene un control de nóminas y pagos de salarios? ¿Que modalidad de pago?</span><br/>
                    <select required name="p48" value={selected48} onChange={handleChange48}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected48 === 'Si' && 
                        <div>
                            <textarea required name="pp48" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="¿Modalidad?..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">49. ¿Contiene un formato de pago por cada empleado?</span><br/>
                    <select required name="p49">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">50. ¿Cual es el tipo de modalidad de pago?</span><br/>
                    <textarea required name="p50" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #50..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">51. ¿Contiene un control de entrada y salidas de los empleados? ¿Cual?</span><br/>
                    <select required name="p51" value={selected51} onChange={handleChange51}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected51 === 'Si' && 
                        <div>
                            <textarea required name="pp51" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="¿Cual?..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">52. ¿Contiene control inmediato de las entradas y salidas de empleados en los centros y programas que están fuera de las oficinas principales?</span><br/>
                    <select required name="p52">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">53. ¿Existe algún mecanismo de reclamo o modificaciones en los salarios?</span><br/>
                    <select name="p53">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">54. ¿De que manera se le informa al empleado(a) la recepción de su salario?</span><br/>
                    <textarea required name="p54" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="¿Como?..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">55. ¿Contienen adelantos o anticipos de salarios?</span><br/>
                    <select name="p55">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">56. ¿Está la organización inscrita a Cooperativas de Ahorros y Créditos?</span><br/>
                    <select required name="p56">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">57. ¿Contiene un registro detallado de las deducciones por cada empleado(a)?</span><br/>
                    <select required name="p57">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">58. ¿Existen bonificaciones por resultados o gratificaciones a los empleados(as)?</span><br/>
                    <select required name="p58">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">59. ¿Las prestaciones sociales se pagan al momento del retiro del empleado(a)?</span><br/>
                    <select required name="p59">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">60. ¿Se pagan y calculan horas extras?</span><br/>
                    <select required name="p60">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">61. ¿ Se pagan y calculan depreciación por uso de automóvil?</span><br/>
                    <select required name="p61">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">62. ¿Contienen un Manual de Créditos y cuentas por cobrar?</span><br/>
                    <select required name="p62">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">63. ¿Existe un auxiliar de los Microcréditos y cuentas por cobrar?</span><br/>
                    <select required name="p63">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">64. ¿Contiene un formato de pago a Microcréditos o cuentas por cobrar?</span><br/>
                    <select required name="p64">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">65. ¿Existe un departamento de caja que recepcione los pagos de Microcréditos o las cuentas por cobrar?</span><br/>
                    <select required name="p65">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">66. ¿Que formato o control utiliza para la recuperación de créditos vencidos?</span><br/>
                    <textarea required name="p66" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="¿Formato?..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">67. ¿Se cuenta con informes mensuales de la cartera crediticia?</span><br/>
                    <select required name="p67">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
              >Guardar y Avanzar</button>
        </form>
      </div>
    </div>
  );
};

export default FormExample6;