import p9 from '../../interfaces/p9';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/auth';
import { useNavigate } from 'react-router-dom';

const FormExample6 = () => 
{

    const userContext = useContext(AuthContext);
    const navigate = useNavigate();

    const [selected1, setSelected1] = useState();
    const [selected5, setSelected5] = useState();

    const handleChange1 = (event:any) => 
    {
        setSelected1(event.target.value)
        
    }

    const handleChange5 = (event:any) => 
    {
        setSelected5(event.target.value)
        
    }

    function sendOne(event: React.FormEvent<HTMLFormElement>)
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        event.preventDefault();

        let getP1: p9 = {
            fk_id_usuario: userContext?.currentUser?.id,
            p1: event.currentTarget.p1.value,
            p2: event.currentTarget.p2.value,
            p3: event.currentTarget.p3.value,
            p4: event.currentTarget.p4.value,
            p5: event.currentTarget.p5.value,
            p6: event.currentTarget.p6.value,
            p7: event.currentTarget.p7.value,
            p8: event.currentTarget.p8.value,
            p9: event.currentTarget.p9.value,
            p10: event.currentTarget.p10.value,
            p11: event.currentTarget.p11.value,
            p12: event.currentTarget.p12.value == 'No' ? (event.currentTarget.p12.value) : (event.currentTarget.pp12.value),
            p13: event.currentTarget.p13.value,
            p14: event.currentTarget.p14.value == 'No' ? (event.currentTarget.p14.value) : (event.currentTarget.pp14.value),
            p15: event.currentTarget.p15.value,
            p16: event.currentTarget.p16.value,
            p17: event.currentTarget.p17.value,
            p18: event.currentTarget.p18.value,
            p19: event.currentTarget.p19.value,
            p20: event.currentTarget.p20.value,
            p21: event.currentTarget.p21.value,
            p22: event.currentTarget.p22.value,
            p23: event.currentTarget.p23.value
		}

        fetch(`${userContext?.api_url}/ong/ninethQuestionary`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: getP1.fk_id_usuario,
                p1: getP1.p1,
                p2: getP1.p2,
                p3: getP1.p3,
                p4: getP1.p4,
                p5: getP1.p5,
                p6: getP1.p6,
                p7: getP1.p7,
                p8: getP1.p8,
                p9: getP1.p9,
                p10: getP1.p10,
                p11: getP1.p11,
                p12: getP1.p12,
                p13: getP1.p13,
                p14: getP1.p14,
                p15: getP1.p15,
                p16: getP1.p16,
                p17: getP1.p17,
                p18: getP1.p18,
                p19: getP1.p19,
                p20: getP1.p20,
                p21: getP1.p21,
                p22: getP1.p22,
                p23: getP1.p23
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    navigate('/ong/p10')
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

    function verifyStatus()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

            fetch(`${userContext?.api_url}/user/getFinished`, 
            {
                method: 'POST',
                headers: 
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    id: userContext?.currentUser?.id
                })
            }).then(resp => 
            {
                if (resp.status >= 400) 
                {
                    console.log(resp);
                } 
                else 
                {
                    resp.json().then(jsonResponse =>
                    {
                        let finished = jsonResponse; 
                        finished.map(()=>
                        {
                            if(finished[0].finishedfourteen === true)
                            {
                                navigate('/')
                            }
                            else if(finished[0].finishednine === true)
                            {
                                navigate('/ong/p10')
                            }
                        })
                    })
                }
            }).catch((err) => 
            {
                console.log(err);
            })        
    }

    useEffect(() => {
        verifyStatus();
        window.scrollTo(0, 0);
      });
    
  
  return (
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            INGRESOS CORRIENTES
        </h1>
        <form onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. ¿Existen políticas de los ingresos y se encuentran documentadas y autorizadas por autoridades gerenciales?</span><br/>
                    <select required name="p1">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. ¿De acuerdo a la estructura funcional de la organización, existe una adecuada segregación de funciones y son independientes los empleados que manejan las coordinaciones de los proyectos?</span><br/>
                    <select required name="p2">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. ¿De acuerdo a la estructura funcional de la organización, existe una adecuada segregación de funciones y son independientes los empleados que manejan los fondos de caja?</span><br/>
                    <select required name="p3">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                  <br/>
                  <br/>
                </label>
            </div>

            <span className="text-principal-2">Se revisa la documentación y comprobantes en cuanto a:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. ¿De acuerdo a la estructura funcional de la organización, existe una adecuada segregación de funciones y son independientes los empleados que manejan los registros contables?</span><br/>
                    <select required name="p4">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. ¿Se concilian mensualmente los auxiliares de cuentas por cobrar con los registros contables?</span><br/>
                    <select required name="p5">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. ¿Se expiden recibos o comprobantes por ingresos, que reúnan los requisitos fiscales?</span><br/>
                    <select required name="p6">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. ¿Tratándose de ingresos por cooperacion o donaciónes de fondos, se expiden recibos por ingresos con el nuevo regimen de facturación?</span><br/>
                    <select required name="p7">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. ¿Se adjuntan comprobantes o certificaciones de las agencias cooperantes de las transferencias recibidas?</span><br/>
                    <select required name="p8">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. ¿Se notifica a la gerencia los ingresos provenientes para la ejecución del Proyecto?</span><br/>
                    <select required name="p9">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. ¿Existen ingresos no identificados en las transferencias bancarias o depositos realizados?</span><br/>
                    <select required name="p10">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. ¿Mantienen una información expedita de las agencias donantes sobre la recepción de los ingresos para ejecución del Proyecto?</span><br/>
                    <select required name="p11">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">En el caso de haber obtenido ingresos por enajenación de terrenos, construcciones, partes sociales, acciones nominativas colocadas entre público inversionistas se considero la opción de ajustar el monto original de la inversión en esos bienes:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. ¿Cuales son las principales fuentes de ingresos?</span><br/>
                    <select required name="p12" value={selected5} onChange={handleChange5}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected5 === 'Si' && 
                        <div>
                            <textarea required name="pp12" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. ¿Se realizo la documentacion legal y pertinente a la adquisición de bienes tangibles o intangibles?</span><br/>
                    <select name="p13">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. ¿Existe comprobantes de entrega y recepción de los ingresos por enajenacion o donación en especie?</span><br/>
                    <select required name="p14" value={selected1} onChange={handleChange1}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected1 === 'Si' && 
                        <div>
                            <textarea required name="pp14" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">15. ¿Se registra como ingresos las adquisiciones de bienes tangibles o intangibles?</span><br/>
                    <select name="p15">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>   
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">16. ¿Se registran mensualmente los ingresos correspondientes a intereses por cuentas bancarias?</span><br/>
                    <select name="p16">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">17. ¿Se registran o se lleva un control de los ingresos por depósitos a plazos fijos?</span><br/>
                    <select required name="p17">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">18. ¿Se documenta a traves de documentos bancarios o notas de crédito los intereses ganados?</span><br/>
                    <select required name="p18">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">19. ¿Se reporta en los auxiliares bancarios los interese generados en el mes?</span><br/>
                    <select required name="p19">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">20. ¿Que documento se emite al obtener ingresos derivados de prestación de servicios, tales como: Alquiler, Servicios Audiovisuales, etc?</span><br/>
                    <textarea required name="p20" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #20..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Pagos:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">21. ¿Existen politicas y reglas sobre la recepcion de ingresos no corrientes?</span><br/>
                    <select name="p21">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">22. ¿Los ingresos derivados por servicios prestados son realizados por transferencias bancarias o cheques emitidos a favor de la Organización?</span><br/>
                    <select required name="p22">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">23. ¿Esta prohibido recibir efectivo para la prestación de servicios o existe personal autorizado para la recepcion del mismo?</span><br/>
                    <select required name="p23">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
              >Guardar y Avanzar</button>
        </form>
      </div>
    </div>
  );
};

export default FormExample6;