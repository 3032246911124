import ONGP1 from "../../interfaces/p4";
import { useEffect, useState, useContext } from "react"
import ONGAnsweredOne from "./ongAnsweredp4";
import { AuthContext } from "../../context/auth";

export default function UserRow(){
    
    const [ONGP1List, setONGP1List] = useState<ONGP1[]>([]);
    const userContext = useContext(AuthContext);

    function renderONGP1() {
        return ONGP1List.map((currentDash: ONGP1) => <ONGAnsweredOne ongP1={currentDash}/>);
    }

    function sendOne()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        fetch(`${userContext?.api_url}/ong/listFourONG`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: localStorage.getItem('id')
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    setONGP1List(jsonResponse.info)
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

    useEffect(() => {
		sendOne();
	}, [])

    return(
    <div className="mx-0 my-0 w-[80%] lg:w-[85%] h-full">
        {renderONGP1()}
    </div>
    )
}