import { useContext } from 'react'
import { AuthContext } from '../context/auth'
import cta_logo from "./../assets/ctalogo2.png"
import LiveClock from "./LiveClock";
import React from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  ListItemSuffix,
  Chip,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import {
  PresentationChartBarIcon,
  ShoppingBagIcon,
  UserCircleIcon,
  Cog6ToothIcon,
  InboxIcon,
  PowerIcon,
} from "@heroicons/react/24/solid";
import { ChevronRightIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { NavLink } from 'react-router-dom';

interface SideBarProps{
    logout: () => void
}

export default function SideBar({logout}:SideBarProps){
    const userContext = useContext(AuthContext);
    const [open, setOpen] = React.useState(0);
    
    const handleOpen = (value: any) => {
      setOpen(open === value ? 0 : value);
    }
    
    return(
        
        // <div>     
        //     <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ml-3 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
        //         <span className="sr-only">Abrir Barra Lateral</span>
        //         <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        //             <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
        //         </svg>
        //     </button>


        //     <aside id="default-sidebar" className="fixed top-0 bg-principal-3 flex order-last flex-col place-items-center font-firasans text-xl left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0 text-principal-2 items-center justify-between" aria-label="Sidebar">
        //         <div className='text-principal-2 text-lg italic font-firasans mb-8 flex order-last flex-col w-full place-items-center text-xl left:0'>
        //             <a className="flex items-center">
        //                 <img src={cta_logo} alt="CTA Logo" className="h-11 ml-2 mt-3"/>
        //             </a>
        //         </div>
        //         <div className='text-principal-2 text-lg italic font-firasans mb-8 flex order-last flex-col w-full place-items-center text-xl left:0'>
        //             <ul className="space-y-2 font-medium">
        //                 <li className="flex place-items-center p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-black">
        //                     <NavLink to={"/"} className={({isActive}) => isActive ? "" : ""}>
        //                         Inicio
        //                     </NavLink>
        //                 </li>
        //                 { (userContext?.currentUser?.administrador || userContext?.currentUser?.superuser) && (
        //                 <li className="flex place-items-center p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-black">
        //                     <NavLink to={"/dashboard"} className={({isActive}) => isActive ? "" : ""}>
        //                         Dashboard
        //                     </NavLink>
        //                 </li>
        //                 ) }
        //                 { (userContext?.currentUser?.administrador || userContext?.currentUser?.superuser || userContext?.currentUser?.ong) && (
        //                 <li className="flex place-items-center p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-black">
        //                     <NavLink to={"/firstqong"} className={({isActive}) => isActive ? "" : ""}>
        //                         Cuestionario ONG
        //                     </NavLink>
        //                 </li>
        //                 ) }
        //                 { (userContext?.currentUser?.administrador || userContext?.currentUser?.superuser || userContext?.currentUser?.comp) && (
        //                 <li className="flex place-items-center p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-black">
        //                     <NavLink to={"/firstqmkt"} className={({isActive}) => isActive ? "" : ""}>
        //                         Cuestionario Mercantil
        //                     </NavLink>
        //                 </li>
        //                 ) }
        //             </ul>
        //         </div>
        //         <div className='text-principal-2 text-lg italic font-firasans mb-8 flex order-last flex-col w-full place-items-center text-xl left:0'>
        //             <p>{`${userContext?.currentUser?.company}`}</p>
        //             <button className='font-roboto text-md text-principal-2 hover:text-fondo' onClick={logout}>
        //                 Salir
        //             </button>
        //         </div>
        //     </aside>
        // </div>
        <Card className="h-screen p-4 shadow-xl bg-principal-1 justify-between">
        <div className="mb-2 p-4">
            <img src={cta_logo} alt="CTA Logo" className=""/>
        </div>
        <List>
        <ListItem className='text-principal-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-black'>
            <ListItemPrefix>
                <InboxIcon className="h-5 w-5" />
            </ListItemPrefix>
            <ListItem>
                <li className="flex place-items-center">
                    <NavLink to={"/"} className={({isActive}) => isActive ? "" : ""}>
                        Inicio
                    </NavLink>
                </li>
            </ListItem>
        </ListItem>
        { (userContext?.currentUser?.administrador || userContext?.currentUser?.superuser) && (
          <Accordion
            open={open === 1}
            icon={
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""}`}
              />
            }>
            <ListItem className="p-0 text-principal-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-black" selected={open === 1}>
              <AccordionHeader onClick={() => handleOpen(1)} className="border-b-0 p-3">
                <ListItemPrefix>
                  <PresentationChartBarIcon className="h-5 w-5" />
                </ListItemPrefix>
                <Typography className="mr-auto font-normal">
                   Dashboard
                </Typography>
              </AccordionHeader>
            </ListItem>
            <AccordionBody className="py-1">
              <List className="p-0">
                <ListItem className='text-principal-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-black'>
                    <li className="flex place-items-center">
                        <NavLink to={"/dashboard/users"} className={({isActive}) => isActive ? "" : ""}>
                            Usuarios
                        </NavLink>
                    </li>
                </ListItem>
                <ListItem className='text-principal-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-black'>
                    <li className="flex place-items-center">
                        <NavLink to={"/dashboard/ong"} className={({isActive}) => isActive ? "" : ""}>
                            Auditoria - ONG
                        </NavLink>
                    </li>
                </ListItem>
                <ListItem className='text-principal-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-black'>
                    <li className="flex place-items-center">
                        <NavLink to={"/dashboard/progong"} className={({isActive}) => isActive ? "" : ""}>
                            Progreso - ONG
                        </NavLink>
                    </li>
                </ListItem>
                <ListItem className='text-principal-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-black'>
                    <li className="flex place-items-center">
                        <NavLink to={"/dashboard/company"} className={({isActive}) => isActive ? "" : ""}>
                            Auditoria - Mercantil
                        </NavLink>
                    </li>
                </ListItem>
                <ListItem className='text-principal-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-black'>
                    <li className="flex place-items-center">
                        <NavLink to={"/dashboard/progmkt"} className={({isActive}) => isActive ? "" : ""}>
                            Progreso - Mercantil
                        </NavLink>
                    </li>
                </ListItem>
              </List>
            </AccordionBody>
          </Accordion>
        )}

        { (userContext?.currentUser?.administrador || userContext?.currentUser?.superuser || userContext?.currentUser?.comp) && (
        <ListItem className='text-principal-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-black'>
            <ListItemPrefix>
                <InboxIcon className="h-5 w-5" />
            </ListItemPrefix>
            <ListItem>
                <li className="flex place-items-center">
                    <NavLink to={"/company/p0"} className={({isActive}) => isActive ? "" : ""}>
                        Cuestionario Mercantil
                    </NavLink>
                </li>
            </ListItem>
        </ListItem>
        )}

        { (userContext?.currentUser?.administrador || userContext?.currentUser?.superuser || userContext?.currentUser?.ong) && (
        <ListItem className='text-principal-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-black'>
            <ListItemPrefix>
                <InboxIcon className="h-5 w-5" />
            </ListItemPrefix>
            <ListItem>
                <li className="flex place-items-center">
                    <NavLink to={"/ong/p1"} className={({isActive}) => isActive ? "" : ""}>
                        Cuestionario ONG
                    </NavLink>
                </li>
            </ListItem>
        </ListItem>
        )}

        <div className='text-principal-2'>
                <LiveClock/>
        </div>

        </List>
        <div className='text-principal-2 text-lg italic font-firasans mb-8 flex order-last flex-col w-full place-items-center text-xl left:0'>
            <p>{`${userContext?.currentUser?.company}`}</p>
            <button className='font-roboto text-md text-principal-2 hover:text-fondo' onClick={logout}>Salir</button>
            
        </div>
      </Card>
    )
}