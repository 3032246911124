import './App.css';
import React from 'react';
import { useContext } from 'react'
import { AuthContext } from './context/auth';
import AuthContextProps from './interfaces/AuthContextProps';
import LoginView from './components/LoginView';
import COMPOne from './components/mkt/comp0';
import COMPTwo from './components/mkt/comp1';
import COMPThree from './components/mkt/comp2';
import COMPFour from './components/mkt/comp3';
import COMPFive from './components/mkt/comp4';
import COMPSix from './components/mkt/comp5';
import COMPSeven from './components/mkt/comp6';
import COMPEight from './components/mkt/comp7';
import COMPNine from './components/mkt/comp8';
import COMPTen from './components/mkt/comp9';
import ONGone from './components/ong/ongp1';
import ONGTwo from './components/ong/ongp2';
import ONGThree from './components/ong/ongp3';
import ONGFour from './components/ong/ongp4';
import ONGFive from './components/ong/ongp5';
import ONGSix from './components/ong/ongp6';
import ONGSeven from './components/ong/ongp7';
import ONGEight from './components/ong/ongp8';
import ONGNine from './components/ong/ongp9';
import ONGTen from './components/ong/ongp10';
import ONGEleven from './components/ong/ongp11';
import ONGTwelve from './components/ong/ongp12';
import ONGThirteen from './components/ong/ongp13';
import ONGFourteen from './components/ong/ongp14';
import ONGHome from './components/ong/onghome';
import ONGPrevP1 from './components/ong/ongPreviewP1';
import ONGPrevP2 from './components/ong/ongPreviewP2';
import ONGPrevP3 from './components/ong/ongPreviewP3';
import ONGPrevP4 from './components/ong/ongPreviewP4';
import ONGPrevP5 from './components/ong/ongPreviewP5';
import ONGPrevP6 from './components/ong/ongPreviewP6';
import ONGPrevP7 from './components/ong/ongPreviewP7';
import ONGPrevP8 from './components/ong/ongPreviewP8';
import ONGPrevP9 from './components/ong/ongPreviewP9';
import ONGPrevP10 from './components/ong/ongPreviewP10';
import ONGPrevP11 from './components/ong/ongPreviewP11';
import ONGPrevP12 from './components/ong/ongPreviewP12';
import ONGPrevP13 from './components/ong/ongPreviewP13';
import ONGPrevP14 from './components/ong/ongPreviewP14';
import ONGDash from './components/ong/ongdashboard';
import MKTDash from './components/mkt/mktdashboard';
import MKTPrevP0 from './components/mkt/mktPreviewP0';
import MKTPrevP1 from './components/mkt/mktPreviewP1';
import MKTPrevP2 from './components/mkt/mktPreviewP2';
import MKTPrevP3 from './components/mkt/mktPreviewP3';
import MKTPrevP4 from './components/mkt/mktPreviewP4';
import MKTPrevP5 from './components/mkt/mktPreviewP5';
import MKTPrevP6 from './components/mkt/mktPreviewP6';
import MKTPrevP7 from './components/mkt/mktPreviewP7';
import MKTPrevP8 from './components/mkt/mktPreviewP8';
import MKTPrevP9 from './components/mkt/mktPreviewP9';
import MKTProgress from './components/mkt/mktProgressView';
import ONGProgress from './components/ong/ongProgressView';
import CompanyHome from './components/mkt/companyhome';
import HomePage from './components/HomePage';
import ResetPassword from './components/ResetPassword';
import UserAdmin from './components/UserAdmin';
import NotFound from './components/NotFound';
import Administration from './components/Administration';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom'
import User from './interfaces/user';

function App() 
{

  const userContext = useContext<AuthContextProps | null>(AuthContext);
  const navigate = useNavigate();

  function Login(newUser: User)
  {
    userContext?.setCurrentUser(newUser);
    navigate('/',{replace: true});
  }

 function Logout()
 {
   userContext?.removeCurrentUser();
   navigate('/',{replace: true});
 }

  return (
    <main>
      <Routes>

        <Route path="/" element={
              userContext?.currentUser != null ?
              (userContext.currentUser.reset_password?  <ResetPassword/> : <HomePage logout={Logout}/>  ) 
              : <Navigate to={"login"} replace/>
            }/>

        <Route path="/dashboard" element={
            userContext?.currentUser?.administrador || userContext?.currentUser?.superuser ?
            <Administration logout={Logout} /> :
            <Navigate to={"*"} replace/>
          }>
              <Route path="users" element={<UserAdmin/>}/>
              <Route path="progong" element={<ONGProgress/>}/>
              <Route path="progmkt" element={<MKTProgress/>}/>
              <Route path="ong" element={<ONGDash/>}/>
              <Route path="company" element={<MKTDash/>}/>

              <Route path="previewp1" element={<ONGPrevP1/>}/>
              <Route path="previewp2" element={<ONGPrevP2/>}/>
              <Route path="previewp3" element={<ONGPrevP3/>}/>
              <Route path="previewp4" element={<ONGPrevP4/>}/>
              <Route path="previewp5" element={<ONGPrevP5/>}/>
              <Route path="previewp6" element={<ONGPrevP6/>}/>
              <Route path="previewp7" element={<ONGPrevP7/>}/>
              <Route path="previewp8" element={<ONGPrevP8/>}/>
              <Route path="previewp9" element={<ONGPrevP9/>}/>
              <Route path="previewp10" element={<ONGPrevP10/>}/>
              <Route path="previewp11" element={<ONGPrevP11/>}/>
              <Route path="previewp12" element={<ONGPrevP12/>}/>
              <Route path="previewp13" element={<ONGPrevP13/>}/>
              <Route path="previewp14" element={<ONGPrevP14/>}/>

              <Route path="previewp0c" element={<MKTPrevP0/>}/>
              <Route path="previewp1c" element={<MKTPrevP1/>}/>
              <Route path="previewp2c" element={<MKTPrevP2/>}/>
              <Route path="previewp3c" element={<MKTPrevP3/>}/>
              <Route path="previewp4c" element={<MKTPrevP4/>}/>
              <Route path="previewp5c" element={<MKTPrevP5/>}/>
              <Route path="previewp6c" element={<MKTPrevP6/>}/>
              <Route path="previewp7c" element={<MKTPrevP7/>}/>
              <Route path="previewp8c" element={<MKTPrevP8/>}/>
              <Route path="previewp9c" element={<MKTPrevP9/>}/>
          </Route>

          <Route path="/ong" element={
            userContext?.currentUser?.administrador || userContext?.currentUser?.superuser || userContext?.currentUser?.ong ?
            <ONGHome logout={Logout}/> :
            <Navigate to={"*"} replace/>
          }>
              <Route path="p1" element={<ONGone/>}/>
              <Route path="p2" element={<ONGTwo/>}/>
              <Route path="p3" element={<ONGThree/>}/>
              <Route path="p4" element={<ONGFour/>}/>
              <Route path="p5" element={<ONGFive/>}/>
              <Route path="p6" element={<ONGSix/>}/>
              <Route path="p7" element={<ONGSeven/>}/>
              <Route path="p8" element={<ONGEight/>}/>
              <Route path="p9" element={<ONGNine/>}/>
              <Route path="p10" element={<ONGTen/>}/>
              <Route path="p11" element={<ONGEleven/>}/>
              <Route path="p12" element={<ONGTwelve/>}/>
              <Route path="p13" element={<ONGThirteen/>}/>
              <Route path="p14" element={<ONGFourteen/>}/>
          </Route>

          <Route path="/company" element={
              userContext?.currentUser?.administrador || userContext?.currentUser?.superuser || userContext?.currentUser?.company ?
              <CompanyHome logout={Logout}/> :
              <Navigate to={"*"} replace/>
              }
          >
              <Route path="p0" element={<COMPOne/>}/>
              <Route path="p1" element={<COMPTwo/>}/>
              <Route path="p2" element={<COMPThree/>}/>
              <Route path="p3" element={<COMPFour/>}/>
              <Route path="p4" element={<COMPFive/>}/>
              <Route path="p5" element={<COMPSix/>}/>
              <Route path="p6" element={<COMPSeven/>}/>
              <Route path="p7" element={<COMPEight/>}/>
              <Route path="p8" element={<COMPNine/>}/>
              <Route path="p9" element={<COMPTen/>}/>
          </Route>

        <Route path='/login' element={<LoginView login={Login}/>}/>
        <Route path='*' element={<NotFound/>}/>

      </Routes>
    </main>
  );
}

export default App;
