import { ReactNode } from "react";


interface ModalPromptProps{
  setShowModal: (show: boolean) => void,
  title: string,
  content: ReactNode
}

export default function ModalPrompt({
  title,
  content
}:ModalPromptProps){


  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative my-6 mx-auto w-1/3 xl:max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-fondo outline-none focus:outline-none font-firasans">
              {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-placeholder rounded-t">
              <h3 className="text-3xl font-semibold text-principal-1">
                  {title}
              </h3>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto overflow-y-auto font-roboto text-placeholder">
                  {
                    content
                  }
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-3 border-t border-solid border-slate-200 rounded-b">
              </div>
          </div>
          </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}