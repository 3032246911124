import User from "../interfaces/user";
import { useContext, useState } from "react";
import { AuthContext } from "../context/auth";
import cta_logo from "./../assets/ctalogo.png"

interface LoginProps 
{
    login: (newUser: User) => void
}

export default function LoginView({ login }: LoginProps) 
{

    const userContext = useContext(AuthContext);
    const [errMessage, setErrMessage] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");

    function changeAuth(event: React.FormEvent<HTMLFormElement>) 
    {
       event.preventDefault()

       let cUser: User = 
       {
           email: event.currentTarget.email.value,
           user_password: event.currentTarget.user_password.value
       }

        fetch(`${userContext?.api_url}/user/login`, 
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            body: JSON.stringify({
                "email": cUser.email,
                "user_password": cUser.user_password
            })
        }).then(resp => 
        {
            if (resp.status >= 400) 
            {
                setMessage("ERROR: Usuario o contraseña incorrecta.");
                setErrMessage(true);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    localStorage.setItem("USER_TOKEN", jsonResponse.token);
                    cUser = jsonResponse.info[0];
                    login(cUser);
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        }) 
    }

    return (
        <div className="grid w-full h-full place-items-center bg-fondo">
            <section>
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                    <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                        <img src={cta_logo} alt="Logo CTA" className="stroke-principal-1 fill-principal-1 w-full grid col-span-1 w-8 h-8 mr-2"/>
                    </a>
                    <div className="w-full bg-principal-1 rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Ingresa con tu cuenta
                            </h1>
                            <form className="space-y-4 md:space-y-6" onSubmit={changeAuth}>
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Correo</label>
                                    <input type="email" name="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-placeholder" placeholder="nombre@compañia.com" required/>
                                </div>
                                <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Contraseña</label>
                                    <input type="password" name="user_password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder-placeholder" required/>
                                </div>
                                <button formAction="submit" className="mt-4 grid col-span-1 bg-principal-3 w-full py-2 rounded-lg font-sans text-principal-2 font-firasans font-bold text-xl"> Ingresar</button>
                            </form>
                            {errMessage && <div className="py-2 mt-2 rounded-lg px-3 text-principal-2 font-roboto">{message}</div>}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}