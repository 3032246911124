import ONGP1 from "../../interfaces/p11";
import { useContext } from "react"
import { AuthContext } from "../../context/auth";
import { useNavigate } from 'react-router-dom';

interface UserRowProps{
    ongP1: ONGP1
}

export default function UserAdmin({
    ongP1
}:UserRowProps) 
{

    const navigate = useNavigate();

    function next()
    {
        navigate('/dashboard/previewp12')
    }

    function back()
    {
        navigate('/dashboard/previewp10')
    }

    const userContext = useContext(AuthContext);
    function sendOne()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        fetch(`${userContext?.api_url}/ong/listEleventhONG`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: localStorage.getItem('id')
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    console.log(resp);
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

return(
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            NOMINAS
        </h1>
        <form onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. ¿La Organización cuenta con manuales relativos al área de recursos humanos?</span><br/>
                    <select disabled name="p1">
                        <option value="No">{ongP1.p1}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. ¿Tiene la organización celebrado contrato colectivo de trabajo?</span><br/>
                    <select disabled name="p2">
                        <option value="No">{ongP1.p2}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. ¿Los contratos individuales de trabajo están debidamente registrados en la junta de conciliación y arbitraje?</span><br/>
                    <select disabled name="p3">
                        <option value="No">{ongP1.p3}</option>
                  </select>
                  <br/>
                  <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. ¿Existen autorizaciones escritas de sueldos y aumentos, incluyendo referencias a contratos colectivos?</span><br/>
                    <select disabled name="p4">
                        <option value="No">{ongP1.p4}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. ¿Se usan relojes checadores, tarjetas de tiempo, reportes o un sistema de computo?</span><br/>
                    <select disabled name="p5">
                        <option value="No">{ongP1.p5}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. ¿Se revisan esos documentos y se aprueban por un funcionario responsable?</span><br/>
                    <select disabled name="p6">
                        <option value="No">{ongP1.p6}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. ¿Las ausencias de los empleados se conocen oportunamente?</span><br/>
                    <select disabled name="p7">
                        <option value="No">{ongP1.p7}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. ¿La gerencia contrata a los empleados?</span><br/>
                    <textarea disabled name="p8" value={ongP1.p8} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #8..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. ¿Se mantienen registros y expedientes adecuados de los empleados?</span><br/>
                    <select disabled name="p9">
                        <option value="No">{ongP1.p9}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. ¿Existen autorizaciones escritas de sueldos y aumentos, incluyendo referencias a contratos colectivos?</span><br/>
                    <select disabled name="p10">
                        <option value="No">{ongP1.p10}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. ¿Si hay bonificaciones a empleados, se autorizan los importes por pagar por un funcionario responsable?</span><br/>
                    <textarea disabled value={ongP1.p11} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. ¿Se comparan las bonificaciones contra reportes de nomina y se revisan los cálculos?</span><br/>
                    <select disabled name="p12">
                        <option value="No">{ongP1.p12}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. ¿Las nóminas son revisadas en cuanto a sueldos, cálculos aritméticos, impuestos y otras retenciones por un empleado responsable?</span><br/>
                    <select disabled name="p13">
                        <option value="No">{ongP1.p13}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. ¿La distribución del importe de las nóminas a las cuentas aplicables es revisada por una persona distinta a quien las prepara?</span><br/>
                    <select disabled name="p14">
                        <option value="No">{ongP1.p14}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">15. ¿Se verificó que la organización haya inscrito a su personal, al cual le pague por concepto de salario, en la oficina recaudadora de la Federación correspondiente al domicilio fiscal del patrón?</span><br/>
                    <select disabled name="p15">
                        <option value="No">{ongP1.p15}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">16. ¿Se ha retenido y enterado el impuesto sobre todos los sueldos, salarios y comisiones a empleados, viáticos y gastos de viaje no comprobados, gratificaciones, prestaciones de previsión social que no reúnan los requisitos de deducibilidad o que excedan el salario normal?</span><br/>
                    <select disabled name="p16">
                        <option value="No">{ongP1.p16}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">17. ¿Se ha retenido y enterado el impuesto a las personas que habitualmente se les pagan honorarios?</span><br/>
                    <select disabled name="p17">
                        <option value="No">{ongP1.p17}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">18. ¿Son cubiertas oportunamente las cuotas de seguridad social, RAP y/o Cooperativas, así como los impuestos estatales sobre nómina?</span><br/>
                    <select disabled name="p18">
                        <option value="No">{ongP1.p18}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">19. ¿Se presentó oportunamente la declaración anual informativa de crédito al salario?</span><br/>
                    <select disabled name="p19">
                        <option value="No">{ongP1.p19}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">20. ¿El total de salarios y sueldos declarados se concilia con el libro mayor y con lo cargado a gastos por salarios y sueldos?</span><br/>
                    <select disabled name="p20">
                        <option value="No">{ongP1.p20}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">21. ¿Se concilia el total de erogaciones al final del ejercicio contra la base del Seguro Social y RAP?</span><br/>
                    <select disabled name="p21">
                        <option value="No">{ongP1.p21}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">22. ¿Todos los obreros, funcionarios y empleados se encuentran inscritos en el IHSS?</span><br/>
                    <textarea disabled value={ongP1.p22} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">23. ¿Para determinar el salario diario integrado se incluye el aguinaldo, gratificaciones, primas, comisiones, alimentación y alimentación gratuita, ahorro, horas extras pactadas en forma fija, etc.?</span><br/>
                    <select disabled name="p23">
                        <option value="No">{ongP1.p23}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">24. ¿Los avisos de inscripción, modificación de salario y baja se presentan dentro del plazo de cinco dias hábiles?</span><br/>
                    <select disabled name="p24">
                        <option value="No">{ongP1.p24}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">25. ¿Se calculan y descuentan adecuadamente las cuotas obreras a todos los trabajadores, con excepción de los que perciben el salario mínimo general?</span><br/>
                    <select disabled name="p25">
                        <option value="No">{ongP1.p25}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={next}
              >Siguiente</button>
        <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={back}
              >Atras</button>
        </form>
      </div>
    </div>
)}