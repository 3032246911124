import p8 from '../../interfaces/p8';
import React, { useContext, useEffect, useState } from 'react';
import ModalPrompt from "../ModalUpload";
import { AuthContext } from '../../context/auth';
import { useNavigate } from 'react-router-dom';

const FormExample6 = () => 
{

    const userContext = useContext(AuthContext);
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selected1, setSelected1] = useState();
    const [selected5, setSelected5] = useState();
    const [selected7, setSelected7] = useState();

    const [file1, setFile1] = useState<any>();
    const [file5, setFile5] = useState<any>();
    const [file7, setFile7] = useState<any>();

    const handleFile1 = (e: any) => 
    {
        setFile1(e.target?.files[0]);
    }

    const handleFile5 = (e: any) => 
    {
        setFile5(e.target?.files[0]);
    }

    const handleFile7 = (e: any) => 
    {
        setFile7(e.target?.files[0]);
    }

    const handleChange1 = (event:any) => 
    {
        setSelected1(event.target.value)
    }

    const handleChange5 = (event:any) => 
    {
        setSelected5(event.target.value)
    }

    const handleChange7 = (event:any) => 
    {
        setSelected7(event.target.value)
    }

    const handleUpload = () => 
    {
        const formData = new FormData();
        formData.append("file", file1);
        formData.append("file", file5);
        formData.append("file", file7);
        fetch(`${userContext?.api_url}/file/upload`, 
        {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.log(error));
    }

    async function sendOne(event: React.FormEvent<HTMLFormElement>)
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        event.preventDefault();

        let getP1: p8 = {
            fk_id_usuario: userContext?.currentUser?.id,
            p1: event.currentTarget.p1.value,
            p2: event.currentTarget.p2.value,
            p3: event.currentTarget.p3.value,
            p4: event.currentTarget.p4.value,
            p5: event.currentTarget.p5.value,
            p6: event.currentTarget.p6.value == 'No' ? (event.currentTarget.p6.value) : (file1.name),
            p7: event.currentTarget.p7.value == 'No' ? (event.currentTarget.p7.value) : (file7.name),
            p8: event.currentTarget.p8.value,
            p9: event.currentTarget.p9.value,
            p10: event.currentTarget.p10.value == 'No' ? (event.currentTarget.p10.value) : (file5.name),
            p11: event.currentTarget.p11.value,
            p12: event.currentTarget.p12.value,
            p13: event.currentTarget.p13.value,
            p14: event.currentTarget.p14.value,
            p15: event.currentTarget.p15.value,
            p16: event.currentTarget.p16.value,
            p17: event.currentTarget.p17.value,
            p18: event.currentTarget.p18.value
		}

        setShowModal(true);
        await fetch(`${userContext?.api_url}/user/upload`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                user_id: userContext?.currentUser?.id
            })
        }).then(resp => 
        {
            fetch(`${userContext?.api_url}/ong/eightQuestionary`, 
            {
                method: 'POST',
                headers: 
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    fk_id_usuario: getP1.fk_id_usuario,
                    p1: getP1.p1,
                    p2: getP1.p2,
                    p3: getP1.p3,
                    p4: getP1.p4,
                    p5: getP1.p5,
                    p6: getP1.p6,
                    p7: getP1.p7,
                    p8: getP1.p8,
                    p9: getP1.p9,
                    p10: getP1.p10,
                    p11: getP1.p11,
                    p12: getP1.p12,
                    p13: getP1.p13,
                    p14: getP1.p14,
                    p15: getP1.p15,
                    p16: getP1.p16,
                    p17: getP1.p17,
                    p18: getP1.p18
                })
            }).then(resp => 
                {   
                    if (resp.status >= 400) 
                    {
                        console.log(resp);
                    }
                    else
                    {
                        setShowModal(false);
                        navigate('/ong/p9')
                    }
                })
            }).catch((err) => 
            {
                console.log(err);
            })
    }

    function verifyStatus()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

            fetch(`${userContext?.api_url}/user/getFinished`, 
            {
                method: 'POST',
                headers: 
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    id: userContext?.currentUser?.id
                })
            }).then(resp => 
            {
                if (resp.status >= 400) 
                {
                    console.log(resp);
                } 
                else 
                {
                    resp.json().then(jsonResponse =>
                    {
                        let finished = jsonResponse; 
                        finished.map(()=>
                        {
                            if(finished[0].finishedfourteen === true)
                            {
                                navigate('/')
                            }
                            else if(finished[0].finishedeight === true)
                            {
                                navigate('/ong/p9')
                            }
                        })
                    })
                }
            }).catch((err) => 
            {
                console.log(err);
            })        
    }

    useEffect(() => {
        verifyStatus();
      });
    
  
  return (
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            IMPUESTOS POR PAGAR
        </h1>
        <form onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. ¿Se vigila el cumplimiento oportuno de las obligaciones fiscales a cargo de la entidad a efecto de evitar contingencias que puedan alterar su estabilidad?</span><br/>
                    <select required name="p1">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. ¿Se archivan de manera adecuada todas las declaraciones fiscales presentadas?</span><br/>
                    <select required name="p2">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. ¿Cuenta la entidad con un calendario de obligaciones fiscales?</span><br/>
                    <select required name="p3">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                  <br/>
                  <br/>
                </label>
            </div>

            <span className="text-principal-2">Se revisa la documentación y comprobantes en cuanto a:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. ¿Cuales son las obligaciones fiscales en materia de ISR, Impuesto al Activo e IVA que tiene registrada la organización?</span><br/>
                    <textarea required name="p4" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #4..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. En caso de haber pagado contribuciones mediante compensación, ¿se verificó que estén presentados los avisos correspondientes?</span><br/>
                    <textarea required name="p5" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #5..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. ¿Se han presentado declaraciones del Impuesto Sobre La Renta del los últimos 3 años anteriores?</span><br/>
                    <select required name="p6" value={selected1} onChange={handleChange1}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected1 === 'Si' && 
                        <div>
                                <span className="text-principal-1">Subir en formato .pdf</span><br/>
                                <input onChange={handleFile1} className="text-principal-2" type="file" name ='p6' accept="application/pdf" required/>
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. ¿Preparan recibos de ingresos o llevan registros de ingresos?</span><br/>
                    <select required name="p7" value={selected7} onChange={handleChange7}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected7 === 'Si' && 
                        <div>
                                <span className="text-principal-1">Subir en formato .pdf</span><br/>
                                <input onChange={handleFile7} className="text-principal-2" type="file" name ='p7' accept="application/pdf" required/>
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. ¿Mantiene en resguardo toda la documentación concernientes a las obligaciones fiscales de la organización?</span><br/>
                    <select required name="p8">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. ¿Se realizan mensualmente las declaraciones concernientes al ISR de la Fuente de la Organización?</span><br/>
                    <select required name="p9">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. ¿Contiene un detalle especifico por mes de las personas que se les realiza la deduccion correspondiente al ISR de la Fuente?</span><br/>
                    <select required name="p10" value={selected5} onChange={handleChange5}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected5 === 'Si' && 
                        <div>
                                <span className="text-principal-1">Subir en formato .pdf</span><br/>
                                <input onChange={handleFile5} className="text-principal-2" type="file" name ='p10' accept="application/pdf" required/>
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. ¿Mantiene actualizado el sistema de DET LIVE de la plataforma virtual de la Servicios de Administración de Renta (SAR)?</span><br/>
                    <select required name="p11">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. ¿Registra mensualmente las declaraciones mensuales de retencion (DMR) al sistema DET 5.5.?</span><br/>
                    <select required name="p12">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. ¿Se encuentran subidas en la plataforma virtual de la Servicios de Administración de Renta (SAR)?</span><br/>
                    <select required name="p13">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. ¿Se realizan las retenciones del 12.5% de servicios profesionales mensualmente?</span><br/>
                    <select name="p14">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">15. ¿Se encuentran las declaraciones del 12.5% de servicios profesionales detalladas por cada persona retenida?</span><br/>
                    <select name="p15">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select> 
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">16. ¿Existe un expediente relacionado con los servicios profesionales que estan sujetos a la retencion del 12.5%?</span><br/>
                    <select required name="p16">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">17. ¿Emiten la organización la constancia de retención a cada servicio profesional, de acuerdo al nuevo regimen de facturación de la SAR?</span><br/>
                    <select required name="p17">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">18. ¿Esta actualizado el libro de retenciones autorizado por la Alcaldia Municipal y la Servicios de Administración de Renta (SAR)?</span><br/>
                    <select required name="p18">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={handleUpload}
              >Guardar y Avanzar</button>

            {showModal ? (
                <ModalPrompt
                    setShowModal={setShowModal}
                    title={'Subiendo archivos'}
                    content={
                        <span className="text-principal-2">La subida de archivos dependerá de la velocidad de su internet, por favor espere.</span>
                    }
                />
                ) : null}
                
        </form>
      </div>
    </div>
  );
};

export default FormExample6;