import ONGP1 from "../../interfaces/p4";
import { useContext } from "react"
import { AuthContext } from "../../context/auth";
import { useNavigate } from 'react-router-dom';

interface UserRowProps{
    ongP1: ONGP1
}

export default function UserAdmin({
    ongP1
}:UserRowProps) 
{

    const navigate = useNavigate();

    function next()
    {
        navigate('/dashboard/previewp5')
    }

    function back()
    {
        navigate('/dashboard/previewp3')
    }

    const userContext = useContext(AuthContext);
    function sendOne()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        fetch(`${userContext?.api_url}/ong/listFourONG`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: localStorage.getItem('id')
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    console.log(resp);
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

return(
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
      <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            PROCESOS INTERNOS
        </h1>
        <p className="font-semibold text-center text-principal-2 uppercase">Cuentas por cobrar</p>
        <form onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. ¿Existe una separación definida en las labores de los empleados que manejan las cuentas por cobrar, los fondos de caja, la correspondencia y los registros contables?</span><br/>
                    <textarea disabled name="p1" value={ongP1.p1} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #1..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. ¿Se envían confirmaciones de saldo por sorpresa periódicamente por los auditores internos o por algún funcionario responsable?</span><br/>
                    <select disabled name="p2">
                        <option value="No">{ongP1.p2}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. ¿Se somete a la aprobación de un funcionario responsable la aceptación de los documentos y garantías colaterales?</span><br/>
                    <textarea disabled value={ongP1.p3} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                  <br/>
                  <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. ¿Se llevan registros detallados de los documentos por cobrar y de las garantías colaterales?</span><br/>
                    <select disabled name="p4">
                        <option value="No">{ongP1.p4}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. ¿Los auditores internos u otros funcionarios independientes practican periódicamente una inspección de los documentos?</span><br/>
                    <select disabled name="p5">
                        <option value="No">{ongP1.p5}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. ¿Se notifica puntualmente a algún funcionario responsable el vencimiento de los documentos?</span><br/>
                    <textarea disabled value={ongP1.p6} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. ¿Que documento se utiliza para establecer las cuentas por cobrar derivadas de el giro de la organización?</span><br/>
                    <textarea disabled name="p7" value={ongP1.p7} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #7..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. ¿Se vigila que esta cuenta sea utilizada para albergar cuentas por cobrar no derivadas del giro u objeto de la entidad?</span><br/>
                    <textarea disabled name="p8" maxLength={300} value={ongP1.p8} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #8..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. ¿Son autorizados los anticipos a empleados por un funcionario apropiado?</span><br/>
                    <textarea disabled value={ongP1.p9} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. ¿Se tiene establecido un monto máximo para prestamos a empleados?</span><br/>
                    <textarea disabled name="p10" value={ongP1.p10} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #10..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. ¿Son revisados los saldos periódicamente por un funcionario responsable?</span><br/>
                    <textarea disabled value={ongP1.p11} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Están los ajustes o cancelación de cuentas  por cobrar aprobadas por un funcionario que no sea responsable de:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. ¿Su autorización?</span><br/>
                    <textarea disabled value={ongP1.p12} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. ¿Su cobro?</span><br/>
                    <textarea disabled value={ongP1.p13} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. ¿Prevé el sistema el registro adecuado y oportuno de ingresos provenientes de rentas, regalías, intereses?</span><br/>
                    <textarea disabled value={ongP1.p14} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={next}
              >Siguiente</button>
        <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={back}
              >Atras</button>
        </form>
      </div>
    </div>
)}