import ONGP1 from "../../interfaces/p12";
import { useContext, useState } from "react"
import { AuthContext } from "../../context/auth";
import { useNavigate } from 'react-router-dom';
import ModalPrompt from "../ModalFiles";

interface UserRowProps{
    ongP1: ONGP1
}

export default function UserAdmin({
    ongP1
}:UserRowProps) 
{

    const navigate = useNavigate();
    const userContext = useContext(AuthContext);
    const [showModal, setShowModal] = useState<boolean>(false);

    function next()
    {
        navigate('/dashboard/previewp13')
    }

    function back()
    {
        navigate('/dashboard/previewp11')
    }

    function modalOne()
    {
        setShowModal(true)
    }

    function sendOne()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        fetch(`${userContext?.api_url}/ong/listTwelveONG`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: localStorage.getItem('id')
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    console.log(resp);
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

return(
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            SISTEMAS DE INFORMACIÓN
        </h1>
        <form onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. ¿Los paquetes principales de "software" de la entidad son paquetes comerciales disponibles o fueron desarrollados de forma interna?</span><br/>
                    <select disabled name="p1">
                        <option value="No">{ongP1.p1}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. ¿La Organización cuenta con un presupuesto coherente para la compra e inversión contínua en hardware computacional?</span><br/>
                    <select disabled name="p2">
                        <option value="No">{ongP1.p2}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. ¿El hardware computacional es suficiente para satisfacer las necesidades de la organización?</span><br/>
                    <select disabled name="p3">
                        <option value="No">{ongP1.p3}</option>
                  </select>
                  <br/>
                  <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. ¿La organización investiga los productos de software para determinar si cumplen con las necesidades de los usuarios?</span><br/>
                    <select disabled name="p4">
                        <option value="No">{ongP1.p4}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. ¿La organización obtiene software de marcas prestigiadas?</span><br/>
                    <textarea disabled value={ongP1.p5} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. ¿La política de la organización prohíbe el uso de programas no autorizados introducidos por el personal?</span><br/>
                    <select disabled name="p6">
                        <option value="No">{ongP1.p6}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. ¿La política de la organización prohíbe la descarga e instalación de software por parte de usuarios no autorizados?</span><br/>
                    <select disabled name="p7">
                        <option value="No">{ongP1.p7}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. ¿El libro mayor y los registros contables principales son procesados por una organización de servicios externa? Si la respuesta es "sí", suba los documentos fuente que se proporcionaron a la organización de servicios, los informes y otra documentación</span><br/>
                    <select disabled name="p8">
                        <option value="No">{ongP1.p8}</option>
                    </select>
                    </label>
                {ongP1.p8 === 'No' ? (
                    <span className="text-principal-2">No se subio ningún archivo</span>
                    ) : 
                    <div>
                        <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalOne}>Consultar</button>

                        {showModal ? (
                            <ModalPrompt
                            setShowModal={setShowModal}
                            title={ongP1.p8}
                            content={
                                
                                <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p8} height="100%" width="100%"></iframe>
                                
                            }
                            />
                            ) : null}

                    </div>               
                }
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. ¿Solamente registran las transacciones de la entidad y los datos relacionados con los procesos? O ¿tienen la capacidad de iniciar transacciones por su cuenta?</span><br/>
                    <select disabled name="p9">
                        <option value="No">{ongP1.p9}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. ¿El hardware computacional de la organización está instalado de forma segura y adecuada?</span><br/>
                    <select disabled name="p10">
                        <option value="No">{ongP1.p10}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. ¿El área donde se ubica el equipo de cómputo (servidores) tiene el acceso restringido y una correcta ventilación?</span><br/>
                    <textarea disabled value={ongP1.p11} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. ¿La organización cuenta con programas de mantenimiento de hardware estándares y regulares?</span><br/>
                    <select disabled name="p12">
                        <option value="No">{ongP1.p12}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. ¿La organización ha establecido procedimientos para el respaldo periódico de información?</span><br/>
                    <select disabled name="p13">
                        <option value="No">{ongP1.p13}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. ¿Los archivos de respaldo se almacenan en un sitio seguro fuera de las instalaciones?</span><br/>
                    <textarea disabled value={ongP1.p14} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">15. ¿Los usuarios se involucran en el diseño y aprobación de sistemas?</span><br/>
                    <select disabled name="p15">
                        <option value="No">{ongP1.p15}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">16. ¿Los usuarios revisan la conclusión de varias etapas de aplicación?</span><br/>
                    <select disabled name="p16">
                        <option value="No">{ongP1.p16}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">17. ¿Se realizan pruebas detalladas de los nuevos programas?</span><br/>
                    <select disabled name="p17">
                        <option value="No">{ongP1.p17}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">18. ¿Los usuarios se involucran en la revisión de las pruebas de programa?</span><br/>
                    <select disabled name="p18">
                        <option value="No">{ongP1.p18}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">19. ¿Los usuarios se involucran en el diseño y aprobación de cambios de programa?</span><br/>
                    <select disabled name="p19">
                        <option value="No">{ongP1.p19}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">20. ¿La organización usa software antivirus para atacar las infecciones de virus?</span><br/>
                    <select disabled name="p20">
                        <option value="No">{ongP1.p20}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">21. ¿La administración ha identificado datos confidenciales y delicados a los cuales se debe restringir el acceso?</span><br/>
                    <select disabled name="p21">
                        <option value="No">{ongP1.p21}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">22. ¿Existen procedimientos instalados para reducir el acceso a los datos confidenciales y delicados?</span><br/>
                    <textarea disabled value={ongP1.p22} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">23. ¿Existen procedimientos instalados para reducir el riesgo de que se asienten en procesamiento transacciones no autorizadas?</span><br/>
                    <select disabled name="p23">
                        <option value="No">{ongP1.p23}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">24. ¿Existen procedimientos instalados para detectar cambios no autorizados a programas que soportan los estados financieros?</span><br/>
                    <select disabled name="p24">
                        <option value="No">{ongP1.p24}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">25. ¿Se controla el acceso de operadores al código fuente y elementos individuales de los archivos de datos?</span><br/>
                    <select disabled name="p25">
                        <option value="No">{ongP1.p25}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">26. ¿Los usuarios sólo tienen acceso a programas y archivos de datos autorizados?</span><br/>
                    <select disabled name="p26">
                        <option value="No">{ongP1.p26}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">27. ¿Los programas de aplicaciones de la organización son compatibles entre sí?</span><br/>
                    <select disabled name="p27">
                        <option value="No">{ongP1.p27}</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={next}
              >Siguiente</button>
        <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={back}
              >Atras</button>
        </form>
      </div>
    </div>
)}