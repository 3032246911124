import { useContext, useState } from "react";
import acv_logo from "./../assets/logo_acv1.svg"
import { AuthContext } from "../context/auth";
import User from "../interfaces/user";
import { useNavigate } from "react-router-dom"

interface ResetPasswordProps {

}

/**
 * Componente que se encarga del reinicio de contraseña
 * @author flynn1411
 */
export default function ResetPassword({ }: ResetPasswordProps) {

    // Contexto
    const userContext = useContext(AuthContext);

    /******                ESTADOS                    *******/
    // Estado para mostrar o no el mensaje de error
    const [errMessage, setErrMessage] = useState<boolean>(false);

    // Estado de cadena de mensaje de error (en caso de encontrarse uno)
    const [message, setMessage] = useState<string>("");
    const navigate = useNavigate();

    /**
     * Función que se encarga de reiniciar la contraseña, este proceso consta de ciertos pasos:
     * 1. Obtener los valores del formulario
     * 2. Verificar si la confirmación concuerda con la nueva contraseña ingresada
     * 3. Enviar solicitud a la API
     * @param event evento de Submit del formulario
     */
    function changePassword(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()

        let token: string = "";

        if (localStorage.getItem("USER_TOKEN") !== null) {
            token = localStorage.getItem("USER_TOKEN")!;
        }

        // Se obtienen los datos del formulario
        let old_password = event.currentTarget.old_password.value;
        let new_password = event.currentTarget.new_password.value;
        let new_password_confirm = event.currentTarget.new_password_confirm.value;

        /*let checked =  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(old_password)
                    && /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(new_password)
                    && /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(new_password_confirm);*/

        let passwordConfirmed = (new_password !== old_password) && (new_password === new_password_confirm);

        if (/*checked &&*/ passwordConfirmed) {

            fetch(`${userContext?.api_url}/user/changePass`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    "id": userContext?.currentUser?.id,
                    "newPass": new_password,
                    "oldPass": old_password
                })
            }).then(resp => {

                if (resp.status >= 400) {
                    console.log(resp)
                    setMessage(`ERROR: ${resp.status}`);
                    setErrMessage(true);
                } else {
                    resp.json().then(jsonResponse => {
                        console.log(jsonResponse);

                        let currentUser = userContext?.currentUser;

                        if (currentUser?.reset_password) {
                            currentUser.reset_password = false;
                        }

                        userContext?.setCurrentUser(currentUser);
                        alert(`${jsonResponse.message}`);
                        //setErrMessage(true);
                        navigate('/', { replace: true });
                    })
                }

            }).catch((err) => {
                console.log(err);

                setMessage(`ERROR: ${err}`);
                setErrMessage(true);
            })


        } else {
            if (old_password === new_password) {
                setMessage("La nueva contraseña no debe ser la contraseña actual.");
            } else {
                setMessage("La nueva contraseña no coincide con la confirmación.");
            }

            setErrMessage(true);
        }

    }

    return (
<div className="grid w-full h-full place-items-center bg-fondo">
                <form
                    onSubmit={changePassword}
                    className="grid-cols-1"
                >
                    <label htmlFor="old_password" className="text-principal-2 grid col-span-1 pb-2">
                        Ingrese su contraseña actual
                    </label>
                    <input
                        type="password"
                        //placeholder=""
                        name="old_password"
                        required
                        //pattern="/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/"
                        //title="Minimo 8 carácteres, una mayuscúla, un número, se permite @!#$%&/()=?¿:;"
                        className="grid col-span-1 border-principal-1 focus:border-principal-2 bg-transparent font-sans border-4 rounded-lg mb-2 py-2 px-2 placeholder-principal-2 w-full text-lg"
                    />
                    <label htmlFor="new_password" className="text-principal-2 grid col-span-1 pb-2">
                        Ingrese su contraseña nueva (minímo 8 carácteres)
                    </label>
                    <input
                        type="password"
                        //placeholder="Ingrese su contraseña actual"
                        name="new_password"
                        required
                        //pattern="/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/"
                        //title="Minimo 8 carácteres, una mayuscúla, un número, se permite @!#$%&/()=?¿:;"
                        className="grid col-span-1 border-principal-1 focus:border-principal-2 bg-transparent font-sans border-4 rounded-lg mb-2 py-2 px-2 placeholder-principal-2 w-full text-lg"
                    />
                    <label htmlFor="new_password_confirm" className="text-principal-2 grid col-span-1 pb-2">
                        Confirme su contraseña nueva
                    </label>
                    <input
                        type="password"
                        //placeholder="Ingrese su contraseña actual"
                        name="new_password_confirm"
                        required
                        //pattern="/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/"
                        //title="Minimo 8 carácteres, una mayuscúla, un número, se permite @!#$%&/()=?¿:;"
                        className="grid col-span-1 border-principal-1 focus:border-principal-2 bg-transparent font-sans border-4 rounded-lg mb-2 py-2 px-2 placeholder-principal-2 w-full text-lg"
                    />
                    <button
                        formAction="submit"
                        //onClick={()=>changeAuth()}
                        className="text-principal-2 mt-4 grid col-span-1 bg-principal-1 w-full py-2 rounded-lg font-sans text-fondo font-firasans font-bold text-xl hover:bg-principal-2">
                        Cambiar Contraseña
                    </button>
                </form>

                {errMessage && <div className="py-2 mt-2 rounded-lg px-3 text-principal-2 font-roboto">{message}</div>}

            </div>
    );
}