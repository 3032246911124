import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router";
import {useEffect} from 'react';
import SideBar from "../SideBar";

/**
 * Propiedades o parámetros del componente HomePage
 * @author flynn1411
 */
interface AdminPageProps{
    logout: () => void
  }

export default function Administration({logout}:AdminPageProps){

    const navigate = useNavigate();

    useEffect(() => {
      navigate("/ong/p1", {replace: true})
    }, [])
    

    return(
        <div className="flex w-full h-full bg-principal-3">
        {/* Area para barra de navegación lateral */}
        <div className="mx-0 my-0 w-[20%] xl:w-[15%] h-full sticky top-0">
          <SideBar logout={logout}/>
        </div>
    
        {/* Area para el contenido en general */}
        <div className="mx-0 my-0 w-[80%] lg:w-[85%] h-full">
          <Outlet/>
        </div>
      </div>
    )
}
