import ONGP1 from "../../interfaces/p2";
import { useContext } from "react"
import { AuthContext } from "../../context/auth";
import { useNavigate } from 'react-router-dom';

interface UserRowProps{
    ongP1: ONGP1
}

export default function UserAdmin({
    ongP1
}:UserRowProps) 
{

    const navigate = useNavigate();

    function next()
    {
        navigate('/dashboard/previewp3')
    }

    function back()
    {
        navigate('/dashboard/previewp1')
    }

    const userContext = useContext(AuthContext);
    function sendOne()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        fetch(`${userContext?.api_url}/ong/listTwoONG`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: localStorage.getItem('id')
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    console.log(resp);
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

return(
<div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            SISTEMA DE GOBIERNO
        </h1>
        <form id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. Desde que se inicio la personería jurídica, cuantas veces han hecho cambio de la Junta Directiva?</span>
                    <textarea disabled value={ongP1.p1} maxLength={300} name="p1" id='text-area1' className="w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring"  placeholder="Pregunta #1..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. Cada cuanto se reúne la Junta Directiva y normalmente como esta compuesta la agenda de reunión?</span>
                    <textarea disabled value={ongP1.p2} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. Existe en la actual junta directiva alguna comisión de seguimiento y evaluación de las actividades en relación a los proyectos en ejecución?</span><br/>
                    <select disabled name="p3">
                        <option value="No">{ongP1.p3}</option>
                  </select>
                  <br/>
                  <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. En que fecha se están realizando las Asambleas Generales?</span>
                    <input type="date" value={ongP1.p4} name ='p4' disabled/>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. Existe algún proceso de evaluación de cada miembro de la Junta Directiva?</span><br/>
                    <textarea disabled value={ongP1.p5} name="p2" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. Revisan en cada reunión de junta directiva los informes de actividades e informes financieros?</span><br></br>
                    <select disabled name="p6">
                        <option value="No">{ongP1.p6}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. Quien o quienes manejan las entregas de donaciones a los niños y niñas de las comunidades?</span><br/>
                    <textarea disabled value={ongP1.p7} name="p7" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #7..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. Existe un Plan Operativo Anual (POA) que es monitoreado por la Junta Directiva?</span><br/>
                    <select disabled name="p8">
                        <option value="No">{ongP1.p8}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. Existe algún proceso de conocimiento de las necesidades de cada comunidad?  ¿En caso de existir problemas entre los niños y niñas en las comunidades toda la Junta Directiva lleva y conoce el caso o solo el responsable de la comunidad?</span><br/>
                    <textarea disabled name="p9" value={ongP1.p9} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #9..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. Existe algún miembro de la Junta Directiva que recibe algún salario o remuneración?</span><br/>
                    <select disabled name="p10">
                        <option value="No">{ongP1.p10}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Preguntas al Secretario(a):</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. Lleva al día el libro de actas de la Junta Directiva?</span><br/>
                    <select disabled name="p11">
                        <option value="No">{ongP1.p11}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. Lleva un registro de cada reunión de Junta Directiva la asistencia y opiniones de relevancia para la organización?</span><br/>
                    <select disabled name="p12">
                        <option value="No">{ongP1.p12}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. Informa mensualmente a la Junta Directiva las decisiones de relevancia a cada miembro de la Junta?</span><br/>
                    <select disabled name="p13">
                        <option value="No">{ongP1.p13}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Preguntas al Fiscal:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. Tiene acceso a los cheques y documentos contables?</span><br/>
                    <select disabled name="p14">
                        <option value="No">{ongP1.p14}</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">15. Cada cuanto revisa de forma muestral los pagos mas relevantes de la organización?</span>
                    <textarea disabled name="p15" value={ongP1.p15} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #15..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Preguntas a toda JD:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">16. Existe una comisión que supervise a todos los Comités de Apoyo?</span><br/>
                    <textarea disabled name="p16" value={ongP1.p16} maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #16..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={next}
              >Siguiente</button>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={back}
              >Atras</button>
        </form>
      </div>
    </div>
)}