import p1 from '../../interfaces/p1';
import React, { useContext, useEffect, useState } from 'react';
import ModalPrompt from "../ModalUpload";
import { AuthContext } from '../../context/auth';
import { useNavigate } from 'react-router-dom';

const FormExample6 = () => 
{
    const userContext = useContext(AuthContext);
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [file6, setFile6] = useState<any>();
    const [file7, setFile7] = useState<any>();
    const [file8, setFile8] = useState<any>();
    const [file9, setFile9] = useState<any>();
    const [file, setFile] = useState<any>();
    const [file15, setFile15] = useState<any>();
    const [file23, setFile23] = useState<any>();
    const [file24, setFile24] = useState<any>();
    const [file30, setFile30] = useState<any>();
    const [file31, setFile31] = useState<any>();
    const [file33, setFile33] = useState<any>();
    const [file35, setFile35] = useState<any>();

    const handleFile6 = (e: any) => 
    {
        setFile6(e.target?.files[0]);
    }

    const handleFile7 = (e: any) => 
    {
        setFile7(e.target?.files[0]);
    }

    const handleFile8 = (e: any) => 
    {
        setFile8(e.target?.files[0]);
    }

    const handleFile9 = (e: any) => 
    {
        setFile9(e.target?.files[0]);
    }

    const handleFile = (e: any) => 
    {
        setFile(e.target?.files[0]);
    }

    const handleFile15 = (e: any) => 
    {
        setFile15(e.target?.files[0]);
    }

    const handleFile23 = (e: any) => 
    {
        setFile23(e.target?.files[0]);
    }

    const handleFile24 = (e: any) => 
    {
        setFile24(e.target?.files[0]);
    }

    const handleFile30 = (e: any) => 
    {
        setFile30(e.target?.files[0]);
    }

    const handleFile31 = (e: any) => 
    {
        setFile31(e.target?.files[0]);
    }

    const handleFile33 = (e: any) => 
    {
        setFile33(e.target?.files[0]);
    }

    const handleFile35 = (e: any) => 
    {
        setFile35(e.target?.files[0]);
    }

    const [selected, setSelected] = useState()
    const [selected15, setSelected15] = useState()
    const [selected23, setSelected23] = useState()
    const [selected24, setSelected24] = useState()
    const [selected30, setSelected30] = useState()
    const [selected31, setSelected31] = useState()
    const [selected33, setSelected33] = useState()
    const [selected35, setSelected35] = useState()

    const handleChange14 = (event:any) => 
    {
       setSelected(event.target.value)
    }

    const handleChange15 = (event:any) => 
    {
        setSelected15(event.target.value)
        
    }

    const handleChange23 = (event:any) => 
    {
        setSelected23(event.target.value)
    }

    const handleChange24 = (event:any) => 
    {
        setSelected24(event.target.value)
    }

    const handleChange30 = (event:any) => 
    {
        setSelected30(event.target.value)
    }

    const handleChange31 = (event:any) => 
    {
        setSelected31(event.target.value)
    }

    const handleChange33 = (event:any) => 
    {
        setSelected33(event.target.value)
    }

    const handleChange35 = (event:any) => 
    {
        setSelected35(event.target.value)
    }

    async function sendOne(event: React.FormEvent<HTMLFormElement>)
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        event.preventDefault();

        let getP1: p1 = {
            fk_id_usuario: userContext?.currentUser?.id,
            p1: event.currentTarget.p1.value,
            p2: event.currentTarget.p2.value,
            p3: event.currentTarget.p3.value,
            p4: event.currentTarget.p4.value,
            p5: event.currentTarget.p5.value,
            p6: file6.name,
            p7: file7.name,
            p8: file8.name,
            p9: file9.name,
            p10: event.currentTarget.p10.value,
            p11: event.currentTarget.p11.value,
            p12: event.currentTarget.p12.value,
            p13: event.currentTarget.p13.value,
            p14: event.currentTarget.p14.value == 'No' ? (event.currentTarget.p14.value) : (file.name),
            p15: event.currentTarget.p15.value == 'No' ? (event.currentTarget.p15.value) : (file15.name),
            p16: event.currentTarget.p16.value,
            p17: event.currentTarget.p17.value,
            p18: event.currentTarget.p18.value,
            p19: event.currentTarget.p19.value,
            p20: event.currentTarget.p20.value,
            p21: event.currentTarget.p21.value,
            p22: event.currentTarget.p22.value,
            p23: event.currentTarget.p23.value == 'No' ? (event.currentTarget.p23.value) : (file23.name),
            p24: event.currentTarget.p24.value == 'No' ? (event.currentTarget.p24.value) : (file24.name),
            p25: event.currentTarget.p25.value,
            p26: event.currentTarget.p26.value,
            p27: event.currentTarget.p27.value,
            p28: event.currentTarget.p28.value,
            p29: event.currentTarget.p29.value,
            p30: event.currentTarget.p30.value == 'No' ? (event.currentTarget.p30.value) : (file30.name),
            p31: event.currentTarget.p31.value == 'No' ? (event.currentTarget.p31.value) : (file31.name),
            p32: event.currentTarget.p32.value,
            p33: event.currentTarget.p33.value == 'No' ? (event.currentTarget.p33.value) : (file33.name),
            p34: event.currentTarget.p34.value,
            p35: event.currentTarget.p35.value == 'No' ? (event.currentTarget.p35.value) : (file35.name),
            p36: event.currentTarget.p36.value,
            p37: event.currentTarget.p37.value,
            p38: event.currentTarget.p38.value,
            p39: event.currentTarget.p39.value,
            p40: event.currentTarget.p40.value,
            p41: event.currentTarget.p41.value
		}

        setShowModal(true);
        await fetch(`${userContext?.api_url}/user/upload`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                user_id: userContext?.currentUser?.id
            })
        }).then(async resp => 
        {
            await fetch(`${userContext?.api_url}/ong/firstQuestionary`, 
            {
                method: 'POST',
                headers: 
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    fk_id_usuario: getP1.fk_id_usuario,
                    p1: getP1.p1,
                    p2: getP1.p2,
                    p3: getP1.p3,
                    p4: getP1.p4,
                    p5: getP1.p5,
                    p6: getP1.p6,
                    p7: getP1.p7,
                    p8: getP1.p8,
                    p9: getP1.p9,
                    p10: getP1.p10,
                    p11: getP1.p11,
                    p12: getP1.p12,
                    p13: getP1.p13,
                    p14: getP1.p14,
                    p15: getP1.p15,
                    p16: getP1.p16,
                    p17: getP1.p17,
                    p18: getP1.p18,
                    p19: getP1.p19,
                    p20: getP1.p20,
                    p21: getP1.p21,
                    p22: getP1.p22,
                    p23: getP1.p23,
                    p24: getP1.p24,
                    p25: getP1.p25,
                    p26: getP1.p26,
                    p27: getP1.p27,
                    p28: getP1.p28,
                    p29: getP1.p29,
                    p30: getP1.p30,
                    p31: getP1.p31,
                    p32: getP1.p32,
                    p33: getP1.p33,
                    p34: getP1.p34,
                    p35: getP1.p35,
                    p36: getP1.p36,
                    p37: getP1.p37,
                    p38: getP1.p38,
                    p39: getP1.p39,
                    p40: getP1.p40,
                    p41: getP1.p41
                })
            }).then(resp => 
            {   
                if (resp.status >= 400) 
                {
                    console.log(resp);
                }
                else
                {
                    setShowModal(false);
                    navigate('/ong/p2')
                }
            })
        }).catch((err) => 
        {
            return err;
        })

    }

    function verifyStatus()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

            fetch(`${userContext?.api_url}/user/getFinished`, 
            {
                method: 'POST',
                headers: 
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    id: userContext?.currentUser?.id
                })
            }).then(resp => 
            {
                if (resp.status >= 400) 
                {
                    console.log(resp);
                } 
                else 
                {
                    resp.json().then(jsonResponse =>
                    {
                        let finished = jsonResponse; 
                        finished.map(()=>
                        {
                            if(finished[0].finishedfourteen === true)
                            {
                                navigate('/')
                            }
                            else if(finished[0].finishedone === true)
                            {
                                navigate('/ong/p2')
                            }
                        })
                    })
                }
            }).catch((err) => 
            {
                console.log(err);
            })        
    }

    const handleUpload = () => 
    {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("file", file6);
        formData.append("file", file7);
        formData.append("file", file8);
        formData.append("file", file9);
        formData.append("file", file15);
        formData.append("file", file23);
        formData.append("file", file24);
        formData.append("file", file30);
        formData.append("file", file31);
        formData.append("file", file33);
        formData.append("file", file35);
        fetch(`${userContext?.api_url}/file/upload`, 
        {
            method: 'POST',
            body: formData
        })
            .then(response => response.json())
            .then(data => console.log(data))
            .catch(error => console.log(error));
    }

    useEffect(() => {
        verifyStatus();
      });
    
  
  return (
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            CONOCIMIENTO DEL CLIENTE
        </h1>
        <form onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. Nombre de la empresa.</span>
                    <textarea required maxLength={300} name="p1" id='text-area1' className="w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring"  placeholder="Pregunta #1..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. Domicilio y teléfonos, planta y oficinas.</span>
                    <textarea required maxLength={300} name="p2" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. Teléfono</span>
                    <textarea required maxLength={300} name="p3" className="pattern w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Número Teléfonico sin guiones." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. Dirección</span>
                    <textarea required maxLength={300} name="p4" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #4..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. Representante Legal</span>
                    <textarea required maxLength={300} name="p5" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #5..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. Identidad del Representante Legal</span><br></br>
                    <span className="text-principal-1">Subir en formato .pdf</span><br/>
                    <input onChange={handleFile6} className="text-principal-2" type="file" name ='file' accept="application/pdf" required/>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. RTN del Representante Legal</span><br/>
                    <span className="text-principal-1">Subir en formato .pdf</span><br/>
                    <input onChange={handleFile7} className="text-principal-2" type="file" name ='file' accept="application/pdf" required/>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. Estados Financieros Firmados y Sellados</span><br/>
                    <span className="text-principal-1">Subir en formato .pdf</span><br/>
                    <input onChange={handleFile8} className="text-principal-2" type="file" name ='file' accept="application/pdf" required/>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. Logo de Empresa</span><br/>
                    <span className="text-principal-1">Subir en formato .png</span><br/>
                    <input onChange={handleFile9} className="text-principal-2" type="file" name ='file' accept="image/png" required/>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. Nombre de los principales funcionarios y el nombre específico a quien debamos dirigir nuestra propuesta.</span>
                    <textarea required maxLength={300} name="p10" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #10..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. Objeto o Giro de la compañía y/o Organización y/o institución.</span>
                    <textarea required maxLength={300} name="p11" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #11..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. Capital social (Patrimonio Institucional), número de acciones, valor nominal, clase de las mismas.</span>
                    <textarea required maxLength={300} name="p12" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #12..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. Fecha de terminación de su ejercicio fiscal.</span><br/>
                    <input type="date" name ='p13' required/>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. ¿Su empresa tiene Registro Tributario Nacional? - RTN</span><br/>
                    <select required name="p14" value={selected} onChange={handleChange14}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected === 'Si' && 
                        <div>
                                <span className="text-principal-1">Subir en formato .pdf</span><br/>
                                <input onChange={handleFile} className="text-principal-2" type="file" name ='p14' accept="application/pdf" required/>
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">15. Número Patronal del IHSS.</span><br/>
                    <select required name="p15" value={selected15} onChange={handleChange15}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected15 === 'Si' && 
                        <div>
                                <span className="text-principal-1">Subir en formato .pdf</span><br/>
                                <input onChange={handleFile15} className="text-principal-2" type="file" name ='p15' accept="application/pdf" required/>
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">16. Cámara y/o Institucion regulada a la que pertenece:</span>
                    <textarea required maxLength={300} name="p16" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #16..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">17. Número aproximado de empleados y obreros.</span><br/>
                    <select required name="p17">
                        <option value="1-10">1-10</option>
                        <option value="11-20">11-20</option>
                        <option value="21-30">21-30</option>
                        <option value="31-40">31-40</option>
                        <option value="41-50">41-50</option>
                        <option value="51plus">51 en adelante</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">18. Total de activo, pasivo y capital contable, ingresos anuales, gastos y utilidades.</span>
                    <textarea required maxLength={300} name="p18" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #18..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">19. Nombres de los principales accionistas y/o Junta Directiva.</span>
                    <textarea required maxLength={300} name="p19" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #19..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">20. ¿Qué relaciones ha tenido la compañía y/o Organización con otros despachos de contadores públicos? Indicar que clase de servicio ha recibido y de que despacho o contador público.</span>
                    <textarea required maxLength={300} name="p20" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #20..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">21. Revisar dictámenes, informes y'/'o cartas de sugerencias preparadas por otros contadores públicos con motivo de sus intervenciones.</span>
                    <textarea required maxLength={300} name="p21" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #21..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">22. ¿Son aparentemente adecuados los métodos del registro, ya sean electrónicos, mecánicos o manuales?</span><br/>
                    <select required name="p22">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                  <br/>
                  <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">23. ¿Contiene la organización Personería Jurídica?</span><br/>
                    <select required name="p23" value={selected23} onChange={handleChange23}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected23 === 'Si' && 
                        <div>
                                <span className="text-principal-1">Subir en formato .pdf</span><br/>
                                <input onChange={handleFile23} className="text-principal-2" type="file" name ='p23' accept="application/pdf" required/>
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">24. ¿Ha tenido modificaciones la Personería Jurídica?</span><br/>
                    <select required name="p24" value={selected24} onChange={handleChange24}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected24 === 'Si' && 
                        <div>
                                <span className="text-principal-1">Subir en formato .pdf</span><br/>
                                <input onChange={handleFile24} className="text-principal-2" type="file" name ='p24' accept="application/pdf" required/>
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">25. ¿De cuantos miembros cuenta la Junta Directiva de la Organización?</span>
                    <textarea required maxLength={300} name="p25" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #25..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">26. ¿Existe en el libro de actas todos los nombramientos desde su fundación?</span><br/>
                    <select required name="p26">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">27. ¿Cada cuanto se reúne la Junta Directiva de la Organización?</span>
                    <textarea required maxLength={300} name="p27" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #27..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">28. ¿De que procedimientos y reglamentos se rige la Junta Directiva?</span>
                    <textarea required maxLength={300} name="p28" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #28..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">29. ¿Solicita la Junta Directiva Informes de Gestión a la Dirección Ejecutiva?</span>
                    <textarea required maxLength={300} name="p29" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #29..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">30. ¿Contiene Reglamentos Internos de Trabajo?</span><br/>
                    <select required name="p30" value={selected30} onChange={handleChange30}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected30 === 'Si' && 
                        <div>
                                <span className="text-principal-1">Subir en formato .pdf</span><br/>
                                <input onChange={handleFile30} className="text-principal-2" type="file" name ='p30' accept="application/pdf" required/>
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">31. ¿Contiene un Organigrama?</span><br/>
                    <select required name="p31" value={selected31} onChange={handleChange31}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected31 === 'Si' && 
                        <div>
                                <span className="text-principal-1">Subir en formato .pdf</span><br/>
                                <input onChange={handleFile31} className="text-principal-2" type="file" name ='p31' accept="application/pdf" required/>
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">32. Describa las Credenciales que como Organización y/o compañía pertenece.</span>
                    <textarea required maxLength={300} name="p32" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #32..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">33. ¿Contiene la organización Cartas válidas de acuerdos con Organismos Internacionales?</span><br/>
                    <select required name="p33" value={selected33} onChange={handleChange33}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected33 === 'Si' && 
                        <div>
                                <span className="text-principal-1">Subir en formato .pdf</span><br/>
                                <input onChange={handleFile33} className="text-principal-2" type="file" name ='p33' accept="application/pdf" required/>
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">34. ¿Existe un límite en el número de períodos de la Junta Directiva?</span>
                    <select required name="p34">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">35. ¿Hay juicios pendientes de tipo laboral, mercantil o de litigios de la organización?</span><br/>
                    <select required name="p35" value={selected35} onChange={handleChange35}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected35 === 'Si' && 
                        <div>
                                <span className="text-principal-1">Subir en formato .pdf</span><br/>
                                <input onChange={handleFile35} className="text-principal-2" type="file" name ='p35' accept="application/pdf" required/>
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">36. ¿Cual es el tipo de proceso de selección de la Junta Directiva?</span>
                    <textarea required maxLength={300} name="p36" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #36..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">37. ¿Mencione las características principales del Presidente'(a)' de la Junta Directiva?</span>
                    <textarea required maxLength={300} name="p37" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #37..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">38. ¿Mencione las características principales del Tesorero(a) de la Junta Directiva?</span>
                    <textarea required maxLength={300} name="p38" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #38..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">39. ¿Mencione las características principales del Secretario (a) de la Junta Directiva?</span>
                    <textarea required maxLength={300} name="p39" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #39..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">40. ¿Mencione las características principales del Fiscal de la Junta Directiva?</span>
                    <textarea required maxLength={300} name="p40" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #40..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">41. ¿Cuantos años lleva la Junta Directiva anual? ¿y para cuando finaliza su periodo?</span>
                    <textarea required maxLength={300} name="p41" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #41..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={handleUpload}
              >Guardar y Avanzar</button>
              
            {showModal ? (
				<ModalPrompt
					setShowModal={setShowModal}
					title={'Subiendo archivos'}
					content={
                        <span className="text-principal-2">La subida de archivos dependerá de la velocidad de su internet, por favor espere.</span>
                    }
				/>
			    ) : null}
        </form>
      </div>
    </div>
  );
};

export default FormExample6;