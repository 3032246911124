import { ReactNode } from "react";


interface ModalPromptProps{
  setShowModal: (show: boolean) => void,
  title: string | undefined,
  content: ReactNode
}

export default function ModalPrompt({
  setShowModal,
  title,
  content
}:ModalPromptProps){


  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative bg-fondo my-6 mx-auto w-1/3 h-full xl:max-w-3xl">
              <div className="flex items-start justify-between p-5 border-b border-solid border-placeholder rounded-t">
                <h3 className="text- 1xl font-semibold text-principal-1">
                    {title}
                </h3>
                <button
                    className="p-1 ml-auto bg-transparent border-0 text-principal-2 float-right text-3xl leading-none font-semibold outline-none focus:outline-none "
                    onClick={() => setShowModal(false)}
                >
                    <span className="bg-transparent text-principal-2 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                    </span>
                </button>
              </div>
              {
                content
              }
          {/*content*/}
          </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}