import { Outlet} from "react-router-dom";
import SideBar from './SideBar';
import Welcome from './Welcome';

/**
 * Propiedades o parámetros del componente HomePage
 * @author flynn1411
 */
interface HomePageProps{
  logout: () => void
}

/**
 * Componente de aplicación principal.
 * Este contiene la base donde todo el contenido se muestra y consta de dos partes:
 * - SideBar: Barra lateral en el lado izquierdo que funciona como navegación entre las distintas partes del sistema
 * - Outlet / contenido: espacio designado donde se muestran todos los componentes, estos se renderizan a traves de las rutas ya definidas en App
 * @author flynn1411
 * @param param0 
 * @returns 
 */
export default function HomePage({logout}:HomePageProps){

  /*function changeAuth(){
    logout();
  }*/

  return(
  <div className="flex w-full h-full  bg-principal-3">
    {/* Area para barra de navegación lateral */}
    <div className="mx-0 my-0 w-[20%] xl:w-[15%] h-full">
      <SideBar logout={logout}/>
    </div>

    {/* Area para el contenido en general */}
    <div className="mx-0 my-0 w-[80%] lg:w-[85%] h-full">
      <Outlet/>
      <Welcome/>
    </div>
  </div>
  );
}