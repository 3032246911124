import p1 from '../../interfaces/p3m';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/auth';
import { useNavigate } from 'react-router-dom';

const FormExample6 = () => 
{
    const userContext = useContext(AuthContext);
    const navigate = useNavigate();

    const [selected, setSelected] = useState()
    const [selected32, setSelected32] = useState()

    const handleChange = (event:any) => 
    {
       setSelected(event.target.value)
    }

    const handleChange32 = (event:any) => 
    {
       setSelected32(event.target.value)
    }

    function sendOne(event: React.FormEvent<HTMLFormElement>)
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        event.preventDefault();

        let getP1: p1 = {
            fk_id_usuario: userContext?.currentUser?.id,
            p1: event.currentTarget.p1.value,
            p2: event.currentTarget.p2.value,
            p3: event.currentTarget.p3.value,
            p4: event.currentTarget.p4.value,
            p5: event.currentTarget.p5.value,
            p6: event.currentTarget.p6.value,
            p7: event.currentTarget.p7.value,
            p8: event.currentTarget.p8.value,
            p9: event.currentTarget.p9.value,
            p10: event.currentTarget.p10.value,
            p11: event.currentTarget.p11.value,
            p12: event.currentTarget.p12.value,
            p13: event.currentTarget.p13.value,
            p14: event.currentTarget.p14.value,
            p15: event.currentTarget.p15.value,
            p16: event.currentTarget.p16.value,
            p17: event.currentTarget.p17.value,
            p18: event.currentTarget.p18.value,
            p19: event.currentTarget.p19.value,
            p20: event.currentTarget.p20.value,
            p21: event.currentTarget.p21.value,
            p22: event.currentTarget.p22.value,
            p23: event.currentTarget.p23.value,
            p24: event.currentTarget.p24.value,
            p25: event.currentTarget.p25.value,
            p26: event.currentTarget.p26.value,
            p27: event.currentTarget.p27.value,
            p28: event.currentTarget.p28.value,
            p29: event.currentTarget.p29.value,
            p30: event.currentTarget.p30.value == 'No' ? (event.currentTarget.p30.value) : (event.currentTarget.pp30.value),
            p31: event.currentTarget.p31.value,
            p32: event.currentTarget.p32.value == 'No' ? (event.currentTarget.p32.value) : (event.currentTarget.pp32.value),
            p33: event.currentTarget.p33.value,
            p34: event.currentTarget.p34.value,
            p35: event.currentTarget.p35.value,
            p36: event.currentTarget.p36.value,
            p37: event.currentTarget.p37.value,
            p38: event.currentTarget.p38.value,
            p39: event.currentTarget.p39.value,
            p40: event.currentTarget.p40.value,
            p41: event.currentTarget.p41.value,
            p42: event.currentTarget.p42.value,
            p43: event.currentTarget.p43.value,
            p44: event.currentTarget.p44.value
		}

        fetch(`${userContext?.api_url}/company/thirdQuestionary`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: getP1.fk_id_usuario,
                p1: getP1.p1,
                p2: getP1.p2,
                p3: getP1.p3,
                p4: getP1.p4,
                p5: getP1.p5,
                p6: getP1.p6,
                p7: getP1.p7,
                p8: getP1.p8,
                p9: getP1.p9,
                p10: getP1.p10,
                p11: getP1.p11,
                p12: getP1.p12,
                p13: getP1.p13,
                p14: getP1.p14,
                p15: getP1.p15,
                p16: getP1.p16,
                p17: getP1.p17,
                p18: getP1.p18,
                p19: getP1.p19,
                p20: getP1.p20,
                p21: getP1.p21,
                p22: getP1.p22,
                p23: getP1.p23,
                p24: getP1.p24,
                p25: getP1.p25,
                p26: getP1.p26,
                p27: getP1.p27,
                p28: getP1.p28,
                p29: getP1.p29,
                p30: getP1.p30,
                p31: getP1.p31,
                p32: getP1.p32,
                p33: getP1.p33,
                p34: getP1.p34,
                p35: getP1.p35,
                p36: getP1.p36,
                p37: getP1.p37,
                p38: getP1.p38,
                p39: getP1.p39,
                p40: getP1.p40,
                p41: getP1.p41,
                p42: getP1.p42,
                p43: getP1.p43,
                p44: getP1.p44
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    navigate('/company/p4')
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

    function verifyStatus()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

            fetch(`${userContext?.api_url}/user/getFinishedMKT`, 
            {
                method: 'POST',
                headers: 
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    id: userContext?.currentUser?.id
                })
            }).then(resp => 
            {
                if (resp.status >= 400) 
                {
                    console.log(resp);
                } 
                else 
                {
                    resp.json().then(jsonResponse =>
                    {
                        let finished = jsonResponse; 
                        finished.map(()=>
                        {
                            if(finished[0].finishedten === true)
                            {
                                navigate('/')
                            }
                            else if(finished[0].finishedfour === true)
                            {
                                navigate('/company/p4')
                            }
                        })
                    })
                }
            }).catch((err) => 
            {
                console.log(err);
            })        
    }

    useEffect(() => {
        verifyStatus();
        window.scrollTo(0, 0);
      });
    
  
  return (
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            EFECTIVO Y EQUIVALENTES
        </h1>
        <form onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. ¿Cuantas cuentas bancarias existen abiertas?</span><br/>
                    <textarea required maxLength={300} name="p1" id='text-area1' className="w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring"  placeholder="Pregunta #1..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. ¿Cuál es el objeto de cada una de ellas?</span><br/>
                    <textarea required maxLength={300} name="p2" id='text-area1' className="w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring"  placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. ¿Todas las cuentas bancarias son utilizadas por la empresa?</span><br/>
                    <select required name="p3">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. ¿Está centralizada la responsabilidad por las entradas y los depósitos de efectivo en el menor número posible de personas?</span><br/>
                    <select name="p4">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Están todos los empleados que participan en las entradas, los pagos y que manejan efectivo y valores:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. ¿Adecuadamente afianzados?</span><br/>
                    <select name="p5">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. ¿Obligados a tomar vacaciones anuales?</span><br/>
                    <select name="p6">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Los empleados que manejan efectivo están desligados de algunos de los deberes siguientes:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. ¿Preparan recibos de ingresos o llevan registros de ingresos?</span><br/>
                    <select name="p7">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. ¿Manejan o tiene acceso a los libros auxiliares?</span><br/>
                    <select name="p8">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. ¿Manejan el libro mayor o el registro de la póliza?</span><br/>
                    <select name="p9">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. ¿Preparan o aprueban los comprobantes de pagos?</span><br/>
                    <select name="p10">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. ¿Preparan, firman o entregan los cheques de pagos?</span><br/>
                    <select name="p11">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">INGRESOS DE CAJA:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. ¿Cuales son las principales fuentes de ingresos?</span><br/>
                    <textarea required maxLength={300} name="p12" id='text-area1' className="w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring"  placeholder="Pregunta #12..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. ¿Dónde y como se reciben los ingresos?</span><br/>
                    <textarea required maxLength={300} name="p13" id='text-area1' className="w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring"  placeholder="Pregunta #13..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. Las entradas de dinero a caja por cantidades relativamente grandes, ¿son en cheques o giros y no en efectivo?</span><br/>
                    <textarea required maxLength={300} name="p14" id='text-area1' className="w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring"  placeholder="Pregunta #14..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">15. ¿Que clase de registro se lleva para las entradas de caja y quien lo lleva?</span><br/>
                    <textarea required maxLength={300} name="p15" id='text-area1' className="w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring"  placeholder="Pregunta #15..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">16. ¿Se depositan correctamentos los ingresos que corresponden a reintegros o devoluciones intactos y sin ningún retraso?</span><br/>
                    <select name="p16">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">17. ¿Mantienen actualizado el control de los ingresos de caja hasta que se depositan en los bancos?</span><br/>
                    <select name="p17">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">18. ¿Alguna persona independiente, efectúa periódicamente un examen por sorpresa de caja chica?</span><br/>
                    <select name="p18">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">19. ¿Se entregan directamente a un empleado independiente del cajero las partidas de depósito o cobro devueltas por el banco? (debido a la insuficiencia de fondos, etc.)</span><br/>
                    <select name="p19">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">20. ¿Se encuentra prohibido cambiar cheques por ingresos recibidos en efectivo y se respeta esa prohibición?</span><br/>
                    <select name="p20">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">21. ¿Se han dado instrucciones escritas a los bancos para que no hagan efectivo ningún cheque a nombre de la empresa?</span><br/>
                    <select name="p21">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">PAGOS:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">22. ¿Que clase de registro de pagos se lleva y quien lo lleva?</span><br/>
                    <textarea required name="p22" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #22..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">23. ¿Se hacen por medio de cheques todos los pagos, salvo los de caja chica?</span><br/>
                    <select name="p23">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">24. ¿Se emplea una maquina protectora de cheques?</span><br/>
                    <select name="p24">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">25. ¿Se mutilan o cancelan los cheques anulados para impedir que vuelvan a usarse y se adhieren a la póliza con el sello de cancelado?</span><br/>
                    <select name="p25">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">En lo que respecta a los talonarios de cheques en blanco:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">26. ¿Se tienen en un lugar seguro?</span><br/>
                    <select name="p26">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">27. ¿Son accesibles solamente a las personas autorizadas para preparar cheques y giros?</span><br/>
                    <select name="p27">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">28. ¿Se prepara un Voucher de cheque o se hace un registro del cheque simultáneamente a su preparación?</span><br/>
                    <select name="p28">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">29. ¿Se hacen los pagos solamente basándose en comprobantes aprobados con la documentación original de soporte adjunta?</span><br/>
                    <select name="p29">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">30. ¿Se cancela con el sello de pagado los documentos correspondientes para impedir el pago duplicado?  En caso afirmativo Como, cuando y quién lo sella?</span><br/>
                    <select required name="p30" value={selected} onChange={handleChange}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected === 'Si' && 
                        <div>
                                <textarea required maxLength={300} name="pp30" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="¿Como, cuando y quién lo sella?" />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">31. ¿Quiénes son las personas autorizadas para firmar los cheques? (Hágase una lista de los nombres  y de los puestos que ocupan).</span><br/>
                    <textarea required name="p31" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #31..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">32. ¿Necesitan dos firmas todos los cheques que representen desembolsos de fondos en general? En caso negativo, ¿por qué se considera esto innecesario?</span><br/>
                    <select required name="p32" value={selected32} onChange={handleChange32}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected32 === 'Si' && 
                        <div>
                                <textarea required maxLength={300} name="pp32" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="¿Como, cuando y quién lo sella?" />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">33. ¿Existen limitaciones a los importes de los cheques firmados por una sola persona?</span><br/>
                    <select name="p33">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">34. ¿Las personas autorizadas tienen prohibido firmar cheques en blanco y  respetan esa prohibición?</span><br/>
                    <select name="p34">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">Las personas autorizadas para firmar cheques están desligadas de los deberes siguientes:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">35. ¿Prepara la conciliación de las cuentas de los bancos?</span><br/>
                    <select name="p35">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">36. ¿Prepara o revisa los comprobantes?</span><br/>
                    <select name="p36">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">37. ¿Tiene bajo su custodia la caja chica u otros fondos especiales?</span><br/>
                    <select name="p37">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>
            
            <span className="text-principal-2">CAJA CHICA:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">38. ¿Se hacen los pagos menores por medio de fondo fijo reembolsable contra entrega de comprobantes?</span><br/>
                    <select name="p38">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">39. ¿Esta prohibido mezclar los ingresos de caja con el fondo de caja chica?</span><br/>
                    <select name="p39">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">40. ¿Recae en una sola persona la responsabilidad por cada fondo?</span><br/>
                    <select name="p40">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">41. ¿Están los pagos de caja chica soportados por documentos debidamente aprobados?</span><br/>
                    <select name="p41">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">42. ¿Se comprueban en el momento del reembolso los documentos que sirven de apoyo a los pagos de la caja chica?</span><br/>
                    <select name="p42">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">43. ¿Cuándo se reponen los fondos de la caja chica, (se cancelan con el sello de "PAGADO" y la fecha) los comprobantes para evitar el pago duplicado?</span><br/>
                    <select name="p43">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">44. ¿No se expiden los cheques para reponer el fondo de caja chica a la orden de la persona que tiene a su cargo la custodia de la misma.</span><br/>
                    <select name="p44">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
              >Guardar y Avanzar</button>
        </form>
      </div>
    </div>
  );
};

export default FormExample6;