import ONGDash from "../../interfaces/dash";
import { SetStateAction, useContext, useEffect, useState } from "react"
import placeholder_logo from "../../assets/placeholder.jpg";
import { useNavigate } from "react-router";
import { AuthContext } from "../../context/auth";

interface UserRowProps{
    ongDash: ONGDash
}

export default function UserRow({
    ongDash
}:UserRowProps){
    
    const navigate = useNavigate();
    const userContext = useContext(AuthContext);

    function sendOne(event: React.FormEvent<HTMLFormElement>)
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        event.preventDefault();

        let getP1 = {
            id: event.currentTarget.p1.value
		}

        fetch(`${userContext?.api_url}/ong/listOneONG`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: getP1.id
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    localStorage.setItem('id', getP1.id) 
                    navigate('/dashboard/previewp1')
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

    return(
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 w-full">
        <form onSubmit={sendOne} id="newOne">
        <textarea className="invisible" disabled name="p1" value={ongDash.id}/>
            <div className="shadow hover:shadow-md w-full bg-white rounded-lg overflow-hidden mt-2 ml-2">
                <img
                    className="object-cover w-full"
                    src={'https://ctaauditores.com/plataforma_cta/archivos/' + ongDash.id + '/' + ongDash.logo}
                    alt="Logo de empresa"
                    />

                <div className="relative p-4">
                    <h3 className="text-base md:text-xl font-medium text-gray-800">
                        {ongDash.nombre}
                    </h3>

                    <p className="mt-4 text-base md:text-lg text-gray-600">
                        {ongDash.direccion}<br/>
                        {ongDash.telefono}<br/>
                    </p>
                <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
              >Consultar</button>
                </div>
            </div>
        </form>
    </div>
    )
}