/**
 * ? Funciones para consumir la API
 * 
 * @author AlexxFuentes
 * @date 29/05/23
 */

/**
 * ? Método para consultar la API con el método POST
 * @param method metodo de consulta a la API
 * @param content contenido a enviar a la API
 * @param url ruta de consulta a la API
 * @returns objeto JSON con la respuesta de la API
 * 
 * @author AlexxFuentes
 * @date 29/05/23
 * 
 * @modifiedBy AlexxFuentes
 * @modifiedAt 30/05/23
 */
export const APIPOST = async (method: string, content: any, url: string) => {
    let token: string = "";
    if (localStorage.getItem("USER_TOKEN") !== null) {
        token = localStorage.getItem("USER_TOKEN")!;
    }

    return fetch(`${url}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': token
        },
        body: JSON.stringify(content)
    })
    .then(resp => {
        if (resp.status >= 400) {
            console.log(resp.status);
            return [];
        } else {
            return resp.json().then(jsonResponse => {
                return jsonResponse;
            });
        }
    })
    .catch((err) => {
        console.log(err);
        return [];
    });
}

/**
 * ? Método para consultar la API con el método GET
 * @param method metodo de consulta a la API
 * @param url ruta de consulta a la API
 * @returns objeto JSON con la respuesta de la API
 * 
 * @author AlexxFuentes
 * @date 29/05/23
 * 
 * @modifiedBy AlexxFuentes
 * @modifiedAt 30/05/23
 */
export const APIGET = async (method: string, url: string) => {
    let token: string = "";
    if (localStorage.getItem("USER_TOKEN") !== null) {
        token = localStorage.getItem("USER_TOKEN")!;
    }

    return fetch(`${url}`, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': token
        }
    })
    .then(resp => {
        if (resp.status >= 400) {
            console.log(resp.status);
            return [];
        } else {
            return resp.json().then(jsonResponse => {
                return jsonResponse;
            });
        }
    })
    .catch((err) => {
        console.log(err);
        return [];
    });
}