import p1 from '../../interfaces/p6m';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/auth';
import { useNavigate } from 'react-router-dom';

const FormExample6 = () => 
{
    const userContext = useContext(AuthContext);
    const navigate = useNavigate();

    const [selected, setSelected] = useState()
    const [selected32, setSelected32] = useState()

    const handleChange = (event:any) => 
    {
       setSelected(event.target.value)
    }

    const handleChange32 = (event:any) => 
    {
       setSelected32(event.target.value)
    }

    function sendOne(event: React.FormEvent<HTMLFormElement>)
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        event.preventDefault();

        let getP1: p1 = {
            fk_id_usuario: userContext?.currentUser?.id,
            p1: event.currentTarget.p1.value,
            p2: event.currentTarget.p2.value,
            p3: event.currentTarget.p3.value,
            p4: event.currentTarget.p4.value,
            p5: event.currentTarget.p5.value,
            p6: event.currentTarget.p6.value,
            p7: event.currentTarget.p7.value,
            p8: event.currentTarget.p8.value,
            p9: event.currentTarget.p9.value,
            p10: event.currentTarget.p10.value,
            p11: event.currentTarget.p11.value,
            p12: event.currentTarget.p12.value,
            p13: event.currentTarget.p13.value,
            p14: event.currentTarget.p14.value,
            p15: event.currentTarget.p15.value,
            p16: event.currentTarget.p16.value,
            p17: event.currentTarget.p17.value,
            p18: event.currentTarget.p18.value,
            p19: event.currentTarget.p19.value,
            p20: event.currentTarget.p20.value,
            p21: event.currentTarget.p21.value,
            p22: event.currentTarget.p22.value,
            p23: event.currentTarget.p23.value
		}

        fetch(`${userContext?.api_url}/company/sixthQuestionary`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: getP1.fk_id_usuario,
                p1: getP1.p1,
                p2: getP1.p2,
                p3: getP1.p3,
                p4: getP1.p4,
                p5: getP1.p5,
                p6: getP1.p6,
                p7: getP1.p7,
                p8: getP1.p8,
                p9: getP1.p9,
                p10: getP1.p10,
                p11: getP1.p11,
                p12: getP1.p12,
                p13: getP1.p13,
                p14: getP1.p14,
                p15: getP1.p15,
                p16: getP1.p16,
                p17: getP1.p17,
                p18: getP1.p18,
                p19: getP1.p19,
                p20: getP1.p20,
                p21: getP1.p21,
                p22: getP1.p22,
                p23: getP1.p23
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    navigate('/company/p7')
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

    function verifyStatus()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

            fetch(`${userContext?.api_url}/user/getFinishedMKT`, 
            {
                method: 'POST',
                headers: 
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    id: userContext?.currentUser?.id
                })
            }).then(resp => 
            {
                if (resp.status >= 400) 
                {
                    console.log(resp);
                } 
                else 
                {
                    resp.json().then(jsonResponse =>
                    {
                        let finished = jsonResponse; 
                        finished.map(()=>
                        {
                            if(finished[0].finishedten === true)
                            {
                                navigate('/')
                            }
                            else if(finished[0].finishedseven === true)
                            {
                                navigate('/company/p7')
                            }
                        })
                    })
                }
            }).catch((err) => 
            {
                console.log(err);
            })        
    }

    useEffect(() => {
        verifyStatus();
        window.scrollTo(0, 0);
      });
    
  
  return (
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            INGRESOS CORRIENTES
        </h1>
        <form onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. ¿Existen políticas de los ingresos y se encuentran documentadas y autorizadas por autoridades gerenciales?</span><br/>
                    <select required name="p1">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. ¿De acuerdo a la estructura funcional de la empresa, existe una adecuada segregación de funciones y son independientes los empleados que manejan los inventarios?</span><br/>
                    <select required name="p2">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. ¿De acuerdo a la estructura funcional de la empresa, existe una adecuada segregación de funciones y son independientes los directivos que manejan la Gerencia?</span><br/>
                    <select required name="p3">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. ¿De acuerdo a la estructura funcional de la empresa, existe una adecuada segregación de funciones y son independientes los empleados que manejan los fondos de caja?</span><br/>
                    <textarea required maxLength={300} name="p4" className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #4..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. ¿De acuerdo a la estructura funcional de la empresa, existe una adecuada segregación de funciones y son independientes los empleados que manejan los registros contables?</span><br/>
                    <select name="p5">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. ¿Se concilian mensualmente los auxiliares de cuentas por cobrar con los registros contables?</span><br/>
                    <select name="p6">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. ¿Se expiden recibos o comprobantes por ingresos, que reúnan los requisitos fiscales?</span><br/>
                    <select name="p7">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. ¿Tratándose de ingresos por ventas u otros servicios, se expiden recibos por ingresos con el nuevo regimen de facturación?</span><br/>
                    <select name="p8">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. ¿Se adjuntan comprobantes o registros de los clientes en las transferencias recibidas?</span><br/>
                    <select name="p9">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. ¿Existen ingresos no identificados en las transferencias bancarias o depositos realizados?</span><br/>
                    <select name="p10">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. ¿Mantienen una información expedita o base de datos de los clientes sobre la recepcion de facturas ycobros efectuados?</span><br/>
                    <select name="p11">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">En el caso de haber obtenido ingresos por enajenación de terrenos, construcciones, partes sociales, acciones nominativas colocadas entre público inversionistas se considero la opción de ajustar el monto original de la inversión en esos bienes para determinar:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. ¿Si se percibieron ingresos en especie, se consideró su valor de mercado o en su defecto el de avalúo?</span><br/>
                    <select name="p12">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. ¿Se realizo la documentacion legal y pertinente a la adquisición de bienes tangibles o intangibles?</span><br/>
                    <select name="p13">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. ¿Existe comprobantes de entrega y recepción de los ingresos por enajenacion o donación en especie?</span><br/>
                    <select name="p14">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">15. ¿Se registra como ingresos las adquisiciones de bienes tangibles o intangibles?</span><br/>
                    <select name="p15">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">INGRESOS POR INTERESES:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">16. ¿Se registran mensualmente los ingresos correspondientes a intereses por cuentas bancarias?</span><br/>
                    <select name="p16">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">17. ¿Se registran o se lleva un control de los ingresos por depósitos a plazos fijos?</span><br/>
                    <select name="p17">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">18. ¿Se documenta a traves de documentos bancarios o notas de crédito los intereses ganados?</span><br/>
                    <select name="p18">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">19. ¿Se reporta en los auxiliares bancarios los interese generados en el mes?</span><br/>
                    <select name="p19">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <span className="text-principal-2">OTROS INGRESOS:</span><br/>
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">20. ¿Que documento se emite al obtener ingresos derivados de prestación de servicios, tales como: Alquiler, Servicios Audiovisuales, etc?</span><br/>
                    <select name="p20">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">21. 21. ¿Existen politicas y reglas sobre la recepcion de ingresos no corrientes?</span><br/>
                    <select name="p21">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">22. ¿Los ingresos derivados por servicios prestados son realizados por transferencias bancarias o cheques emitidos a favor de la empresa?</span><br/>
                    <select name="p22">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">23.¿Esta prohibido recibir efectivo para la prestación de servicios o existe personal autorizado para la recepcion del mismo?</span><br/>
                    <select name="p23">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
              >Guardar y Avanzar</button>
        </form>
      </div>
    </div>
  );
};

export default FormExample6;