import ONGP1 from "../../interfaces/p3";
import { useContext, useState } from "react"
import { AuthContext } from "../../context/auth";
import { useNavigate } from 'react-router-dom';
import ModalPrompt from "../ModalFiles";

interface UserRowProps{
    ongP1: ONGP1
}

export default function UserAdmin({
    ongP1
}:UserRowProps) 
{

    const navigate = useNavigate();
    const userContext = useContext(AuthContext);
    const [showModal, setShowModal] = useState<boolean>(false);

    function next()
    {
        navigate('/dashboard/previewp4')
    }

    function back()
    {
        navigate('/dashboard/previewp2')
    }

    function modalOne()
    {
        setShowModal(true)
    }

    function sendOne()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        fetch(`${userContext?.api_url}/ong/listThreeONG`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: localStorage.getItem('id')
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    console.log(resp);
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

return(
<div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            PROCESOS INTERNOS
        </h1>
        <p className="font-semibold text-center text-principal-2 uppercase">Conocimiento de la Organización</p>
        <div id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. Giro o rama en que opera.</span>
                    <textarea disabled maxLength={300} value={ongP1.p1} name="p1" id='text-area1' className="w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring"  placeholder="Pregunta #1..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. Características de la organización o empresa.</span>
                    <textarea disabled name="p2" maxLength={300} value={ongP1.p2} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #2..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. Tendencias de la organización en particular.</span><br/>
                    <textarea disabled name="p3" maxLength={300} value={ongP1.p3} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #3..." />
                  <br/>
                  <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. Tamaño relativo en cuanto a capacidad instalada considerado en:</span>
                    <textarea disabled name="p4" maxLength={300} value={ongP1.p4} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #4..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. Capacidad de instalaciones.</span><br/>
                    <textarea disabled name="p5" maxLength={300} value={ongP1.p5} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #5..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. Principales actividades y programas de la organización</span><br></br>
                    <textarea disabled name="p6" maxLength={300} value={ongP1.p6} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #6..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. Principales proveedores.</span><br/>
                    <textarea disabled name="p7" maxLength={300} value={ongP1.p7} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #7..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. Principales Agencias o Cooperantes</span><br/>
                    <textarea disabled name="p8" maxLength={300} value={ongP1.p8} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #8..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. Situación financiera y principales fuentes de financiamiento.</span><br/>
                    <textarea disabled name="p9" maxLength={300} value={ongP1.p9} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #9..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. Planes futuros.</span><br/>
                    <textarea disabled name="p10" maxLength={300} value={ongP1.p10} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #10..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. Problemas o posiciones ventajosas que sean sobresalientes.</span><br/>
                    <textarea disabled name="p11" maxLength={300} value={ongP1.p11} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #11..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. Se tiene Organigrama con los nombres de los principales funcionarios?</span><br/>
                    <select disabled name="p12">
                        <option value="No">{ongP1.p12}</option>
                    </select>
                    </label>
                <button className="bg-principal-1 justify-center items-center text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={modalOne}>Consultar</button>

                {showModal ? (
                    <ModalPrompt
                        setShowModal={setShowModal}
                        title={ongP1.p12}
                        content={
                            
                            <iframe src={'https://ctaauditores.com/plataforma_cta/archivos/'+ ongP1.fk_id_usuario + '/' + ongP1.p12} height="100%" width="100%"></iframe>

                        }
                        />
                        ) : null}
            </div>
            <br/>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. Peculiaridades y políticas del sistema de contabilidad.</span><br/>
                    <textarea disabled name="p13" maxLength={300} value={ongP1.p13} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #13..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. Opinión sobre el prestigio e imagen de la organización ante él publico.</span><br/>
                    <textarea disabled name="p14" maxLength={300} value={ongP1.p14} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #14..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">15. Volumen de Ingresos.</span>
                    <textarea disabled name="p15" maxLength={300} value={ongP1.p15} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #15..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">16. Personal y nómina anual.</span><br/>
                    <textarea disabled name="p16" maxLength={300} value={ongP1.p16} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #16..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">17. Principales domicilios e instalaciones o Centros de actividades</span><br/>
                    <textarea disabled name="p17" maxLength={300} value={ongP1.p17} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #17..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">18. Describa el ambiente de control que se siente en las instalaciones.</span><br/>
                    <textarea disabled name="p18" maxLength={300} value={ongP1.p18} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #18..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">19. Oportunidad de servicios al cliente.</span><br/>
                    <textarea disabled name="p19" maxLength={300} value={ongP1.p19} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #19..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">20. Otros.</span><br/>
                    <textarea disabled name="p20" maxLength={300} value={ongP1.p20} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #20..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={next}
              >Siguiente</button>
        <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
                  onClick={back}
              >Atras</button>
        </div>
      </div>
    </div>
)}