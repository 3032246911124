import { createContext, useState } from "react";
import User from "../interfaces/user";
import AuthContextProps from "../interfaces/AuthContextProps";

// En el caso que se refresque la página, se recupera el usuario actual y el token que se encuentra ubicado en LocalStorage
let sessionUser: User = localStorage.getItem('userInfo') ? 
            JSON.parse(`${localStorage.getItem('userInfo')}`)
            :
            null
            ;

//Creación del contexto de autenticación
export const AuthContext = createContext<AuthContextProps | null>(null);

/**
 * Propiedades para proveedor
 */
interface AuthProviderProps{
  children: any
}

/**
 * 
 * Proveedor de contexto de usuario actual
 * @author flynn1411
 * @date 06/03/23
 * @modified flynn1411
 * @date_modified 12/03/23
 */
const AuthProvider = ({children}: AuthProviderProps) => {
  const [currentUser, changeCurrentUser] = useState<User | null | undefined>(sessionUser);
  //const api_url: string = 'http://localhost:4000';
  const api_url: string = 'https://cta-back.onrender.com';

  const setCurrentUser = (newUser: User | null | undefined) => {
    changeCurrentUser(newUser);
    localStorage.setItem('userInfo', JSON.stringify(newUser));
  }

  const removeCurrentUser = () => {
    changeCurrentUser(null);
    //Se realiza el logout
    localStorage.removeItem('userInfo');
    localStorage.removeItem('USER_TOKEN');
  }

  return(
    <AuthContext.Provider value={{currentUser, setCurrentUser, removeCurrentUser, api_url}}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;