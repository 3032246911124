import User from "../interfaces/user";

interface UserRowProps{
    user: User,
    setSelectedUser: (user: User) => void,
    setMode: (mode: string) => void,
    setShowModal: (show: boolean) => void
}

export default function UserRow({
    user,
    setMode,
    setSelectedUser,
    setShowModal
}:UserRowProps){

    
    function editUser(){
        setMode("Editar Usuaria");
        setSelectedUser(user);
        setShowModal(true);
    }

    return(
        <tr onDoubleClick={editUser}>
            <td className="p-2 whitespace-nowrap">
                <div className="font-medium text-center">
                    {user.id}
                </div>
            </td>
            <td className="p-2 whitespace-nowrap">
                <div className="font-medium text-center">
                    {user.company}
                </div>
            </td>
            <td className="p-2 whitespace-nowrap">
                <div className="font-medium text-center">
                    {user.email}
                </div>
            </td>
            <td className="p-2 whitespace-nowrap">
                <div className="font-medium text-center">
                    {String(user.ong == true ? ('Si') : ('No'))}
                </div>
            </td>
            <td className="p-2 whitespace-nowrap">
                <div className="font-medium text-center">
                    {String(user.comp  == true ? ('Si') : ('No'))}
                </div>
            </td>
        </tr>
    )
}