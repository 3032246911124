import { SetStateAction, useContext, useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { AuthContext } from "../context/auth";
import ModalPrompt from "./Modal";
import User from "../interfaces/user";
import UserRow from './UserRow';
import { APIGET, APIPOST } from "../utils/api";

export default function UserAdmin() 
{

	const [userList, setUserList] = useState<User[]>([]);
	const userContext = useContext(AuthContext);
	const [showModal, setShowModal] = useState<boolean>(false);
	const [mode, setMode] = useState<string>("");
	const [selectedUser, setSelectedUser] = useState<User>({ email: "", user_password: "", company: "", superuser: false, administrador: false, ong: false, comp: false });

    function renderUsers() 
    {
		return userList.map((usr: User) => <UserRow key={uuidv4()} user={usr} setMode={setMode} setSelectedUser={setSelectedUser} setShowModal={setShowModal} />);
	}

    function registerUser(event: React.FormEvent<HTMLFormElement>) 
    {
		let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

		event.preventDefault();
		let register_route: string = "registersuper";

		let cUser: User = 
        {
			id: selectedUser.id,
			email: event.currentTarget.email.value ? event.currentTarget.email.value : selectedUser.email,
			user_password: event.currentTarget.user_password ? event.currentTarget.user_password.value : "",
			company: event.currentTarget.company.value ? event.currentTarget.company.value : selectedUser.company,
			ong: event.currentTarget.ong.value ? event.currentTarget.ong.value : selectedUser.ong,
			superuser: event.currentTarget.superuser.value ? event.currentTarget.superuser.value : selectedUser.superuser,
			administrador: event.currentTarget.administrador.value ? event.currentTarget.administrador.value : selectedUser.administrador,
			comp: event.currentTarget.comp.value ? event.currentTarget.comp.value : selectedUser.comp
		}

		let checked = /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(cUser.email) && /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(cUser.user_password);
		let password_confirm = event.currentTarget.password_confirm ? event.currentTarget.password_confirm.value : "";

		if (cUser.user_password !== password_confirm) 
        {
			alert("Las contraseñas no concuerdan.")
			checked = false;
		}

		if (checked && (mode === "Registrar una nuevo usuario")) {
			fetch(`${userContext?.api_url}/user/${register_route}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': token
				},
				body: JSON.stringify({
					email: cUser.email,
					user_password: cUser.user_password,
					company: cUser.company,
					ong: cUser.ong,
                    comp: cUser.comp,
					superuser: cUser.superuser,
					administrador: cUser.administrador
				})
			}).then(resp => {
				if (resp.status >= 400) {
					console.log(resp);
				} else {
					resp.json().then(jsonResponse => {

						getUsers();
					})
				}
			}).catch((err) => {
				console.log(err);
			})
		}

		else if (mode === "Editar Usuaria") {
			const formData = new FormData(event.currentTarget);
			let empty: boolean = true;
			for (let [key, value] of formData.entries()) {
				if (value !== "") {
					empty = false;
				}
			}

			if (!empty) {
				fetch(`${userContext?.api_url}/user/updateInfoSuper`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json',
						'Authorization': token
					},
					body: JSON.stringify(cUser)
				}).then(resp => {
					if (resp.status >= 400) {
						console.log(resp);
					} else {
						resp.json().then(jsonResponse => {
							getUsers();
						})
					}
				}).catch((err) => {
					console.log(err);
				})
			}

		}
		setShowModal(false);
	}

    function getUsers() 
    {
        APIGET('POST', `${userContext?.api_url}/user/usersinfo`).then((resp) => 
        {
            if (resp.status === "OK") 
            {
                setUserList(resp.userInfo);
            }
        })
	}

    useEffect(() => {
		getUsers();
	}, [])

    return(
    <div className="justify-center items-center content-center flex overflow-x-hidden overflow-y-hidden inset-0 z-50 outline-none focus:outline-none bg-principal-3">
        {showModal ? (
				<ModalPrompt
					setShowModal={setShowModal}
					title={mode}
					content={
						<form onSubmit={registerUser} className="" id="newUserForm">
							<div className="px-0">
								<label htmlFor="company" className="pb-1 text-principal-2">Empresa</label>
								<input
									type="text"
									placeholder={"Ingrese nombre de la empresa"}
									name="company"
									required={mode === "Registrar una nuevo usuario"}
									className="border-principal-1 h-10 w-full focus:border-principal-2 bg-transparent font-sans border-4 rounded-lg mb-2 py-2 px-2 text-lg pb-2 placeholder-placeholder text-principal-2"
									defaultValue={`${mode === "Editar Usuaria" ? selectedUser?.company : ''}`}
								/>
							</div>
							<div className="px-0">
								<label htmlFor="email" className="pb-1 text-principal-2" >
									Email
								</label>
								<input
									type="email"
									placeholder={"Ingrese un correo electrónico"}
									name="email"
									required={mode === "Registrar una nuevo usuario"}
									className="border-principal-1 h-10 w-full focus:border-principal-2 bg-transparent font-sans border-4 rounded-lg mb-2 py-2 px-2 text-lg pb-2 placeholder-placeholder text-principal-2"
									defaultValue={`${mode === "Editar Usuaria" ? selectedUser.email : ''}`}
								/>
							</div>
							{
								mode === "Registrar una nuevo usuario" &&
								<>
									<div className="px-0 grid">
										<label htmlFor="user_password" className="pb-0 text-principal-2">Contraseña</label>
										<span className="text-xs pb-1" >Minímo 8 carácteres, una mayuscúla, una minuscúla y digítos.</span>
										<input
											type="password"
											name="user_password"
											autoComplete="on"
											className="border-principal-1 h-10 w-full focus:border-principal-2 bg-transparent font-sans border-4 rounded-lg mb-2 py-2 px-2 text-lg pb-2 placeholder-placeholder text-principal-2"
										/>
									</div>
									<div className="px-0">
										<label htmlFor="password_confirm" className="pb-1 text-principal-2">Confirme la contraseña</label>
										<input
											type="password"
											autoComplete="on"
											name="password_confirm"
											className="border-principal-1 h-10 w-full focus:border-principal-2 bg-transparent font-sans border-4 rounded-lg mb-2 py-2 px-2 text-lg pb-2 placeholder-placeholder text-principal-2"
										/>
									</div>
								</>
							}
                            <div className="px-0">
                                <label htmlFor="ong" className="pb-1 text-principal-2">¿Es Mercantil?</label><br/>
                                <div className="pr-5 text-principal-2 flex items-center justify-start ">
                                    <input type="radio" value="1" name="comp" className="focus:bg-principal-1" /> Si
                                    <input type="radio" value="0" name="comp" className="focus:bg-principal-1" /> No
                                </div>
                            </div><br/>
                            <div className="px-0">
                                <label htmlFor="ong" className="pb-1 text-principal-2">¿Es ONG?</label><br/>
                                <div className="pr-5 text-principal-2">
                                    <input type="radio" value="1" name="ong" className="focus:bg-principal-1" /> Si
                                    <input type="radio" value="0" name="ong" className="focus:bg-principal-1" /> No
                                </div>
                            </div>
                            <br/>
                            {
                                userContext?.currentUser?.administrador ?
                                (
                                    mode === "Registrar una nuevo usuario" &&
                                    <>
                                    <label htmlFor="superuser" className="pb-1 text-principal-2">¿Es Administrador?</label><br/>
                                                    <input defaultChecked={selectedUser.administrador} type="radio" value="1" name="administrador" className="focus:bg-principal-1" /> Si
                                                    <input defaultChecked={selectedUser.administrador} type="radio" value="0" name="administrador" className="focus:bg-principal-1" /> No
                                    </>)
                                    : null
                            }
							{
                                userContext?.currentUser?.superuser ?
                                (
                                    mode === "Registrar una nuevo usuario" &&
                                    <>
                                        <div className="pr-5 text-principal-2">
                                            <label htmlFor="superuser" className="pb-1 text-principal-2">¿Es Super Usuario?</label><br/>
                                            <input defaultChecked={selectedUser.superuser} type="radio" value="1" name="superuser" className="focus:bg-principal-1" /> Si
                                            <input defaultChecked={selectedUser.superuser} type="radio" value="0" name="superuser" className="focus:bg-principal-1" /> No
                                        </div><br/>
                                        <div className="pr-5 text-principal-2">
                                            <label htmlFor="superuser" className="pb-1 text-principal-2">¿Es Administrador?</label><br/>
                                            <input defaultChecked={selectedUser.superuser} type="radio" value="1" name="administrador" className="focus:bg-principal-1" /> Si
                                            <input defaultChecked={selectedUser.superuser} type="radio" value="0" name="administrador" className="focus:bg-principal-1" /> No
                                            <br/>
                                        </div>
                                    </>)
                                    : null
							}
						</form>
					}
					closeFunction={() => console.log("Pasó")}
					acceptFunction={() => console.log("Pasó")}
					formName={"newUserForm"}
				/>
			) : null}
        <div className="relative h-screen w-[70%] justify-center items-center content-center">
                <div className="max-w-[50%]-xl items-center justify-center mx-auto p-4 bg-principal-3 h-screen">
                    <header className="px-5 py-4 border-b border-gray-100 flex place-items-center flex-row justify-between">
                        <h2 className="font-semibold text-2xl text-principal-2">Usuarios</h2>
                        <button
                            onClick={() => {
                                setSelectedUser({ email: "", user_password: "", company: "", superuser: false, administrador: false, ong: false, comp: false });
                                setMode("Registrar una nuevo usuario");
                                setShowModal(true);
                            }}
                            className="grid col-span-1 bg-principal-1 w-[25%] lg:w-[20%] xl:w-[14%] 3xl:w-[10%] py-1 rounded-md font-sans text-principal-2 font-firasans font-bold text-md hover:bg-principal-1"
                        >Registrar Usuario</button>
                    </header>
                    <div className="p-3">
                        <div className="overflow-x-auto">
                            <table className="table-auto w-full font-roboto text-principal-3">
                                <thead className="text-xs font-semibold uppercase text-principal-2 bg-principal-1">
                                    <tr>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">ID</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Empresa</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Email</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">ONG</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Mercantil</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-sm divide-y divide-gray-100 text-principal-2">{renderUsers()}</tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}