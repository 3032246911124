import p14 from '../../interfaces/p14';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/auth';
import { useNavigate } from 'react-router-dom';

const FormExample6 = () => 
{

    const userContext = useContext(AuthContext);
    const navigate = useNavigate();

    const [selected1, setSelected1] = useState();
    const [selected7, setSelected7] = useState();

    const handleChange1 = (event:any) => 
    {
        setSelected1(event.target.value)
        
    }

    const handleChange7 = (event:any) => 
    {
        setSelected7(event.target.value)
        
    }

    function sendOne(event: React.FormEvent<HTMLFormElement>)
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

        event.preventDefault();

        let getP1: p14 = {
            fk_id_usuario: userContext?.currentUser?.id,
            p1: event.currentTarget.p1.value,
            p2: event.currentTarget.p2.value,
            p3: event.currentTarget.p3.value,
            p4: event.currentTarget.p4.value,
            p5: event.currentTarget.p5.value,
            p6: event.currentTarget.p6.value,
            p7: event.currentTarget.p7.value,
            p8: event.currentTarget.p8.value == 'No' ? (event.currentTarget.p8.value) : (event.currentTarget.pp8.value),
            p9: event.currentTarget.p9.value,
            p10: event.currentTarget.p10.value,
            p11: event.currentTarget.p11.value,
            p12: event.currentTarget.p12.value,
            p13: event.currentTarget.p13.value,
            p14: event.currentTarget.p14.value,
            p15: event.currentTarget.p15.value,
            p16: event.currentTarget.p16.value == 'No' ? (event.currentTarget.p16.value) : (event.currentTarget.pp16.value),
            p17: event.currentTarget.p17.value,
            p18: event.currentTarget.p18.value,
            p19: event.currentTarget.p19.value,
            p20: event.currentTarget.p20.value,
            p21: event.currentTarget.p21.value,
            p22: event.currentTarget.p22.value,
            p23: event.currentTarget.p23.value,
            p24: event.currentTarget.p24.value,
            p25: event.currentTarget.p25.value,
            p26: event.currentTarget.p26.value,
            p27: event.currentTarget.p27.value,
            p28: event.currentTarget.p28.value,
            p29: event.currentTarget.p29.value,
            p30: event.currentTarget.p30.value,
            p31: event.currentTarget.p31.value,
            p32: event.currentTarget.p32.value,
            p33: event.currentTarget.p33.value,
            p34: event.currentTarget.p34.value,
            p35: event.currentTarget.p35.value,
            p36: event.currentTarget.p36.value,
            p37: event.currentTarget.p37.value,
            p38: event.currentTarget.p38.value,
            p39: event.currentTarget.p39.value,
            p40: event.currentTarget.p40.value,
            p41: event.currentTarget.p41.value
		}

        fetch(`${userContext?.api_url}/ong/fourteenQuestionary`, 
        {
            method: 'POST',
            headers: 
            {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                fk_id_usuario: getP1.fk_id_usuario,
                p1: getP1.p1,
                p2: getP1.p2,
                p3: getP1.p3,
                p4: getP1.p4,
                p5: getP1.p5,
                p6: getP1.p6,
                p7: getP1.p7,
                p8: getP1.p8,
                p9: getP1.p9,
                p10: getP1.p10,
                p11: getP1.p11,
                p12: getP1.p12,
                p13: getP1.p13,
                p14: getP1.p14,
                p15: getP1.p15,
                p16: getP1.p16,
                p17: getP1.p17,
                p18: getP1.p18,
                p19: getP1.p19,
                p20: getP1.p20,
                p21: getP1.p21,
                p22: getP1.p22,
                p23: getP1.p23,
                p24: getP1.p24,
                p25: getP1.p25,
                p26: getP1.p26,
                p27: getP1.p27,
                p28: getP1.p28,
                p29: getP1.p29,
                p30: getP1.p30,
                p31: getP1.p31,
                p32: getP1.p32,
                p33: getP1.p33,
                p34: getP1.p34,
                p35: getP1.p35,
                p36: getP1.p36,
                p37: getP1.p37,
                p38: getP1.p38,
                p39: getP1.p39,
                p40: getP1.p40,
                p41: getP1.p41
            })
        }).then(resp => 
            {
            if (resp.status >= 400) 
            {
                console.log(resp);
            } 
            else 
            {
                resp.json().then(jsonResponse => 
                {
                    navigate('/')
                })
            }
        }).catch((err) => 
        {
            console.log(err);
        })

    }

    function verifyStatus()
    {
        let token: string = "";

		if (localStorage.getItem("USER_TOKEN") !== null) 
        {
			token = localStorage.getItem("USER_TOKEN")!;
		}

            fetch(`${userContext?.api_url}/user/getFinished`, 
            {
                method: 'POST',
                headers: 
                {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': token
                },
                body: JSON.stringify({
                    id: userContext?.currentUser?.id
                })
            }).then(resp => 
            {
                if (resp.status >= 400) 
                {
                    console.log(resp);
                } 
                else 
                {
                    resp.json().then(jsonResponse =>
                    {
                        let finished = jsonResponse; 
                        finished.map(()=>
                        {
                            if(finished[0].finishedfourteen === true)
                            {
                                navigate('/')
                            }
                        })
                    })
                }
            }).catch((err) => 
            {
                console.log(err);
            })        
    }

    useEffect(() => {
        verifyStatus();
        window.scrollTo(0, 0);
      });
    
  
  return (
    <div className="justify-center overflow-hidden">
      <div className="w-full p-6 m-auto lg:max-w-xl">
        <h1 className="text-3xl font-semibold text-center text-principal-2 uppercase">
            RECURSOS HUMANOS
        </h1>
        <form onSubmit={sendOne} id="newOne" className="mt-6">
            <div className="mb-2">
                <label>
                    <span className="text-principal-2">1. ¿Existe un Manual de Personal y de Funciones?</span><br/>
                    <select name="p1">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">2. ¿Posee la Organización Manuales inductivos de entrenamiento y capacitación?</span><br/>
                    <select name="p2">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">3. ¿Posee la organización adecuadamente establecido las políticas y obligaciones del personal de cada área de trabajo?</span><br/>
                    <select name="p3">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                  <br/>
                  <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">4. ¿Existen Manual de Reclutamiento, Selección y Contratación de Personal?</span><br/>
                    <select name="p4">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">5. ¿Existen Clasificación de Puestos y Rangos Salariales?</span><br/>
                    <select required name="p5">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">6. ¿Existe un Manual de Evaluación de Puestos y Funciones?</span><br/>
                    <select required name="p6">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">7. ¿Existe Manual de Seguridad e Higiene del Personal?</span><br/>
                    <select required name="p7">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">8. ¿Contiene la Organización Expedientes actualizados de Trabajo del personal?</span><br/>
                    <select required name="p8" value={selected1} onChange={handleChange1}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected1 === 'Si' && 
                        <div>
                            <textarea required name="pp8" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">9. Mencione los documentos que actualmente existen en los expedientes de trabajo.</span><br/>
                    <textarea required name="p9" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">10. ¿Posee la Organización detallado los puestos de trabajo existentes?</span><br/>
                    <select required name="p10">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">11. ¿Están correctamente definidos las funciones de cada puesto de trabajo?</span><br/>
                    <select required name="p11">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">12. ¿Los puestos de Trabajo están de acuerdo al Organigrama actual?</span><br/>
                    <select name="p12">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">13. ¿Que instrumento se utiliza para la contratación del Personal?</span><br/>
                    <textarea required name="p13" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">14. ¿Están debidamente certificados por el Ministerio de Trabajo los contratos?</span><br/>
                    <select name="p14">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">15. ¿Existe un Código de Ética, Conducta y Valores del personal?</span><br/>
                    <select name="p15">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">16. ¿Posee la Organización procesos de selección adecuados? ¿Que tipo de procesos efectúan actualmente?</span><br/>
                    <select required name="p16" value={selected7} onChange={handleChange7}>
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                        {selected7 === 'Si' && 
                        <div>
                            <textarea required name="pp16" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                        </div>}
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">17. ¿Posee la Organización documento de Conflicto de Intereses?</span><br/>
                    <select required name="p17">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">18. ¿Posee la Organización documento de Política de Protección a los niños(as)?</span><br/>
                    <select required name="p18">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">19. ¿Están correctamente definidas las horas de trabajo de cada puesto?</span><br/>
                    <select required name="p19">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">20. ¿Están correctamente definidas los beneficios, vacaciones y licencias al personal?</span><br/>
                    <select required name="p20">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">21. ¿Existen Controles de evaluación del personal de las áreas de trabajo?¿ Existen Controles de evaluación del personal de las áreas de trabajo?</span><br/>
                    <select name="p21">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">22. ¿Contiene informes definitivos de las evaluaciones efectuadas al personal?</span><br/>
                    <select required name="p22">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">23. ¿Se planifica y ejecuta la formación y capacitación del personal nuevo?</span><br/>
                    <select required name="p23">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">24. ¿Cada cuanto se efectúan capacitaciones y entrenamiento del personal permanente?</span><br/>
                    <select required name="p24">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">25. ¿Posee la Organización presupuesto para capacitar al personal con Seminarios, Conferencias, Entrenamientos y otros en el año?</span><br/>
                    <select required name="p25">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">26. ¿Existe un inductivo y manual de procedimientos para la contratación de personal?</span><br/>
                    <select required name="p26">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">27. Describa claramente el proceso de selección y elección de personal nuevo.</span><br/>
                    <textarea required name="p27" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">28. ¿Que procedimientos realiza y ejecuta al contratar al personal elegido?</span><br/>
                    <textarea required name="p28" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">29. ¿Existe un departamento o sección para desarrollar el talento y la capacidad de cada empleado permanente de la organización?</span><br/>
                    <select name="p29">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">30. ¿Existen políticas y planes de incentivos y compensaciones al personal?</span><br/>
                    <select name="p30">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">31. ¿Que tipo de incentivos y compensaciones contiene el personal?</span><br/>
                    <textarea required name="p31" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Detalle..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">32. ¿Existe un plan o políticas de rotación y movimiento de personal en áreas especializadas o técnicas?</span><br/>
                    <select required name="p32">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">33. ¿Existe un reglamento de medidas disciplinarias del personal?</span><br/>
                    <select required name="p33">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">34. ¿Existe un plan de seguros contra accidentes o sucesos del personal en actividades de riesgos?</span><br/>
                    <select required name="p34">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">35. Describa los medios de comunicación que se utiliza para los empleados y personal de la Organización</span><br/>
                    <textarea required name="p35" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #35..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">36. ¿Se preparan y utilizan los planes de acción para comunicar las necesidades de la dirección?</span><br/>
                    <select required name="p36">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">37. ¿Existe una correcta aplicación de los roles de mando y autoridad?</span><br/>
                    <select required name="p37">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">38. ¿Que consideraciones incluiría usted en los roles de mando y autoridad?</span><br/>
                    <textarea required name="p38" maxLength={300} className=" w-full mt-2 bg-principal-2 rounded-md focus:border-principal-1 resize-none focus:ring" placeholder="Pregunta #38..." />
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">39. ¿Se encuentra la estructura de personal de acuerdo al Código de Trabajo y sus reglamentos?</span><br/>
                    <select required name="p39">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">40. ¿Existe un correcto sistema de salarios e incentivos del personal en general de la Organización?</span><br/>
                    <select required name="p40">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                    </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <div className="mb-2">
                <label>
                    <span className="text-principal-2">41. ¿ Contiene la Organización Carnet o Medios de identificación personal de cada empleado permanente?</span><br/>
                    <select name="p41">
                        <option value="No">No</option>
                        <option value="Si">Si</option>
                  </select>
                    <br/>
                    <br/>
                </label>
            </div>

            <button
                  className="bg-principal-1 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                  form="newOne"
              >Guardar y Avanzar</button>
        </form>
      </div>
    </div>
  );
};

export default FormExample6;